import { useCountDown } from 'utils/date-time-utils'
export interface CountdownClockProps {
  title: string
  dueDate: string
  titleClass?: string
  clockClass?: string
}
const CountdownClock = ({
  title,
  dueDate,
  clockClass,
  titleClass
}: CountdownClockProps) => {
  const [h, m, s, loading] = useCountDown(dueDate)
  return (
    <>
      <h3 className={`text-center ${titleClass}`}>{title}</h3>
      <p className={`text-gold text-center ${clockClass}`}>
        {loading ? (
          <>Loading...</>
        ) : (
          <span>
            {h} : {m} : {s}
          </span>
        )}
      </p>
    </>
  )
}

export default CountdownClock
