import { Svg } from 'images'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Input = styled.input`
  border-radius: 8px 0 0 8px;
  border-color: rgba(8, 15, 24, 0.16);
`
const Button = styled.button`
  background-color: #f1b625;
  border-color: transparent;
  &:hover {
    background-color: #f1b625;
    border-color: transparent;
  }
`
type LottoSearchProps = {
  value?: string
  onSearch?: (e: ChangeEvent<HTMLInputElement>) => void
}
const LottoSearch = ({ value, onSearch }: LottoSearchProps) => {
  const { t } = useTranslation()
  return (
    <div className="input-group">
      <div className="form-outline">
        <Input
          type="search"
          id="search-lotto-number"
          className="form-control"
          placeholder={t('lottoSearch.searchNumber').toString()}
          onChange={onSearch}
          value={value ?? ''}
        />
      </div>
      <Button type="button" className="btn btn-primary">
        <img
          src={Svg.iconMagnifierBlack}
          alt={t('lottoSearch.searchNumber').toString()}
        />
      </Button>
    </div>
  )
}

export default LottoSearch
