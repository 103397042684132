import { Svg } from 'images'
const Contact = () => {
  return (
    <div className="container">
      <h2 className="mt-5 mb-3 d-flex justify-content-center">ติดต่อเรา</h2>
      <div className="contact-detail-main-container">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-6 contact-detail-container">
            <div className="p-3 d-flex justify-content-center flex-column h-100">
              <div className="mb-3">
                <img src={Svg.Logo} alt="" />
              </div>
              <p>
                โยเกิร์ต แมกกาซีน แบด เกย์ม้านั่ง
                เพนกวินละอ่อนอันเดอร์แมชีนสต๊อก เซนเซอร์ตรวจสอบแพตเทิร์น
                จ๊อกกี้กับดัก ไฟแนนซ์โบตั๋นเฮีย
                ไฮเวย์บึ้มแบ็กโฮอีแต๋นซัพพลายเออร์
              </p>
              <div className="contact-details-wrapper p-3">
                <h3>ติดต่อผู้ดูแล</h3>
                <ul className="list-unstyled">
                  <li className="list-inline-item">
                    <a href="#" title="LineID">
                      <img src={Svg.iconContactLine} alt="LineID" />
                      <span>ไลน์ไอดี : </span>
                      <span>LineID</span>
                    </a>
                  </li>
                  <li className="">
                    <a href="#" title="LineID">
                      <img src={Svg.iconContactTel} alt="0123456789" />
                      <span>สายด่วน : </span>
                      <span>0123456789</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-6 contact-form-container">
            <div className="p-3">
              <form action="">
                <div className="row">
                  <div className="mb-3">
                    <label className="form-label">ชื่อติดต่อ</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ชื่อ"
                      aria-label="ชื่อ"
                      id="contactName"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">หัวข้อ</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="หัวข้อ"
                      aria-label="หัวข้อ"
                      id="contactTitle"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">อีเมล์</label>
                    <input
                      type="email"
                      className="form-control"
                      id="InputEmail"
                      placeholder="Your Email"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">ข้อความ</label>
                    <textarea
                      name=""
                      className="form-control"
                      id="contactMassage"
                      cols={30}
                      rows={10}
                      placeholder="ใส่ข้อความ"
                    ></textarea>
                  </div>
                  <div className="col-12">
                    <button
                      type="submit"
                      className="btn btn-primary btn-send-data w-100"
                    >
                      ส่งข้อมูล
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
