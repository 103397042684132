import { AxiosResponse } from 'axios'
import { call, put, takeLatest } from 'redux-saga/effects'
import { callAPI } from '.'
import { fetchData } from '../reducers/todoReducer'
import { sagaActions } from '../sagaActions'
function* fetchTodos() {
  try {
    const result: AxiosResponse = yield call(() =>
      callAPI({ url: 'https://jsonplaceholder.typicode.com/todos' })
    )
    yield put(fetchData(result.data))
  } catch (e) {
    yield put({ type: 'FETCH_FAILED' })
  }
}

export function* watchFetchTodos() {
  yield takeLatest(sagaActions.FETCH_TODOS, fetchTodos)
}
