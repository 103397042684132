import Lottie from 'react-lottie-player'
import animation from 'images/404error.json'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
export interface Component404Props {
  title: string
  header: string
  info: string
}
const Component404 = ({ title, header, info }: Component404Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <section className="cotainer my-3 d-flex justify-content-center align-content-center vh-100 align-items-center">
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-center align-content-center align-items-center flex-column">
            <h2 className="text-gold">{title}</h2>
            <h3>{header}</h3>
            <p>{info}</p>
            <Lottie
              loop
              inputMode="none"
              style={{ height: '55vh' }}
              animationData={animation}
            />
            <div className="d-flex justify-content-center align-items-center row-gap-5 btn-back-container">
              <a
                onClick={() => navigate('/lotto')}
                title={t('page404.backToHome') ?? ''}
                className="btn btn-back-home"
              >
                {t('page404.backToHome')}
              </a>
              <a
                onClick={() => navigate(-1)}
                title={t('page404.back') ?? ''}
                className="btn btn-back"
              >
                {t('page404.back')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Component404
