import { NavBarItemProps, NavbarProps } from 'components/Navbar'

const NavbarTab = ({
  items,
  onChangeItem,
  selected,
  navBarClass
}: NavbarProps) => {
  return (
    <nav>
      <div
        className={`nav nav-tabs ${navBarClass}`}
        id="nav-tab"
        role="tablist"
      >
        {items?.map((i) => (
          <NavbarTabItem
            active={selected === i.key}
            name={i.name}
            itemClass={i.itemClass}
            key={i.key}
            onChangeItem={() => onChangeItem(i.key)}
          />
        ))}
      </div>
    </nav>
  )
}

const NavbarTabItem = ({
  active,
  name,
  itemClass,
  onChangeItem
}: NavBarItemProps) => {
  return (
    <button
      className={`nav-link ${active && 'active'} ${itemClass}`}
      type="button"
      role="tab"
      onClick={onChangeItem}
    >
      {name}
    </button>
  )
}

export default NavbarTab
