import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import dayjs, { isValidDate, useCountDown } from 'utils/date-time-utils'

export type LottoType =
  | 'lotto-list-yeekeevip'
  | 'lotto-list-crypto'
  | 'lotto-list-yeekee'
  | 'lotto-list-corporate'
  | 'lotto-list-goverment'
  | 'lotto-list-baac'
  | 'lotto-list-thaistock-evening'
  | 'lotto-list-thaistock-morning'
  | 'lotto-list-thaistock-afternoon'
  | 'lotto-list-thaistock-pm'
  | 'lotto-list-vietnam'
  | 'lotto-list-hanoi'
  | 'lotto-list-stock-korea'
  | 'lotto-list-stock-nikei'
  | 'lotto-list-stock-nikei-afternoon'
  | 'lotto-list-stock-hungseng-afternoon'
  | 'lotto-list-stock-hungseng-morning'
  | 'lotto-list-stock-china-afternoon'
  | 'lotto-list-stock-china-morning'
  | 'lotto-list-stock-taiwan'
  | 'lotto-list-stock-singapore'
  | 'lotto-list-stock-egypt'
  | 'lotto-list-stock-german'
  | 'lotto-list-stock-england'
  | 'lotto-list-stock-russia'
  | 'lotto-list-stock-lao'
  | 'lotto-list-stock-india'
  | 'lotto-list-stock-downjones'
  | 'lotto-list-stock-malaysia'
  | 'lotto-list-stock-lao-star'

export type LottoItemProps = {
  title: string
  gameId?: number
  details?: string
  type: LottoType
  betEnable?: boolean
  betDetailsString?: string
  dueDate?: string
  openDate?: string
  endDate?: string
  round?: number
  link?: string
  handleOnClick?: () => void
}

const Wrapper = styled.div`
  cursor: pointer;
`

const LottoItem = ({
  title,
  details,
  type,
  betEnable,
  betDetailsString,
  dueDate,
  openDate,
  endDate,
  link,
  handleOnClick
}: LottoItemProps) => {
  const { t } = useTranslation()
  const navigation = useNavigate()

  const renderEnableTag = () => {
    if (betEnable === undefined) {
      return <div></div>
    }
    return (
      <span className={betEnable ? 'bet-enable' : 'bet-disable'}>
        {betDetailsString}
      </span>
    )
  }
  const renderDetails = () => {
    let response = (
      <button
        title=""
        className="btn-ready-bet btn-black radius-8 text-gold space-btn-inner"
      >
        {t('lottoPage.status.open')}
      </button>
    )
    if (
      (openDate &&
        endDate &&
        dayjs(openDate).isBefore(dayjs()) &&
        dayjs(endDate).isBefore(dayjs())) ||
      !betEnable
    ) {
      response = (
        <button
          title=""
          className="btn-ready-bet btn-grey radius-8  space-btn-inner"
        >
          {t('lottoPage.status.close')}
        </button>
      )
    }
    if (dueDate) {
      response = <CountDownComponent dueDate={dueDate} />
    }
    return response
  }
  const onClick = () => {
    if (link) {
      navigation(link)
    } else {
      handleOnClick?.()
    }
  }
  return (
    <Wrapper className="col-6 col-lg-3 mb-4 lotto-list-wrapper">
      <a title={title} onClick={onClick}>
        <div className={`lotto-list-inner ${type ?? 'lotto-list-yeekee'}`}>
          <p className="mb-0">
            <strong className="lotto-title">{title}</strong>
            <br />
            {details && <span className="lotto-details">{details}</span>}
          </p>
          <div
            className={
              dueDate ? 'bet-countdown' : 'bet-details d-flex flex-column'
            }
          >
            {renderEnableTag()}
            {renderDetails()}
          </div>
        </div>
      </a>
    </Wrapper>
  )
}

const CountDownComponent = ({ dueDate }: { dueDate: string }) => {
  const { t } = useTranslation()
  if (!isValidDate(dueDate)) {
    return <p className="text-gold">Invalid due date</p>
  }
  const [h, m, s, loading, isOverdue] = useCountDown(dueDate)
  return loading ? (
    <p className="bet-countdown-detail text-gold d-flex flex-row justify-content-center mb-0 btn-black radius-8 space-btn-inner">
      <span
        className="pinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
      Loading...
    </p>
  ) : isOverdue ? (
    <button
      title=""
      type="button"
      className="btn-ready-bet btn-grey radius-8  space-btn-inner"
    >
      {t('lottoPage.status.close')}
    </button>
  ) : (
    <p className="bet-countdown-detail text-gold d-flex flex-row justify-content-center mb-0 btn-black radius-8 space-btn-inner">
      <span>{h}</span>
      <span>{m}</span>
      <span>{s}</span>
    </p>
  )
}

export default LottoItem
