import styled from 'styled-components'

export interface LottoContainerProps {
  title?: string
  titleClass?: string
  buttonText?: string
  children?: React.ReactNode
  isShowButton?: boolean
  onClickButton?: () => void
}
const ButtonText = styled.button`
  width: fit-content;
  margin: 10px;
`
const LottoContainer = ({
  title,
  titleClass,
  isShowButton,
  buttonText,
  onClickButton,
  children
}: LottoContainerProps) => {
  return (
    <section className="lotto-list-section-container container">
      <div className="row">
        <h2 className={titleClass}>{title}</h2>
        <div className="row">
          {isShowButton && (
            <ButtonText
              type="button"
              onClick={onClickButton}
              style={{ width: 'fit-content', margin: 10 }}
              className="btn-gold radius-8 text-light-black d-flex justify-content-center space-btn-inner text-decoration-none"
            >
              {buttonText}
            </ButtonText>
          )}
        </div>
        {children}
      </div>
    </section>
  )
}

export default LottoContainer
