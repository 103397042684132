import { ListManualBet, NumberInput } from 'components'
import { useManualBet } from '../hook/useManualBet'

const ManualBet = () => {
  const {
    renderItem,
    selected,
    onSelectNewMenu,
    maxDigit,
    addDigit,
    deleteNumber,
    currentNumber
  } = useManualBet()
  return (
    <div className="row">
      <div className="col-12 col-lg-4">
        <ListManualBet
          items={renderItem}
          selected={selected}
          onChangeBet={onSelectNewMenu}
        />
      </div>
      <div className="col-12 col-lg-8">
        <NumberInput
          maxDigit={maxDigit}
          onAddNumber={addDigit}
          onDeleteNumber={deleteNumber}
          currentNumber={currentNumber}
        />
      </div>
    </div>
  )
}

export default ManualBet
