import { PanelButton } from 'pages/lotto/bet-lotto/bet-from-panel/three-digit-panel'
import React from 'react'

export type TwoNumberFunctionPanelProps = {
  title: string
  type: string
  value?: string[]
  onSelectNumber?: (number: string, type: string) => void
}
const TwoNumberFunctionPanel = ({
  title,
  value,
  type,
  onSelectNumber
}: TwoNumberFunctionPanelProps) => {
  return (
    <React.Fragment>
      <h3 className="font-16 text-center">{title}</h3>
      <ul className="list-unstyled list-inline d-flex flex-wrap justify-lg-content-start justify-content-between align-items-center align-content-start">
        {['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].map((i) => (
          <li className="list-inline-item" key={`${type}${i}`}>
            <PanelButton
              className={`text-decoration-none btn-outline-light-grey ${
                value?.includes(i) && 'active'
              }`}
              onClick={() => onSelectNumber?.(i, type)}
            >
              {i}
            </PanelButton>
          </li>
        ))}
      </ul>
    </React.Fragment>
  )
}

export default TwoNumberFunctionPanel
