import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import thLng from './th.json'
import enLng from './en.json'

i18n.use(initReactI18next).init({
  resources: {
    th: {
      translation: thLng
    },
    en: {
      translation: enLng
    }
  },
  lng: 'th',
  // fallbackLng: 'th',
  interpolation: {
    escapeValue: false
  }
})

export default i18n
