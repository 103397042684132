export const NumberFormat = (value: number, decimal = 2) => {
  return Number(value.toFixed(decimal)).toLocaleString('en-US', {
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal
  })
}

export const generatePermutations = (arr: number[]): string[] => {
  if (arr.length === 1) {
    return [arr[0].toString()]
  }
  const result: string[] = []
  for (let i = 0; i < arr.length; i++) {
    const rest = [...arr.slice(0, i), ...arr.slice(i + 1)]
    const permutations = generatePermutations(rest)
    for (let j = 0; j < permutations.length; j++) {
      result.push(arr[i].toString() + permutations[j])
    }
  }
  return result
}

export const generatePermutationsWithString = (number: string): string[] => {
  const result: string[] = []
  const backtrack = (current: string, remaining: string): void => {
    if (remaining.length === 0) {
      result.push(current)
      return
    }
    for (let i = 0; i < remaining.length; i++) {
      const digit = remaining[i]
      backtrack(current + digit, remaining.slice(0, i) + remaining.slice(i + 1))
    }
  }
  backtrack('', number)
  return result
}

export const generateNineteenNumber = (digit: number): string[] => {
  const result: string[] = []
  for (let i = 0; i < 100; i++) {
    const str = i.toString().padStart(2, '0')
    if (str.includes(digit.toString())) {
      result.push(str)
    }
  }

  return result
}

export const generateDoubleNumber = (): string[] => {
  const result = []
  for (let i = 0; i < 10; i++) {
    result.push(`${i}${i}`)
  }
  return result
}

export const generateSwitchNumber = (
  digit: number,
  fromBack = false
): string[] => {
  const result = []
  for (let i = 0; i < 10; i++) {
    if (fromBack) {
      result.push(`${i}${digit.toString()}`)
    } else {
      result.push(`${digit.toString()}${i}`)
    }
  }
  return result
}

export const generateLowNumber = (): string[] => {
  const result = []
  for (let i = 0; i < 50; i++) {
    if (i < 10) {
      result.push(`0${i}`)
    } else {
      result.push(`${i}`)
    }
  }
  return result
}

export const generateHighNumber = (): string[] => {
  const result = []
  for (let i = 50; i < 100; i++) {
    result.push(`${i}`)
  }
  return result
}

export const generateOddNumber = (): string[] => {
  const result = []
  for (let i = 1; i < 100; i += 2) {
    const numStr = i.toString().padStart(2, '0')
    result.push(numStr)
  }

  return result
}

export const generateEvenNumber = (): string[] => {
  const result: string[] = []
  for (let i = 0; i < 100; i += 2) {
    result.push(i.toString().padStart(2, '0'))
  }
  return result
}

export type BetNumber = {
  number: string
  active: boolean
}
export type ThreeNumberParent = {
  parent: string
  active: boolean
  children: BetNumber[]
}
type GenerateThreeNumber = () => ThreeNumberParent[]
export const generateThreeNumber: GenerateThreeNumber = () => {
  const numbers = []

  for (let i = 0; i <= 9; i++) {
    const parentNumber = (i * 100).toString().padStart(3, '0')
    const parentObject: ThreeNumberParent = {
      parent: parentNumber,
      active: parentNumber === '000' ? true : false,
      children: []
    }

    for (let j = 0; j <= 99; j++) {
      const childNumber = (i * 100 + j).toString().padStart(3, '0')
      parentObject.children.push({ number: childNumber, active: false })
    }
    numbers.push(parentObject)
  }

  return numbers
}
type GenerateTwoNumber = () => BetNumber[]
export const generateTwoNumber: GenerateTwoNumber = () => {
  const numbers: BetNumber[] = []
  for (let i = 0; i < 100; i++) {
    const number = i.toString().padStart(2, '0')
    const object = { number, active: false }
    numbers.push(object)
  }
  return numbers
}
type GenerateRunningNumber = () => BetNumber[]
export const generateRunningNumber: GenerateRunningNumber = () => {
  const numbers: BetNumber[] = []
  for (let i = 0; i < 10; i++) {
    const number = i.toString()
    const object = { number, active: false }
    numbers.push(object)
  }
  return numbers
}

export const NUMBER_FORMAT_WITHOUT_DECIMAL = '0,0'
export const NUMBER_FORMAT = '0,0.00'
