import { useTranslation } from 'react-i18next'
export interface LoadMoreProps {
  onClick: () => void
}
const LoadMore = ({ onClick }: LoadMoreProps) => {
  const { t } = useTranslation()
  return (
    <div className="loadmore-container container mt-4 mb-4">
      <div className="row">
        <div className="col-12 d-flex justify-content-center">
          <a
            onClick={onClick}
            title={t('loadMore.other').toString()}
            className="text-decoration-none text-light-black btn-gold radius-8 space-btn-inner"
          >
            {t('loadMore.other')}
          </a>
        </div>
      </div>
    </div>
  )
}

export default LoadMore
