import {
  LimitTemplateBasePrice,
  LimitTemplateSpecialPrice,
  ResponseProcessLimit
} from 'redux/saga/limitHandlerSaga'
import { BetLottoType } from './lotto-context'

export const isInRange = (
  currentPrice: number,
  minPrice?: number | null,
  maxPrice?: number | null
): boolean => {
  if (minPrice !== undefined && minPrice !== null && currentPrice < minPrice) {
    return false
  }
  if (maxPrice !== undefined && maxPrice !== null && currentPrice > maxPrice) {
    return false
  }
  return true
}
// Helper function to check for special limits
const checkSpecialLimits = (
  number: string,
  betType: BetLottoType,
  totalBet: number,
  specialLimits: LimitTemplateSpecialPrice[]
): { limit: 'none' | 'limit' | 'range'; appliedRate: number } => {
  for (const specialLimit of specialLimits) {
    if (
      specialLimit.bet_number === number &&
      specialLimit.bet_type === betType &&
      specialLimit.type === 'limit' &&
      specialLimit.limit_price != null &&
      totalBet > specialLimit.limit_price
    ) {
      console.log(totalBet, specialLimit.limit_price)
      console.log('in special limit')
      return { limit: 'limit', appliedRate: 0 }
    }
  }
  return { limit: 'none', appliedRate: 0 }
}

// Helper function to check for special ranges
const checkSpecialRanges = (
  number: string,
  betType: BetLottoType,
  totalBet: number,
  specialRate: number,
  specialLimits: LimitTemplateSpecialPrice[]
): { limit: 'none' | 'limit' | 'range'; appliedRate: number } => {
  let latestValidRate = specialRate
  let limit: 'none' | 'limit' | 'range' = 'none'

  for (const specialLimit of specialLimits) {
    if (
      specialLimit.bet_number === number &&
      specialLimit.bet_type === betType &&
      specialLimit.type === 'range'
    ) {
      const min = specialLimit.bet_price_min ?? -Infinity
      const max = specialLimit.bet_price_max ?? Infinity
      const rewardPercent = specialLimit.reward_percent ?? 100

      if (isInRange(totalBet, min, max)) {
        console.log('in special range')
        latestValidRate = specialRate * (rewardPercent / 100)
        limit = 'range' // Mark as range hit
      }
    }
  }

  return { limit, appliedRate: latestValidRate }
}

// Helper function to check for base limits
const checkBaseLimits = (
  totalBet: number,
  baseLimits: LimitTemplateBasePrice[]
): { limit: 'none' | 'limit' | 'range'; appliedRate: number } => {
  for (const baseLimit of baseLimits) {
    if (
      baseLimit.type === 'limit' &&
      baseLimit.limit_price != null &&
      totalBet > baseLimit.limit_price
    ) {
      console.log('in base limit')
      return { limit: 'limit', appliedRate: 0 }
    }
  }
  return { limit: 'none', appliedRate: 0 }
}

// Helper function to check for base ranges with gap handling and correct rate application
const checkBaseRanges = (
  totalBet: number,
  baseRate: number,
  baseLimits: LimitTemplateBasePrice[]
): { limit: 'none' | 'limit' | 'range'; appliedRate: number } => {
  let latestValidRate = baseRate
  let limit: 'none' | 'limit' | 'range' = 'none'

  for (const baseLimit of baseLimits) {
    if (baseLimit.type === 'range') {
      const min = baseLimit.bet_price_min ?? -Infinity
      const max = baseLimit.bet_price_max ?? Infinity
      const payRatePercent = baseLimit.reward_percent ?? 100
      console.log('inbase range')
      if (isInRange(totalBet, min, max)) {
        latestValidRate = baseRate * (payRatePercent / 100)
        limit = 'range'
      }
    }
  }

  return { limit, appliedRate: latestValidRate }
}

// Main function to determine limit type and applied rate using helper functions
export const getLimitTypeWithRateUsingTemplate = (
  number: string,
  betType: BetLottoType,
  baseRate: number,
  betPrice: number,
  limitTemplate?: ResponseProcessLimit
): { limit: 'none' | 'limit' | 'range'; appliedRate: number } => {
  if (!limitTemplate) {
    return { limit: 'none', appliedRate: baseRate }
  }

  const { current_price, base_limit_price, special_limit_price } = limitTemplate
  const currentBet =
    current_price.current_bet[betType]?.[number]?.current_bet || 0
  const totalBet = currentBet + betPrice

  // Check if there are any special rules defined for this betType and number
  const hasSpecialRules = special_limit_price.some(
    (specialLimit) =>
      specialLimit.bet_number === number && specialLimit.bet_type === betType
  )

  if (hasSpecialRules) {
    // Step 1: Check for special limits for the specific betType and number
    let result = checkSpecialLimits(
      number,
      betType,
      totalBet,
      special_limit_price
    )
    if (result.limit !== 'none') return result // Return immediately if a special limit is found

    // Step 2: Check for special ranges for the specific betType and number
    result = checkSpecialRanges(
      number,
      betType,
      totalBet,
      baseRate,
      special_limit_price
    )
    if (result.limit !== 'none') return result // Return immediately if a special range is found

    // If special rules exist but none apply, return 'none' and baseRate
    return { limit: 'none', appliedRate: baseRate }
  }

  // Only check base rules if no special rules exist
  // Step 3: Check for base limits
  let result = checkBaseLimits(totalBet, base_limit_price)
  if (result.limit !== 'none') return result // Return if a base limit is found

  // Step 4: Check for base ranges
  result = checkBaseRanges(totalBet, baseRate, base_limit_price)
  if (result.limit !== 'none') return result // Return if a base range is found

  // If no limit condition was met, return 'none' and baseRate
  return { limit: 'none', appliedRate: baseRate }
}
