import { useTranslation } from 'react-i18next'
import { GenericWidget } from 'components'
import { GenericWidgetProps } from 'components/GenericWidget'
const Page404 = () => {
  const { t } = useTranslation()
  const Page404Data: GenericWidgetProps = {
    component: 'Layout',
    items: [
      {
        component: 'Component404',
        props: {
          title: '404 Oops!',
          header: t('page404.header'),
          info: t('page404.info')
        }
      }
    ]
  }
  return <GenericWidget {...Page404Data} />
}
export default Page404
