import { useTranslation } from 'react-i18next'
import Modal, { Props } from 'react-modal'
export interface ModalComponentProps extends Props {
  onClickOk?: () => void
  onClickCancel?: () => void
  title?: string
  children?: React.ReactNode
}
const ModalComponent = (props: ModalComponentProps) => {
  const { t } = useTranslation()
  return (
    <Modal {...props}>
      <div className="row">
        <div className="col-12">
          <h3 className="">{props.title}</h3>
        </div>
        {props.children}
        {props?.onClickOk && (
          <div className="col-lg-12 col-md-12 col-xs-12">
            <button
              type="button"
              className="btn-gold radius-8 text-light-black  justify-content-center space-btn-inner text-decoration-none"
              onClick={() => props?.onClickOk?.()}
            >
              {t('message.confirm')}
            </button>
            <button
              type="button"
              className="btn"
              onClick={() => props?.onClickCancel?.()}
            >
              {t('message.cancel')}
            </button>
          </div>
        )}
      </div>
    </Modal>
  )
}
Modal.setAppElement('#root')

export default ModalComponent
