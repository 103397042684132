import styled from 'styled-components'
const DisabledPanel = styled.div`
  background: rgba(0, 0, 0, 0.7);
  z-index: 998;
  transition: all 0.5s ease-in-out;
  width: 100%;
  height: 100%;
  position: absolute;
`
export default DisabledPanel
