import { ResultCardProps, ResultWrapper } from 'components/ResultCard'
import { Column, GenericTable } from 'components/TablePagination'
import { FixTypeLater } from 'react-redux'

export interface ResultTableProps extends ResultCardProps {
  columns: Column[]
  data: FixTypeLater
}
export const ResultTable = ({
  title,
  icon,
  detail,
  header,
  headerResult,
  columns,
  data
}: ResultTableProps) => {
  return (
    <ResultWrapper
      title={title}
      icon={icon}
      detail={detail}
      header={header}
      headerResult={headerResult}
    >
      <GenericTable
        columns={columns}
        data={data}
        containerClassName="result_table"
      />
    </ResultWrapper>
  )
}
