import { AxiosResponse } from 'axios'
import { ResponseStructure, callAPI } from '.'
import { sagaActions } from '../sagaActions'
import { FixTypeLater } from 'react-redux'
import { call, put, takeLatest } from 'redux-saga/effects'
import {
  fetchLimitRound,
  fetchLimitRoundFailure,
  fetchLimitRoundSuccess
} from 'redux/reducers/limitNumberReducer'
import { systemErrorHandler } from './errorHandlerSaga'
const API_LIMIT_GET_LIMIT_BY_ROUND = `${process.env.REACT_APP_HOST}/api/v1/lotto/game/round/:roundId/limit_price_v2`

export interface LimitTemplateBasePrice {
  id: number
  type: string
  bet_price_max?: number | null // Optional and nullable
  bet_price_min?: number | null // Optional and nullable
  reward_percent?: number | null // Optional and nullable
  limit_price?: number | null // Optional and nullable
  limit_template_id: number // Foreign key
  provider_id: number // Foreign key
  create_at: string // ISO formatted date string
  update_at: string // ISO formatted date string
}

// Interface for LimitTemplateSpecialPrice
export interface LimitTemplateSpecialPrice {
  id: number
  type?: string | null // Optional and nullable
  bet_type?: string | null // Optional and nullable
  bet_number: string
  bet_price_min?: number | null // Optional and nullable
  bet_price_max?: number | null // Optional and nullable
  reward_percent?: number | null // Optional and nullable
  limit_price?: number | null // Optional and nullable
  limit_template_id: number // Foreign key
  provider_id: number // Foreign key
  create_at: string // ISO formatted date string
  update_at: string // ISO formatted date string
}

// TypeScript equivalent of BetType
export interface BetDetail {
  number: string
  current_bet: number
}

// Interface for BetType used in BetLimit
export interface BetType {
  [key: string]: {
    [key: string]: BetDetail
  }
}

// Interface for BetLimit
export interface BetLimit {
  round_id: number
  provider_id: number
  current_bet: BetType
}

// Main ResponseProcessLimit Interface
export interface ResponseProcessLimit {
  base_limit_price: LimitTemplateBasePrice[]
  special_limit_price: LimitTemplateSpecialPrice[]
  current_price: BetLimit
}
interface ResponseLimitGame extends ResponseStructure {
  data: {
    limit: ResponseProcessLimit
  }
}

function* fetchLimitByRound({
  payload: { roundId }
}: {
  payload: { roundId: string }
}) {
  try {
    yield put(fetchLimitRound())
    const result: AxiosResponse<ResponseLimitGame> = yield call(() =>
      callAPI({
        url: API_LIMIT_GET_LIMIT_BY_ROUND.replace(':roundId', roundId)
      })
    )
    if (result?.data?.status === 'OK') {
      yield put(fetchLimitRoundSuccess(result.data?.data?.limit))
    } else {
      yield put(fetchLimitRoundFailure(result?.data?.message))
    }
  } catch (error: FixTypeLater) {
    yield put(fetchLimitRoundFailure(error.message))
    yield systemErrorHandler(error)
  }
}
export function* watchFetchLimit() {
  yield takeLatest<FixTypeLater>(
    sagaActions.FETCH_LIMIT_ROUND_NUMBERS,
    fetchLimitByRound
  )
}
