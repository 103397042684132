export interface PageTitleProps {
  title?: string
  details?: string
}
const PageTitle = ({ title, details }: PageTitleProps) => (
  <h2 className="bet-title-details-wrapper radius-16 d-flex justify-content-start mb-4">
    <span className="bet-title">{title}</span>
    {details && <span className="bet-title-details">{details}</span>}
  </h2>
)

export default PageTitle
