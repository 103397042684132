import TablePagination, {
  TablePaginationProps
} from 'components/TablePagination'
import { useTranslation } from 'react-i18next'

const YeeKeeTable = ({
  data,
  columns,
  limit,
  onPageChange,
  onLimitChange,
  page,
  count,
  loading
}: TablePaginationProps) => {
  const { t } = useTranslation()
  return (
    <>
      <div className="row mb-3">
        <div className="col-12">
          <div className="yeekee-result-number-summary d-flex justify-content-center align-items-center flex-column">
            <strong className="yeekee-result-number-summary-title">
              {t('betResult.list_member_put_number')}
            </strong>
          </div>
        </div>
      </div>
      <TablePagination
        noHeader
        trClass="yeekee-result-player-lists"
        columns={columns}
        limit={limit}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
        page={page}
        data={data ?? []}
        count={count}
        loading={loading}
      />
    </>
  )
}
export default YeeKeeTable
