import { useNumberInput } from 'components/NumberInput/useNumberInput'
import { Column } from 'components/TablePagination'
import { usePagination } from 'components/TablePagination/usePagination'
import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootState } from 'redux/reducers'
import {
  YeekeePutNumberData,
  YeekeePutNumberPagination
} from 'redux/reducers/yeekeeReducer'
import {
  ListYeekeePutNumberPagePayload,
  YeekeePutNumberPayload
} from 'redux/saga/yeekeeSaga'
import { sagaActions } from 'redux/sagaActions'
import {
  DATE_TIME_SECOND_FORMAT_HUMAN,
  convertToDateTime
} from 'utils/date-time-utils'

export const useShootNumber = (isShootNumber?: boolean) => {
  const { t, i18n } = useTranslation()
  const { gameId, roundId } = useParams()
  const [countDown, setCountDown] = useState<number | undefined>()
  const maxDigit = 5
  const { addDigit, currentNumber, deleteNumber, resetNumber } =
    useNumberInput(maxDigit)
  const dispatch = useDispatch()
  const {
    loadingPutNumber,
    loadingFetchNumbers,
    data,
    error,
    response,
    summary,
    yeekeeConfig
  } = useSelector((state: RootState) => {
    return {
      loadingFetchNumbers: state.yeekee.loading.listYeekeePutNumber,
      loadingPutNumber: state.yeekee.loading.putNumberYeekee,
      data: state.yeekee.listYeekeePutNumber,
      error: state.yeekee.error,
      response: state.yeekee.putNumberYeekee,
      summary: state.yeekee.listYeekeePutNumber.summary,
      yeekeeConfig: state.games.round?.yeekee_config
    }
  })
  useEffect(() => {
    if (currentNumber.length === maxDigit) {
      doPostPutNumber(currentNumber.join(''))
    }
    if (countDown) {
      setTimeout(() => {
        const pCountDown = countDown === 1 ? undefined : countDown - 1
        setCountDown(pCountDown)
      }, 1000)
    }
  }, [currentNumber, countDown])
  const pagination = usePagination(undefined, 20)
  const doPostPutNumber = (putNumber: string) => {
    const payload: YeekeePutNumberPayload = {
      gameId: !!gameId ? parseInt(gameId) : -1,
      roundId: !!roundId ? parseInt(roundId) : -1,
      inputNumber: putNumber
    }
    dispatch({ type: sagaActions.POST_YEEKEE_PUT_NUMBER, payload })
    if (!loadingPutNumber && error) {
      toast.error(error)
      dispatch({ type: sagaActions.CLEAR_GLOBAL_ERROR })
    }
    if (!error && !loadingPutNumber) {
      setCountDown(10)
    }
    resetNumber()
  }
  const fetchYeekeeListPutNumber = () => {
    const payloadYeekeePutNumber: ListYeekeePutNumberPagePayload = {
      page: pagination.page,
      pageSize: pagination.limit,
      roundId: roundId ?? ''
    }
    dispatch({
      type: sagaActions.FETCH_YEEKEE_LIST_NUMBER,
      payload: payloadYeekeePutNumber
    })
  }
  useEffect(() => {
    fetchYeekeeListPutNumber()
    if (response && response.status === 'OK') {
      toast.success(t('message.successPutNumber'))
      dispatch({ type: sagaActions.CLEAR_YEEKEE_PUT_RESPONSE })
    }
    if (isShootNumber) {
      const intervalId = setInterval(fetchYeekeeListPutNumber, 2000)
      return () => {
        clearInterval(intervalId) // Clear interval on component unmount
      }
    }
  }, [response, pagination.page, pagination.limit])

  const maskUsername = (username: string) => {
    const firstChar = username.charAt(0)
    const lastChar = username.charAt(username.length - 1)
    const maskedChars = '*'.repeat(username.length - 2) // Hide all characters except the first and last
    return `${firstChar}${maskedChars}${lastChar}`
  }
  const columns: Column[] = [
    {
      name: t('shootNumber.index'),
      thStyle: { textAlign: 'center' },
      accessor: 'id',
      Cell: (row: YeekeePutNumberData, idx) => {
        const rank = (pagination.page - 1) * pagination.limit + idx + 1
        const hilight = [
          yeekeeConfig?.bonus_row_1,
          yeekeeConfig?.bonus_row_2,
          yeekeeConfig?.bonus_row_3,
          yeekeeConfig?.bonus_row_4,
          yeekeeConfig?.bonus_row_5
        ].includes(rank)
        return (
          <div className="yeekee-result-list-no d-flex flex-lg-row flex-md-row flex-column align-items-start gap-1">
            <span
              className={`badge-yeekee-player-list ${
                hilight ? 'badge-winning' : ''
              } text-center`}
            >
              {t('shootNumber.rank', { rank })}
            </span>
            <span>{row.input_number}</span>
          </div>
        )
      }
    },
    {
      name: t('shootNumber.member'),
      accessor: 'player_username',
      Cell: (row: YeekeePutNumberData) => {
        return (
          <div className="yekee-result-player-name d-flex flex-lg-row justify-content-lg-center flex-md-row flex-column align-items-center gap-1">
            <span className="label-block">{t('shootNumber.member')}</span>
            <span className="label-name">
              {isShootNumber
                ? maskUsername(row.player_username)
                : row.player_username}
            </span>
          </div>
        )
      }
    },
    {
      name: t('shootNumber.date_time'),
      thStyle: { textAlign: 'center' },
      accessor: 'created_at',
      Cell: (row: YeekeePutNumberData) => {
        return (
          <div className="yeekee-result-bet-time d-flex flex-lg-row justify-content-lg-end flex-md-row flex-column align-items-end gap-1">
            <span className="label-block">{t('shootNumber.date_time')}</span>
            <span className="label-name">
              {convertToDateTime(
                row.saved_at,
                DATE_TIME_SECOND_FORMAT_HUMAN,
                localStorage.getItem('lang') ?? i18n.language
              )}
            </span>
          </div>
        )
      }
    }
  ]
  const loadingData: YeekeePutNumberPagination =
    !isShootNumber && loadingFetchNumbers
      ? {
          count: data.count,
          data: [],
          summary: data.summary
        }
      : data
  return {
    loadingPutNumber,
    loadingFetchNumbers,
    data: loadingData,
    columns,
    countDown,
    ...pagination,
    addDigit,
    currentNumber,
    deleteNumber,
    summary
  }
}
