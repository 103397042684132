import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ResponseProcessLimit } from 'redux/saga/limitHandlerSaga'

export interface LimitNumberState {
  loading: {
    fetchLimitRound: boolean
  }
  limitRound?: ResponseProcessLimit
  error: undefined | string
}
const initialState: LimitNumberState = {
  loading: {
    fetchLimitRound: false
  },
  limitRound: undefined,
  error: undefined
}

const limitNumberSlice = createSlice({
  name: 'limitNumber',
  initialState,
  reducers: {
    fetchLimitRound: (state) => ({
      ...state,
      loading: {
        ...state.loading,
        fetchLimitRound: true
      },
      error: undefined
    }),
    fetchLimitRoundSuccess: (
      state,
      action: PayloadAction<ResponseProcessLimit>
    ) => ({
      ...state,
      loading: {
        ...state.loading,
        fetchLimitRound: false
      },
      limitRound: action.payload,
      error: undefined
    }),
    fetchLimitRoundFailure: (state, action: PayloadAction<string>) => ({
      ...state,
      loading: {
        ...state.loading,
        fetchLimitRound: false
      },
      error: action.payload
    })
  }
})
export const {
  fetchLimitRound,
  fetchLimitRoundSuccess,
  fetchLimitRoundFailure
} = limitNumberSlice.actions

export default limitNumberSlice
