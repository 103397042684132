import React, { useEffect, useState, useRef } from 'react'
import styled, { keyframes } from 'styled-components'
import { Svg } from 'images'

export interface DynamicMarqueeProps {
  speed: string
  children: React.ReactNode[] | React.ReactNode
}

const marqueeAnimation = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
`

const MarqueeContainer = styled.div<DynamicMarqueeProps>`
  width=560px;
  white-space: nowrap;
  overflow: hidden;
  animation: ${marqueeAnimation} ${({ speed }) => speed} infinite linear;
`

const DynamicMarquee: React.FC<DynamicMarqueeProps> = ({ speed, children }) => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0)
  const childArray = Array.isArray(children) ? children : [children]
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth
      const textWidth = containerRef.current.scrollWidth
      const animationDuration = (textWidth / containerWidth) * parseFloat(speed)
      const interval = setInterval(() => {
        setCurrentTextIndex((prevIndex) => (prevIndex + 1) % childArray.length)
      }, animationDuration * 1000)

      return () => clearInterval(interval)
    }
  }, [speed, childArray.length])

  return (
    <div className="container my-5">
      <div className="annoucement-container p-2">
        <div className="row">
          <div className="col-2 col-lg-1 position-relative">
            <div className="position-absolute annoucement-icon">
              <img src={Svg.iconAnnouncement} alt="ประกาศ" />
            </div>
          </div>
          <div className="col-10 col-lg-11">
            <div className="marquee-container" style={{ overflow: 'hidden' }}>
              <MarqueeContainer
                className="marquee-text annoucement-content d-flex flex-row col-auto p-1"
                ref={containerRef}
                speed={speed}
                key={currentTextIndex}
              >
                <ul className="list-inline mb-0">
                  <li className="list-inline-item">
                    {childArray[currentTextIndex]}
                  </li>
                </ul>
              </MarqueeContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DynamicMarquee
