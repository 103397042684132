import { ResultCard } from 'components'
import useYeekeeResult from '../hook/useYeekeeResult'
import YeeKeeTable from './yeekee-table'
import { useTranslation } from 'react-i18next'
import { Svg } from 'images'

const YeekeeResult = () => {
  const { t } = useTranslation()
  const {
    pageTitle,
    data,
    columns,
    page,
    limit,
    onPageChange,
    onLimitChange,
    loadingFetchNumbers,
    bonusCard,
    resultCard,
    closeAt,
    onHandleBack
  } = useYeekeeResult()
  return (
    <div className="container main-container my-4">
      <div className="row">
        <div className="col-12 col-lg-1 mb-3">
          <div
            className="d-flex flex-row align-items-center gap-1"
            style={{ cursor: 'pointer' }}
            onClick={onHandleBack}
          >
            <img src={Svg.iconPrevious} alt="กลับ" />
            <span>{t('pollDetails.back')}</span>
          </div>
        </div>
        <div className="col-12 col-lg-8 offset-lg-1">
          <h2 className="d-flex justify-content-center mb-3">{pageTitle}</h2>
          <div className="yekee-result-status-round mb-3 p-3 d-flex justify-content-center">
            {closeAt}
          </div>
          <ResultCard {...resultCard} />
          <ResultCard {...bonusCard} />
          <div className="lotto-result-card-container p-3 mb-3">
            <YeeKeeTable
              data={data.data ?? []}
              count={data.count}
              limit={limit}
              onPageChange={onPageChange}
              onLimitChange={onLimitChange}
              columns={columns}
              page={page}
              loading={loadingFetchNumbers}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default YeekeeResult
