import axios from 'axios'
import { FixTypeLater } from 'react-redux'
export const ERROR_RECORD_NOT_FOUND = 'record not found'
export const getAxiosErrorMessage = (
  error: FixTypeLater
): string | undefined => {
  if (axios.isAxiosError(error)) {
    return error.response?.data.message
  }
  return undefined
}
