import { FixTypeLater } from 'react-redux'
import { call, put, takeEvery } from 'redux-saga/effects'
import { sagaActions } from '../sagaActions'
import { naviagePath } from 'redux/reducers/routerReducer'

//   const API_EXCHANGE_TOKEN = `${process.env.REACT_APP_HOST}/api/v1/seamless/exchange_token`

function* handleRedirect(action: FixTypeLater): FixTypeLater {
  console.log('handleRedirect: xxxxx')
  const { redirectToPath, delay } = action.payload
  console.log(redirectToPath, delay)
  yield put(
    naviagePath({
      navigatePath: redirectToPath,
      delay: delay
    })
  )
}

export function* routerHandlerSaga() {
  yield takeEvery<FixTypeLater>(
    sagaActions.ROUTER_NAVIGATE_REQUEST,
    handleRedirect
  )
}
