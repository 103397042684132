import { Svg, Img } from 'images'
import Logo from 'images/logo.svg'
import { useTranslation } from 'react-i18next'
import Lottie from 'react-lottie-player'
import animation from 'images/loadingbar-100.json'
const Loading = () => {
  const { t } = useTranslation()
  return (
    <section className="cotainer my-3 d-flex justify-content-center align-content-center vh-100 align-items-center">
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-center align-content-center align-items-center flex-column loader-container">
            <div className="loader-wrapper d-flex flex-column justify-content-center align-items-center align-content-center position-relative">
              <span className="loader position-absolute"></span>
              <span className="position-absolute">
                <img src={Logo} alt="movewinbet" />
              </span>
            </div>
            <p className="mt-3">{t('message.loading')} .....</p>
          </div>
        </div>
      </div>
    </section>
  )
}
const LoadingV2 = () => {
  const { t } = useTranslation()
  return (
    <div className="loading-page-container">
      <div className="loading-bg loading-bg-desktop loading-bg-mobile">
        <div className="position-relative">
          <div className="loading-bar-container">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="loading-logo mt-5 mb-3">
                      <a href="#" title="">
                        <img src={Img.logoLoading} alt="movewinbet" />
                      </a>
                    </div>
                    <h2 className="header-loading">
                      {t('message.loadingBanner')}
                    </h2>
                    <h3 className="sub-header-loading">
                      {t('message.loadingCaption')}
                    </h3>
                    <div className="lottie-player-container position-relative">
                      <div className="lottie-player-wrapper">
                        <Lottie
                          play
                          loop
                          inputMode="none"
                          style={{ height: '55vh' }}
                          animationData={animation}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export const LoadingMiniContent = () => {
  return (
    <div className="loader-data-container">
      <div className="d-flex justify-content-center align-content-center align-items-center flex-column loader-container">
        <div className="loader-wrapper loading-data-wrapper d-flex flex-column justify-content-center align-items-center align-content-center position-relative">
          <span className="loader position-absolute"></span>
          <span className="position-absolute">
            <img src={Svg.Logo} alt="movewinbet" />
          </span>
        </div>
      </div>
    </div>
  )
}
export default LoadingV2
