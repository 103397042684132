import { SwiperComponentProps } from 'components/Swiper'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/reducers'
import {
  isEncryptLotto,
  isGroupLotto,
  isYeekeeType
} from 'utils/lotto-type-utils'
import { GetResultProps } from './useResulted'
import { useTranslation } from 'react-i18next'
import Resulted from '../resulted'
import { ResultTable } from 'components/ResultTable'
import { useNavigate } from 'react-router-dom'

export const useResultSlider = () => {
  const navigation = useNavigate()
  const { i18n, t } = useTranslation()
  const { results } = useSelector((state: RootState) => ({
    results: state.games.results
  }))
  const swiperProps: SwiperComponentProps = { items: [] }
  results?.forEach((i) => {
    if (i.rounds.length > 0) {
      if (
        !isYeekeeType(i.type) &&
        !isEncryptLotto(i.type) &&
        i.rounds.length > 0
      ) {
        const props = GetResultProps(i18n, t, i.rounds?.[0], i)
        swiperProps.items.push(<Resulted key={i.id} {...props} swiper />)
      }
      if (
        !isYeekeeType(i.type) &&
        isGroupLotto(i.type) &&
        !isEncryptLotto(i.type)
      ) {
        const props = GetResultProps(i18n, t, undefined, i)
        swiperProps.items.push(
          <Resulted
            key={i.id}
            hideRound
            {...props}
            noRound={i.rounds.length === 0}
          />
        )
      }
      if (
        isYeekeeType(i.type) ||
        (isEncryptLotto(i.type) && i.rounds.length > 0)
      ) {
        const props = GetResultProps(i18n, t, undefined, i, navigation)
        swiperProps.items.push(
          <ResultTable key={i.id} {...props} data={i.rounds} />
        )
      }
    }
  })
  return { swiperProps }
}
