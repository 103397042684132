import { useNumberInput } from 'components/NumberInput/useNumberInput'
import { useLottoContext } from 'contexts'
import { BetLottoType } from 'contexts/lotto-context'
import { useEffect } from 'react'
export const useGroupBet = () => {
  const { addLottoList, betPriceChange, game } = useLottoContext()
  let betType: BetLottoType = 'bet_four'
  let maxDigit = 4
  if (game?.type === 'group_six') {
    maxDigit = 6
    betType = 'bet_six'
  }
  const { addDigit, currentNumber, deleteNumber, resetNumber } =
    useNumberInput(maxDigit)
  useEffect(() => {
    if (currentNumber.length === maxDigit) {
      addLottoList(currentNumber.join(''), betType, game?.cost_price ?? 400)
      resetNumber()
    }
  }, [currentNumber])

  return {
    maxDigit,
    addDigit,
    currentNumber,
    deleteNumber,
    resetNumber,
    betPriceChange
  }
}
