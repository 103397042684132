import axios from 'axios'
import { FixTypeLater } from 'react-redux'
import { put, takeLatest } from 'redux-saga/effects'
import {
  SystemError,
  fetchErrorHandler,
  clearErrorHandler
} from 'redux/reducers/errorHandlerReducer'
import { sagaActions } from 'redux/sagaActions'

export function* systemErrorHandler(e: FixTypeLater) {
  let errorPayload: SystemError = {
    status: sagaActions.FETCH_FAILED,
    message: e.message,
    code: e.code
  }
  if (axios.isAxiosError(e)) {
    errorPayload = {
      status: e.code,
      message: e.message,
      code: e.response?.status
    }
  }
  yield put(fetchErrorHandler(errorPayload))
}

export function* clearGlobalError() {
  yield put(clearErrorHandler())
}

export function* watchGlobalError() {
  yield takeLatest(sagaActions.CLEAR_GLOBAL_ERROR, clearGlobalError)
}
