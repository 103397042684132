import { Column, GenericTable } from 'components/TablePagination'
import { useTranslation } from 'react-i18next'

export type BetRuleData = {
  bet_type: string
  bet_price: number
}
interface BetRulePanelProps {
  data: BetRuleData[]
}
const BetRulePanel = ({ data }: BetRulePanelProps) => {
  const { t } = useTranslation()
  const Columns: Column[] = [
    {
      name: t('betRule.columns.betType'),
      accessor: 'bet_type',
      Cell: (row: BetRuleData) => (
        <>{t(`betDetailPanel.betLottoType.${row.bet_type}`)}</>
      )
    },
    { name: t('betRule.columns.betPrice'), accessor: 'bet_price' }
  ]
  return (
    <>
      <h3 className="font-24 text-gold text-center">{t('betRule.payRate')}</h3>
      <div className="bet-detail-dashboard-wrapper mb-4">
        <GenericTable columns={Columns} data={data} />
      </div>
      <p className="text-red">{t('betRule.t&c')}</p>
    </>
  )
}

export default BetRulePanel
