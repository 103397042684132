import { useState } from 'react'

export const usePagination = (order?: string, initLimit?: number) => {
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(initLimit ?? 10)
  const [orderBy, setOrderBy] = useState<string>(order ?? 'created_at asc')
  const onPageChange = (newPage: number) => setPage(newPage)
  const onLimitChange = (newLimit: number) => setLimit(newLimit)
  const onSort = (sort?: { id: string; desc: boolean }) => {
    const pSort = `${sort?.id} ${sort?.desc ? 'desc' : 'asc'}`
    setOrderBy(pSort)
  }
  return {
    page,
    limit,
    orderBy,
    onPageChange,
    onLimitChange,
    onSort
  }
}
