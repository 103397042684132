import { FixTypeLater } from 'react-redux'
import { put, takeLatest, call } from 'redux-saga/effects'
import { sagaActions } from '../sagaActions'
import { setPlayerToken } from 'redux/reducers/userReducer'
import { callPublicAPI } from '.'

const API_EXCHANGE_TOKEN = `${process.env.REACT_APP_HOST}/api/v1/seamless/exchange_token`

function* initializeToServer(action: FixTypeLater): FixTypeLater {
  console.log('initializeToServer')
  const { token } = action.payload

  try {
    const result: FixTypeLater = yield call(callPublicAPI, {
      url: API_EXCHANGE_TOKEN,
      method: 'POST',
      data: {
        token
      }
    })

    if (result.status !== 200) {
      throw new Error('Could not initilize to server')
    }

    yield put({
      type: sagaActions.INITIALIZED_STATE_TO_SERVER,
      payload: {
        resultExchangeToken: result.data,
        token: token
      }
    })
  } catch (e) {
    // console.log(e)
    yield put({
      type: sagaActions.ROUTER_NAVIGATE_REQUEST,
      payload: { redirectToPath: '/session_error', delay: 1500 }
    })
  }
}

function* initializedToServer(action: FixTypeLater): FixTypeLater {
  const jwt = action.payload.resultExchangeToken.access_token // replace with your JWT
  const base64Url = jwt.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  yield call(() => {
    localStorage.setItem(
      'access_token',
      action.payload.resultExchangeToken.access_token
    )
    localStorage.setItem('token', action.payload.token)
    localStorage.setItem('user_info', jsonPayload)
  })

  const userInfo = JSON.parse(String(jsonPayload))

  yield put(
    setPlayerToken({
      accessToken: action.payload.resultExchangeToken.access_token,
      token: action.payload.token,
      userName: userInfo?.unique_username,
      userId: userInfo?.user_id,
      providerId: userInfo?.provider_id,
      ipAddeess: userInfo?.ip_address
    })
  )

  // window.location.href = "/"
  yield put({
    type: sagaActions.ROUTER_NAVIGATE_REQUEST,
    payload: { redirectToPath: '/', delay: 0 }
  })
}

function* initializeApp(action: FixTypeLater): FixTypeLater {
  console.log('initializeHomePage')

  const pathname = action.payload.location.pathname
  const pathnames = pathname.split('/')
  console.log(pathnames.includes('startGame'))

  if (!pathnames.includes('startGame')) {
    const accessToken = localStorage.getItem('access_token')
    const token = localStorage.getItem('token')
    const userInfoStr: string | null = localStorage.getItem('user_info')
    const userInfo = JSON.parse(String(userInfoStr))

    if (!accessToken || !token || !userInfoStr) {
      yield put({
        type: sagaActions.ROUTER_NAVIGATE_REQUEST,
        payload: { redirectToPath: '/session_error', delay: 0 }
      })
    } else {
      yield put(
        setPlayerToken({
          accessToken: accessToken?.toString(),
          token: token?.toString(),
          userName: userInfo?.unique_username,
          userId: userInfo?.user_id,
          providerId: userInfo?.provider_id,
          ipAddeess: userInfo?.ip_address
        })
      )
    }
  } else {
    // yield put({
    //   type: sagaActions.INITIALIZE_STATE_TO_SERVER,
    //   payload: { redirectToPath: '/', delay: 0 }
    // })
  }
}

export function* initialize() {
  console.log('Init saga')
  yield takeLatest<FixTypeLater>(
    sagaActions.INITIALIZE_STATE_TO_SERVER,
    initializeToServer
  )

  yield takeLatest<FixTypeLater>(
    sagaActions.INITIALIZED_STATE_TO_SERVER,
    initializedToServer
  )

  yield takeLatest<FixTypeLater>(sagaActions.INITIALIZE_APP, initializeApp)
}
