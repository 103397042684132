import { PayloadAction, createSlice } from '@reduxjs/toolkit'
export interface TodoState {
  todos: Todo[]
}
interface Todo {
  userId: number
  id: number
  title: string
  completed: boolean
}
const initialState: TodoState = {
  todos: []
}

const todoSlice = createSlice({
  name: 'todo',
  initialState,
  reducers: {
    fetchData: (state, action: PayloadAction<Todo[]>) => {
      return {
        todos: action.payload
      }
    }
  }
})
export const { fetchData } = todoSlice.actions
export default todoSlice
