import { AxiosResponse } from 'axios'
import { call, put, takeLatest } from 'redux-saga/effects'
import {
  PaginationPayloadPaginationStructure,
  ResponsePaginationStructure,
  ResponseStructure,
  callAPI
} from '.'
import { sagaActions } from '../sagaActions'
import {
  Game,
  Group,
  Round,
  Ticket,
  fetchCancelBetResponse,
  fetchGameData,
  fetchResults,
  fetchRoundData,
  fetchRoundList,
  fetchBetResponse,
  fetchTicketDetailData,
  fetchGroupData,
  clearResponseData,
  fetchGroupDataSuccess,
  fetchGroupDataFailure,
  fetchGameDataSuccess,
  fetchGameDataFailure,
  fetchRoundListSuccess,
  fetchRoundListFailure,
  fetchRoundDataSuccess,
  ListLimitPriceNumber,
  fetchListLimitPriceNumberData,
  fetchListLimitPriceNumberDataSuccess,
  fetchListLimitPriceNumberDataFailure,
  fetchTicketDetailDataSuccess,
  fetchTicketDetailDataFailure,
  fetchTicketPagination,
  fetchTicketPaginationSuccess,
  fetchTicketPaginationFailure,
  RoundPage,
  fetchRoundDataFailure,
  loadMoreRoundList,
  loadMoreRoundListSuccess,
  loadMoreRoundListFailure,
  fetchResultsFailure,
  fetchResultsSuccess,
  Announcement,
  fetchAnnouncement,
  fetchAnnouncementSuccess,
  fetchAnnouncementFailure
} from 'redux/reducers/gameReducer'
import { systemErrorHandler } from './errorHandlerSaga'
import { FixTypeLater } from 'react-redux'
import { BetLottoType } from 'contexts/lotto-context'
import { toast } from 'react-hot-toast'
import {
  ERROR_RECORD_NOT_FOUND,
  getAxiosErrorMessage
} from 'utils/error-handler-utils'
import i18next from 'i18next'
const LOADING_MESSAGE = 'กรุณารอสักครู่'
const API_FETCH_ANNOUNCEMENT = `${process.env.REACT_APP_HOST}/api/v1/lotto/announcement/list`
const API_FETCH_RESULTS = `${process.env.REACT_APP_HOST}/api/v1/lotto/results`
const API_FETCH_GAME = `${process.env.REACT_APP_HOST}/api/v1/lotto/game/:gameId`
const API_FETCH_GROUP = `${process.env.REACT_APP_HOST}/api/v1/lotto/game/groups`
const API_FETCH_ROUND = `${process.env.REACT_APP_HOST}/api/v1/lotto/game/round/:roundId`
const API_FETCH_ROUND_LIST = `${process.env.REACT_APP_HOST}/api/v1/lotto/game/round/list`
const API_FETCH_TICKET_LIST = `${process.env.REACT_APP_HOST}/api/v1/lotto/game/ticket/pageList`
const API_FETCH_TICKET_LIST_HISTORY = `${process.env.REACT_APP_HOST}/api/v1/lotto/game/ticket/history/pageList`
const API_FETCH_TICKET_DETAIL = `${process.env.REACT_APP_HOST}/api/v1/lotto/game/ticket/:ticketId`
const API_FETCH_TICKET_DETAIL_HISTORY = `${process.env.REACT_APP_HOST}/api/v1/lotto/game/ticket/history/:ticketId`
const API_POST_BET_GAME = `${process.env.REACT_APP_HOST}/api/v1/lotto/bet`
const API_POST_CANCEL_BET = `${process.env.REACT_APP_HOST}/api/v1/lotto/bet/:betId/cancel`
const API_FETCH_LIMIT_PRICE_NUMBER = `${process.env.REACT_APP_HOST}/api/v1/lotto/game/round/:roundId/listLimitPriceNumbers`

interface ListLimitPriceNumberResponse extends ResponseStructure {
  data: {
    limit_price_number: ListLimitPriceNumber[]
  }
}
interface ListGroupsResponse extends ResponseStructure {
  data: {
    groups: Group[]
    results: Game[]
  }
}

export interface BetCancelResponse extends ResponseStructure {
  data: string
}
interface GameRespsonse extends ResponseStructure {
  data: {
    game: Game
  }
}
interface ListGameResponse extends ResponseStructure {
  data: {
    games: Game[]
  }
}

interface ListGameRoundResponse extends ResponseStructure {
  data: {
    data: RoundPage
  }
}
export interface BetSuccessResponse extends ResponseStructure {
  data: {
    bet_amount: number
    created_at: string
    id: number
    percent_share: number
    provider_id: number
    provider_user_id: number
    status: string
    updated_at: string
  }
}
interface FetchListGameRoundPayload
  extends PaginationPayloadPaginationStructure {
  gameId: number
}
export interface FetchGameRoundPayload {
  roundId: number
}

interface AnnouncementResponse extends ResponseStructure {
  data: {
    announcements: Announcement[]
  }
}

export interface PayloadFetchTicketDetail {
  ticketId: number
}

export interface PayloadFetchGame {
  gameId: number
}

export interface BetGameItem {
  number: string
  price: number
  rate: number
  type: BetLottoType
}

export interface PayloadBetGame {
  betList: BetGameItem[]
  roundId: number
}
export interface PayloadBetCancel {
  betId: number
}

export interface PayloadFetchTickets
  extends PaginationPayloadPaginationStructure {
  ticketType?: string
  sort?: string
}

interface GameRoundResponse extends ResponseStructure {
  data: {
    round: Round
  }
}
interface ListTicketData extends ResponsePaginationStructure {
  data: Ticket[]
}
interface ListTicketResponse extends ResponseStructure {
  data: ListTicketData
}

interface TicketsDetailResponse extends ResponseStructure {
  data: {
    ticket_details: Ticket
  }
}

function* fetchGame({ payload: { gameId } }: { payload: PayloadFetchGame }) {
  try {
    yield put(fetchGameData())
    const result: AxiosResponse<GameRespsonse> = yield call(() =>
      callAPI({
        url: API_FETCH_GAME.replace(':gameId', gameId.toString())
      })
    )
    if (result?.data?.status === 'OK') {
      yield put(fetchGameDataSuccess(result?.data?.data?.game))
    } else {
      yield put(fetchGameDataFailure(result?.data?.message))
    }
  } catch (e: FixTypeLater) {
    if (e.response && e.response.status === 404) {
      yield put(fetchGameDataFailure(ERROR_RECORD_NOT_FOUND))
    }
    yield systemErrorHandler(e)
  }
}

function* fetchGameRoundList({
  payload: { page = 1, pageSize = 10, gameId }
}: {
  payload: FetchListGameRoundPayload
}) {
  try {
    yield put(fetchRoundList())
    const result: AxiosResponse<ListGameRoundResponse> = yield call(() =>
      callAPI({
        url: `${API_FETCH_ROUND_LIST}/${gameId}?page=${page}&pageSize=${pageSize}`
      })
    )
    if (result.data?.status === 'OK') {
      yield put(fetchRoundListSuccess(result?.data?.data?.data))
    } else {
      yield put(fetchRoundListFailure(result.data?.message))
    }
  } catch (e: FixTypeLater) {
    yield put(fetchRoundListFailure(e.message))
    yield systemErrorHandler(e)
  }
}

function* loadMoreGameRoundList({
  payload: { page = 1, pageSize = 10, gameId }
}: {
  payload: FetchListGameRoundPayload
}) {
  try {
    yield put(loadMoreRoundList())
    const result: AxiosResponse<ListGameRoundResponse> = yield call(() =>
      callAPI({
        url: `${API_FETCH_ROUND_LIST}/${gameId}?page=${page}&pageSize=${pageSize}`
      })
    )
    if (result?.data?.status === 'OK') {
      yield put(loadMoreRoundListSuccess(result?.data?.data?.data))
    } else {
      yield put(loadMoreRoundListFailure(result?.data?.message))
    }
  } catch (e: FixTypeLater) {
    yield put(loadMoreRoundListFailure(e.message))
    yield systemErrorHandler(e)
  }
}

function* postBetGame({
  payload: { betList, roundId }
}: {
  payload: PayloadBetGame
}) {
  const loading = toast.loading(LOADING_MESSAGE)
  try {
    const result: AxiosResponse<FixTypeLater> = yield call(() =>
      callAPI({
        url: API_POST_BET_GAME,
        method: 'post',
        data: {
          betList,
          roundId
        }
      })
    )
    if (result.data) {
      toast.dismiss(loading)
      yield put(fetchBetResponse(result.data))
    }
  } catch (e: any) {
    // Use any to catch the error properly
    toast.dismiss(loading)
    console.log('ERROR', e)

    // Check if the error response exists and contains a message
    if (
      e.response &&
      e.response.data &&
      e.response.data.message === 'ERROR_HIT_LIMIT'
    ) {
      // Handle the specific error message
      toast.error(i18next.t('error.hitLimit'))
    } else {
      // Handle other errors
      yield systemErrorHandler(e)
    }
  }
}
function* postCancelBetGame({
  payload: { betId }
}: {
  payload: PayloadBetCancel
}) {
  try {
    const result: AxiosResponse<BetCancelResponse> = yield call(() =>
      callAPI({
        url: API_POST_CANCEL_BET.replace(':betId', betId.toString()),
        method: 'post',
        data: {
          betId
        }
      })
    )
    yield put(fetchCancelBetResponse(result.data))
  } catch (e: FixTypeLater) {
    yield systemErrorHandler(e)
  }
}
function* fetchRound({
  payload: { roundId }
}: {
  payload: FetchGameRoundPayload
}) {
  try {
    yield put(fetchRoundData())
    const result: AxiosResponse<GameRoundResponse> = yield call(() =>
      callAPI({
        url: API_FETCH_ROUND.replace(':roundId', roundId.toString()),
        method: 'get'
      })
    )
    if (result?.data?.status === 'OK') {
      yield put(fetchRoundDataSuccess(result?.data?.data?.round))
    } else {
      console.log('RESULT_FAILED', result?.data?.message)
      yield put(fetchRoundDataFailure(result?.data?.message))
    }
  } catch (e: FixTypeLater) {
    const errMessage = getAxiosErrorMessage(e)
    if (errMessage) {
      yield put(fetchRoundDataFailure(errMessage))
    }
    yield systemErrorHandler(e)
  }
}

function* fetchTicketPage({
  payload: { ticketType, page, pageSize, sort }
}: {
  payload: PayloadFetchTickets
}) {
  try {
    let url = `${API_FETCH_TICKET_LIST}?page=${page}&pageSize=${pageSize}`
    if (ticketType && !!ticketType) {
      url += `&ticketType=${ticketType}`
    }
    if (sort && !!sort) {
      url += `&sort=${sort}`
    }
    yield put(fetchTicketPagination())
    const result: AxiosResponse<ListTicketResponse> = yield call(() =>
      callAPI({
        url,
        method: 'get'
      })
    )
    if (result?.data?.status === 'OK') {
      yield put(
        fetchTicketPaginationSuccess({
          count: result?.data?.data?.count,
          limit: result?.data?.data?.limit,
          page: result?.data?.data?.page,
          tickets: result?.data?.data?.data
        })
      )
    } else {
      yield put(fetchTicketPaginationFailure(result?.data?.message))
    }
  } catch (e: FixTypeLater) {
    yield put(fetchTicketPaginationFailure(e.message))
    yield systemErrorHandler(e)
  }
}
function* fetchTicketPageHistory({
  payload: { ticketType, page, pageSize, sort }
}: {
  payload: PayloadFetchTickets
}) {
  try {
    let url = `${API_FETCH_TICKET_LIST_HISTORY}?page=${page}&pageSize=${pageSize}`
    if (ticketType && !!ticketType) {
      url += `&ticketType=${ticketType}`
    }
    if (sort && !!sort) {
      url += `&sort=${sort}`
    }
    yield put(fetchTicketPagination())
    const result: AxiosResponse<ListTicketResponse> = yield call(() =>
      callAPI({
        url,
        method: 'get'
      })
    )
    if (result?.data?.status === 'OK') {
      yield put(
        fetchTicketPaginationSuccess({
          count: result?.data?.data?.count,
          limit: result?.data?.data?.limit,
          page: result?.data?.data?.page,
          tickets: result?.data?.data?.data
        })
      )
    } else {
      yield put(fetchTicketPaginationFailure(result?.data?.message))
    }
  } catch (e: FixTypeLater) {
    yield put(fetchTicketPaginationFailure(e.message))
    yield systemErrorHandler(e)
  }
}

function* fetchTicketsDetail({
  payload: { ticketId }
}: {
  payload: PayloadFetchTicketDetail
}) {
  try {
    yield put(fetchTicketDetailData())
    const result: AxiosResponse<TicketsDetailResponse> = yield call(() =>
      callAPI({
        url: API_FETCH_TICKET_DETAIL.replace(':ticketId', ticketId.toString()),
        method: 'get'
      })
    )
    if (result?.data?.status === 'OK') {
      const ticketDetails = result?.data?.data?.ticket_details
      ticketDetails.bet_amount = ticketDetails?.details.reduce(
        (sum, detail) => sum + detail.bet_amount,
        0
      )
      ticketDetails.reward_result = ticketDetails?.details.reduce(
        (sum, detail) => sum + detail.reward_amount,
        0
      )
      yield put(
        fetchTicketDetailDataSuccess(result?.data?.data?.ticket_details)
      )
    } else {
      yield put(fetchTicketDetailDataFailure(result?.data?.message))
    }
  } catch (e: FixTypeLater) {
    yield systemErrorHandler(e)
  }
}

function* fetchTicketsDetailHistory({
  payload: { ticketId }
}: {
  payload: PayloadFetchTicketDetail
}) {
  try {
    yield put(fetchTicketDetailData())
    const result: AxiosResponse<TicketsDetailResponse> = yield call(() =>
      callAPI({
        url: API_FETCH_TICKET_DETAIL_HISTORY.replace(
          ':ticketId',
          ticketId.toString()
        ),
        method: 'get'
      })
    )
    if (result?.data?.status === 'OK') {
      const ticketDetails = result?.data?.data?.ticket_details
      ticketDetails.bet_amount = ticketDetails?.details.reduce(
        (sum, detail) => sum + detail.bet_amount,
        0
      )
      ticketDetails.reward_result = ticketDetails?.details.reduce(
        (sum, detail) => sum + detail.reward_amount,
        0
      )
      yield put(
        fetchTicketDetailDataSuccess(result?.data?.data?.ticket_details)
      )
    } else {
      yield put(fetchTicketDetailDataFailure(result?.data?.message))
    }
  } catch (e: FixTypeLater) {
    yield systemErrorHandler(e)
  }
}

function* fetchGameGroup() {
  try {
    yield put(fetchGroupData())
    const result: AxiosResponse<ListGroupsResponse> = yield call(() =>
      callAPI({
        url: API_FETCH_GROUP
      })
    )
    if (result?.data?.status != 'OK') {
      yield put(fetchGroupDataFailure(result?.data.message))
      yield put(fetchResultsFailure())
    } else {
      yield put(fetchGroupDataSuccess(result?.data?.data?.groups))
      yield put(fetchResultsSuccess(result?.data?.data?.results))
    }
  } catch (e: FixTypeLater) {
    yield systemErrorHandler(e)
    yield put(fetchGroupDataFailure(e.message))
    yield put(fetchResultsFailure())
  }
}

function* fetchListLimitPriceNumber({
  payload: { roundId }
}: {
  payload: { roundId: string }
}) {
  try {
    yield put(fetchListLimitPriceNumberData())
    const result: AxiosResponse<ListLimitPriceNumberResponse> = yield call(() =>
      callAPI({
        url: API_FETCH_LIMIT_PRICE_NUMBER.replace(':roundId', roundId)
      })
    )
    if (result.data.status === 'OK') {
      yield put(
        fetchListLimitPriceNumberDataSuccess(
          result.data.data.limit_price_number
        )
      )
    } else {
      yield put(fetchListLimitPriceNumberDataFailure(result.data.message))
    }
  } catch (e: FixTypeLater) {
    yield put(fetchListLimitPriceNumberDataFailure(e.message))
    yield systemErrorHandler(e)
  }
}

function* fetchAnnouncementData() {
  try {
    yield put(fetchAnnouncement())
    const result: AxiosResponse<AnnouncementResponse> = yield call(() =>
      callAPI({
        url: API_FETCH_ANNOUNCEMENT
      })
    )
    if (result?.data?.status === 'OK') {
      yield put(fetchAnnouncementSuccess(result?.data?.data?.announcements))
    } else {
      yield put(fetchAnnouncementFailure(result?.data?.message))
    }
  } catch (e: FixTypeLater) {
    yield systemErrorHandler(e)
  }
}

function* fetchListResult({
  payload: { date }
}: {
  payload: { date: string }
}) {
  let url = API_FETCH_RESULTS
  if (date && date.length > 0) {
    url = `${API_FETCH_RESULTS}?date=${date}`
  }
  try {
    yield put(fetchResults())
    const result: AxiosResponse<ListGameResponse> = yield call(() =>
      callAPI({
        url: url
      })
    )
    if (result?.data?.status === 'OK') {
      yield put(fetchResultsSuccess(result?.data?.data?.games))
    } else {
      yield put(fetchResultsFailure(result?.data?.message))
    }
  } catch (e: FixTypeLater) {
    yield systemErrorHandler(e)
  }
}

function* clearResponse() {
  yield put(clearResponseData())
}

export function* watchFetchListGame() {
  yield takeLatest<FixTypeLater>(sagaActions.FETCH_RESULTS, fetchListResult)
  yield takeLatest<FixTypeLater>(sagaActions.POST_BET_GAME, postBetGame)
  yield takeLatest<FixTypeLater>(
    sagaActions.FETCH_LIST_GAME_ROUND,
    fetchGameRoundList
  )
  yield takeLatest<FixTypeLater>(
    sagaActions.LOAD_MORE_LIST_GAME_ROUND,
    loadMoreGameRoundList
  )
  yield takeLatest<FixTypeLater>(
    sagaActions.FETCH_LIMIT_PRICE_NUMBERS,
    fetchListLimitPriceNumber
  )
  yield takeLatest<FixTypeLater>(sagaActions.FETCH_GAME_ROUND, fetchRound)
  yield takeLatest<FixTypeLater>(
    sagaActions.FETCH_TICKET_PAGINATION,
    fetchTicketPage
  )
  yield takeLatest<FixTypeLater>(
    sagaActions.FETCH_TICKET_PAGINATION_HISTORY,
    fetchTicketPageHistory
  )
  yield takeLatest<FixTypeLater>(
    sagaActions.FETCH_TICKET_DETAIL,
    fetchTicketsDetail
  )
  yield takeLatest<FixTypeLater>(
    sagaActions.FETCH_TICKET_DETAIL_HISTORY,
    fetchTicketsDetailHistory
  )
  yield takeLatest<FixTypeLater>(sagaActions.POST_BET_CANCEL, postCancelBetGame)
  yield takeLatest<FixTypeLater>(sagaActions.FETCH_GROUPS, fetchGameGroup)
  yield takeLatest<FixTypeLater>(sagaActions.FETCH_GAME, fetchGame)
  yield takeLatest<FixTypeLater>(sagaActions.CLEAR_RESPONSE, clearResponse)
  yield takeLatest<FixTypeLater>(
    sagaActions.FETCH_ANNOUNCEMENTS,
    fetchAnnouncementData
  )
}
