import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from '@redux-saga/core'
import rootSaga from '../saga'
import reducers from '../reducers'

const sagaMiddleWare = createSagaMiddleware()
const store = configureStore({
  reducer: reducers,
  middleware: [sagaMiddleWare]
})

sagaMiddleWare.run(rootSaga)

export default store
