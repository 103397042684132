import { useTranslation } from 'react-i18next'

const EmptyContent = () => {
  const { t } = useTranslation()
  return (
    <div className="d-flex justify-content-center align-items-center">
      {t('message.empty')}
    </div>
  )
}
export default EmptyContent
