import { useNavigate, useParams } from 'react-router-dom'
import { usePollDetail } from './hook/usePoll'
import { BetDetailCard } from 'components'

import BetDetailInfo from 'components/BetDetailInfo'
import LayoutInfo from 'components/Layout/LayoutInfo'
type PollDetailsProps = {
  isHistory?: boolean
}
const PollDetails = ({ isHistory }: PollDetailsProps) => {
  const navigation = useNavigate()
  const { betId } = useParams()
  const ticketId = betId ? parseInt(betId) : 0
  const {
    pollDetails,
    loading,
    renderElementTable,
    ticketName,
    canCancel,
    onCancel,
    onClickCheckResult
  } = usePollDetail(ticketId, isHistory)
  const onBackAction = () => navigation(-1)

  return (
    <LayoutInfo loading={loading} onBackAction={onBackAction}>
      <BetDetailCard>
        <BetDetailInfo
          ticketName={ticketName}
          ticket={pollDetails}
          canCancel={canCancel}
          onCancel={onCancel}
          onClickCheckResult={onClickCheckResult}
        />
      </BetDetailCard>
      {renderElementTable()}
    </LayoutInfo>
  )
}
export default PollDetails
