import { all } from 'redux-saga/effects'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { watchFetchTodos } from './fetachTodoSaga'
import { watchFetchListGame } from './fetchGameListSaga'
import { watchUser } from './fetchUserSage'
import { initialize } from './initializeSaga'
import { routerHandlerSaga } from './routerHandlerSaga'
import { FixTypeLater } from 'react-redux'
import { watchYeekeeGame } from './yeekeeSaga'
import { watchGlobalError } from './errorHandlerSaga'
import { watchFetchLimit } from './limitHandlerSaga'

export const callAPI = async ({
  url,
  method,
  data,
  headers = {
    // Authorization: `Bearer ${localStorage.getItem("")}`
  }
}: AxiosRequestConfig): Promise<AxiosResponse> => {
  headers = {
    ...headers,
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    AccessToken: localStorage.getItem('access_token')
  }

  return await axios({
    url,
    method,
    data,
    headers
  })
}

export const callPublicAPI = async ({
  url,
  method,
  data // headers
}: AxiosRequestConfig): Promise<FixTypeLater> => {
  return await axios({
    url,
    method,
    data
    // headers
  })
}

export interface ResponseStructure {
  status: string
  message: string
}

export interface ResponsePaginationStructure {
  limit: number
  page: number
  count: number
}

export interface PaginationPayloadPaginationStructure {
  page: number
  pageSize: number
}
function* rootSaga() {
  yield all([
    watchGlobalError(),
    watchFetchTodos(),
    watchFetchListGame(),
    watchYeekeeGame(),
    watchFetchLimit(),
    watchUser(),
    initialize(),
    routerHandlerSaga()
  ])
}
export default rootSaga
