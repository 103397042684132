import {
  createContext,
  ReactNode,
  FC,
  useContext,
  useState,
  useEffect,
  useCallback
} from 'react'
import { useTranslation } from 'react-i18next'
import { Game, ListLimitPriceNumber } from 'redux/reducers/gameReducer'
import { useDispatch } from 'react-redux'
import { BetGameItem, PayloadBetGame } from 'redux/saga/fetchGameListSaga'
import { sagaActions } from 'redux/sagaActions'
import { RootState } from 'redux/reducers'
import { useSelector } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import _, { cloneDeep } from 'lodash'
import {
  getRoundLottoConfig,
  responseLottoConfig
} from 'utils/lotto-type-utils'
import { LimitToastrPopup } from 'components/LimitToastrPopup'
import {
  getLimitTypeWithRateUsingTemplate,
  isInRange
} from 'contexts/limitHelper'
type LottoContextProviderProps = {
  children: ReactNode
}
export type BetLottoType =
  | 'bet_three_top'
  | 'bet_three_tod'
  | 'bet_two_top'
  | 'bet_two_tod'
  | 'bet_two_under'
  | 'bet_run_top'
  | 'bet_run_bottom'
  | 'bet_three_front'
  | 'bet_three_front_tod'
  | 'bet_three_under'
  | 'bet_four'
  | 'bet_six'

type BetTypesWithRates = {
  [type in BetLottoType]?: number
} & {
  [key: string]: number | undefined
}

export type AddLottoListResult = {
  rangeHit: number
  typeRangeHit: BetLottoType[]
  numberRangeHit: string[]
  limitRangeHit: number[]
}

export type LottoItem = {
  number: string
  price: number
  winPrice: number
  rate: number
}
export type LottoTypeGroup = {
  name: string
  type: BetLottoType
  rate?: number
  items: LottoItem[]
}

interface LottoContextType {
  creditLeft: number
  game?: Game
  lottoGroupItem: LottoTypeGroup[]
  addLottoList: (number: string, type: BetLottoType, winRate: number) => void
  addLottoListV2: (
    number: string[],
    type: BetLottoType,
    winRate: number
  ) => void
  addLottoListByType: (
    number: string,
    typesWithRates: BetTypesWithRates
  ) => Promise<AddLottoListResult>
  addLottoListAndHandleResponse(number: string, params: BetTypesWithRates): void
  betPrice: number | undefined
  betPriceChange: (price: number) => void
  onManualBetPriceChange: (price: number) => void
  deleteLottoList: (idx: number, type: string) => void
  deleteLottoListByNumber: (number: string, type: string) => void
  onChangeSingleBetPrice: (type: string, idx: number, newNumber: number) => void
  onClearBetList: () => void
  onHandleSubmit: (roundId: number) => void
  totalBetPrice: number
  getLimitType: (number: string) => {
    limit: string
    listLimitNumber: ListLimitPriceNumber[]
  }
  getLimitTypeUI: (number: string, betType: string[]) => { limit: string }
  lottoConfig: responseLottoConfig
}
const INIT_LOTTO_CONFIG = {
  betFour: {},
  betOne: {},
  betSix: {},
  betThree: {},
  betTwo: {}
}
const LottoContext = createContext<LottoContextType>({
  creditLeft: 0,
  game: undefined,
  // round: undefined,
  totalBetPrice: 0,
  lottoGroupItem: [],
  lottoConfig: INIT_LOTTO_CONFIG,
  addLottoList: () => {
    return
  },
  addLottoListV2: () => {
    return
  },
  addLottoListAndHandleResponse: async () => {
    return
  },
  addLottoListByType: async (): Promise<AddLottoListResult> => {
    return {
      rangeHit: 0,
      typeRangeHit: [],
      numberRangeHit: [],
      limitRangeHit: []
    }
  },
  betPrice: 1,
  betPriceChange: () => {
    return
  },
  onChangeSingleBetPrice: () => {
    return
  },
  deleteLottoList: () => {
    return
  },
  deleteLottoListByNumber: () => {
    return
  },
  onManualBetPriceChange: () => {
    return
  },
  onClearBetList: () => {
    return
  },
  onHandleSubmit: () => {
    return
  },
  getLimitType: () => {
    return { limit: 'none', listLimitNumber: [] }
  },
  getLimitTypeUI: () => ({ limit: 'none' })
})
export const useLottoContext = () => useContext(LottoContext)
export const LottoContextProvider: FC<LottoContextProviderProps> = ({
  children
}) => {
  const { t } = useTranslation()
  const navigation = useNavigate()
  const {
    game,
    limitNumbers,
    loadingFetchLimit,
    credit,
    round,
    response,
    limitTemplate,
    error
  } = useSelector((state: RootState) => ({
    game: state.games.game,
    round: state.games.round,
    limitNumbers: state.games.listLimitPriceNumber,
    credit: state.user.userBalance,
    loadingFetchLimit: state.games.loading.listLimitPriceNumber,
    response: state.games.response,
    error: state.error.globalError,
    limitTemplate: state.limitTemplate.limitRound
  }))
  const { roundId } = useParams()
  const dispatch = useDispatch()
  const [betPrice, setBetPrice] = useState<number | undefined>(undefined)
  const [lottoConfig, setConfig] =
    useState<responseLottoConfig>(INIT_LOTTO_CONFIG)
  const [selectType, setSelect] = useState<never[] | BetLottoType[]>([])
  const [lottoGroupItem, setListGroup] = useState<LottoTypeGroup[] | never[]>(
    []
  )
  const totalBetPrice = [...lottoGroupItem]?.reduce(
    (acc, curr) =>
      acc + curr.items.reduce((acc2, curr2) => acc2 + curr2.price, 0),
    0
  )

  useEffect(() => {
    if (response && response.status === 'OK') {
      toast.success(t('message.successBet'))
      navigation(`/lotto/poll/${response.data.id}`)
      dispatch({ type: sagaActions.CLEAR_RESPONSE })
    }
    if (error?.message) {
      toast.error(error.message)
      dispatch({ type: sagaActions.CLEAR_GLOBAL_ERROR })
    }
  }, [error, response])

  const fetchRoundLimitPrice = (_roundId?: number) => {
    dispatch({
      type: sagaActions.FETCH_LIMIT_ROUND_NUMBERS,
      payload: { roundId: _roundId ?? roundId }
    })
  }

  useEffect(() => {
    if (round) {
      const config = getRoundLottoConfig(round)
      setConfig(config)
      setDefaultLottoGroup(config)
    }
  }, [round])
  const creditLeft = (credit ?? 0) - totalBetPrice
  useEffect(() => {
    if (betPrice) {
      betPriceChange(betPrice ?? 1)
    }
  }, [betPrice])

  const setDefaultLottoGroup = (config: responseLottoConfig) => {
    const defaultItem: LottoTypeGroup[] = []
    if (config) {
      for (const configKey in config) {
        if (Object.prototype.hasOwnProperty.call(config, configKey)) {
          const val = (config as any)[configKey] // Access the nested object directly

          // Iterate over the properties of val (e.g., 'bet_two_top', 'bet_two_under', etc.)
          for (const key in val) {
            if (Object.prototype.hasOwnProperty.call(val, key)) {
              const type = key as BetLottoType
              const rate = val[key]

              defaultItem.push({
                items: [],
                name: t(`betDetailPanel.betLottoType.${type}`),
                type,
                rate
              })
            }
          }
        }
      }
    }
    setListGroup(defaultItem)
  }
  const addLottoList = async (
    number: string,
    type: BetLottoType,
    winRate = 1,
    betPrice = 1
  ) => {
    const pLottoItem = [...lottoGroupItem]
    const groupIdx = pLottoItem.findIndex((i) => i.type === type)

    let pBetPrice: number
    switch (type) {
      case 'bet_run_top':
      case 'bet_run_bottom':
        pBetPrice = 30
        break
      case 'bet_four':
      case 'bet_six':
        pBetPrice = winRate
        break
      default:
        pBetPrice = betPrice
    }

    const pItem: LottoItem = {
      price: pBetPrice,
      number,
      winPrice: winRate * pBetPrice,
      rate: winRate
    }

    const { isInRange, item, rate } = handleCheckRange(
      number,
      pItem,
      pBetPrice,
      type
    )

    const group = pLottoItem[groupIdx]
    const curIdx = group?.items?.findIndex((v) => v.number == number)

    if (isInRange) {
      toastr.confirm(t('message.rangeMessage', { number, price: rate }), {
        onOk: () => {
          if (curIdx === -1) {
            group.items.push(item)
          }
        },
        onCancel: () => {
          group.items = group.items.filter((i) => i.number !== number)
        }
      })
    } else {
      if (curIdx === -1) {
        group.items.push(item)
      }
    }

    setListGroup(pLottoItem)
    toast.success(t('message.successBetNumber', { number }))
    // }
  }
  const addLottoListV2 = (
    numbers: string[],
    type: BetLottoType,
    winRate = 1,
    betPrice = 1
  ) => {
    const pLottoItem = [...lottoGroupItem]
    const groupIdx = pLottoItem.findIndex((i) => i.type === type)

    let pBetPrice: number
    switch (type) {
      case 'bet_run_top':
      case 'bet_run_bottom':
        pBetPrice = 30
        break
      case 'bet_four':
      case 'bet_six':
        pBetPrice = 400
        break
      default:
        pBetPrice = betPrice
    }

    numbers?.forEach((number) => {
      const { limit, listLimitNumber } = getLimitType(number)

      if (limit === 'limit') {
        const rule = listLimitNumber.find((i) => i.type === limit)
        if (rule && rule.system_current_bet >= rule.limit_price) {
          toastr.confirm(t('mesage.limitMessage', { number }), {})
          return
        }
      }
      if (['none', 'range'].includes(limit)) {
        const pItem: LottoItem = {
          price: pBetPrice,
          number,
          winPrice: winRate * pBetPrice,
          rate: winRate
        }

        const group = pLottoItem[groupIdx]
        const curIdx = group.items?.findIndex((v) => v.number == number)

        if (curIdx === -1) {
          group.items.push(pItem)
        }
      }
    })

    toast.success(
      t('message.successBetNumber', {
        number: t(`betDetailPanel.betLottoType.${type}`)
      })
    )

    setListGroup(pLottoItem)
  }
  const checkLimitNumbersV2 = (
    number: string,
    item: LottoItem,
    newCurrentBet = 1,
    type: BetLottoType
  ) => {
    const { limit, listLimitNumber } = getLimitType(number)
    const isInLimit = false
    if (limit !== 'limit') {
      return { isInLimit, item }
    }
    const limitRules = listLimitNumber.find(
      (i) => i.number === number && i.bet_type === type
    )
    if (!limitRules) {
      return { isInLimit, item }
    }
    const newSystemLimit = newCurrentBet + limitRules.system_current_bet
    if (newSystemLimit >= limitRules.limit_price) {
      item.rate = 0
      item.winPrice = 0
      return { isInLimit: true, item }
    }
    return { isInLimit, item }
  }

  const addLottoListByType = async (
    number: string,
    typesWithRates: BetTypesWithRates,
    betPrice = 1
  ): Promise<AddLottoListResult> => {
    if (creditLeft <= 0) {
      toastr.confirm(t('message.creditNotEnough'), {})
      return {
        rangeHit: 0,
        typeRangeHit: [],
        numberRangeHit: [],
        limitRangeHit: []
      }
    }

    const pLottoItem = [...lottoGroupItem]
    let rangeHit = 0
    const typeRangeHit: BetLottoType[] = []
    const numberRangeHit: Set<string> = new Set()
    const limitRangeHit: number[] = []

    for (const type in typesWithRates) {
      if (typesWithRates.hasOwnProperty(type)) {
        const winRate = typesWithRates[type]
        if (winRate !== undefined) {
          const groupIdx = pLottoItem.findIndex(
            (i) => i.type === (type as BetLottoType)
          )

          let pBetPrice: number
          switch (type) {
            case 'bet_run_top':
            case 'bet_run_bottom':
              pBetPrice = 30
              break
            case 'bet_four':
            case 'bet_six':
              pBetPrice = winRate
              break
            default:
              pBetPrice = betPrice
          }
          const originRate = findRateByType(type as BetLottoType)
          const { limit, appliedRate } = getLimitTypeWithRateUsingTemplate(
            number,
            type as BetLottoType,
            originRate,
            betPrice,
            limitTemplate // Ensure limitTemplate is correctly passed here
          )

          const pItem: LottoItem = {
            price: pBetPrice,
            number,
            winPrice: appliedRate * pBetPrice,
            rate: appliedRate
          }

          const group = pLottoItem[groupIdx]
          const curIdx = group?.items?.findIndex((v) => v.number == number)

          if (limit === 'limit') {
            rangeHit += 1
            limitRangeHit.push(0)
            typeRangeHit.push(type as BetLottoType)
            numberRangeHit.add(number)
          } else if (limit === 'range' || limit === 'none') {
            if (curIdx === -1 && pItem.winPrice > 0) {
              group.items.push(pItem)
              if (limit === 'range') {
                rangeHit += 1
                limitRangeHit.push(pItem.rate)
                typeRangeHit.push(type as BetLottoType)
              }
              numberRangeHit.add(number)
              toast.success(t('message.successBetNumber', { number }))
            }
          }
        }
      }
    }

    setListGroup(pLottoItem)
    return {
      rangeHit,
      typeRangeHit,
      numberRangeHit: Array.from(numberRangeHit),
      limitRangeHit
    }
  }

  const findRateByType = (type: BetLottoType) => {
    let rate = 0
    Object.keys(lottoConfig)?.forEach((key) => {
      const val = (lottoConfig as any)[key] // Access the nested object directly
      for (const k in val) {
        if (Object.prototype.hasOwnProperty.call(val, k)) {
          const _type = k as BetLottoType
          const _rate = val[k]
          if (_type === type) {
            rate = _rate
          }
        }
      }
    })
    return rate
  }
  const handleCheckRange = (
    _number: string,
    _pItem: LottoItem,
    newCurrentBet = 1,
    type: BetLottoType
  ) => {
    const { limit, listLimitNumber } = getLimitType(_number)
    const originRate = findRateByType(type)

    if (limit !== 'range') {
      return { isInRange: false, item: _pItem }
    }

    const limitRules = listLimitNumber.find(
      (i) => i.number === _number && i.bet_type === type
    )
    if (!limitRules) {
      return { isInRange: false, item: _pItem }
    }

    const newSystemLimit = newCurrentBet + limitRules.system_current_bet
    const applyRule = limitRules.rules.find((i) => {
      const isWithinRange =
        newSystemLimit >= i.min_rate && newSystemLimit <= i.max_rate
      const isBeyondMax = newSystemLimit > i.max_rate
      return isWithinRange || isBeyondMax
    })

    const newPayRate: number = applyRule?.pay_rate_percent ?? 100
    const payNewRate = originRate * (newPayRate / 100)
    const isInRange = newPayRate !== 100

    const itemRes: LottoItem = {
      ..._pItem,
      rate: payNewRate,
      winPrice: payNewRate * newCurrentBet
    }

    return {
      isInRange,
      rate: newPayRate,
      item: itemRes
    }
  }
  const getUpdatePriceBySingleNumber = (
    type: string,
    idx: number,
    newPrice: number
  ) => {
    let updatePrice = 0
    const group = lottoGroupItem?.find((v) => v.type === type)
    group?.items?.forEach((i, k) => {
      updatePrice += k === idx ? newPrice : i.price
    })
    return updatePrice
  }
  const onChangeSingleBetPrice = (
    type: string,
    idx: number,
    newPrice: number
  ) => {
    let rangeHit = 0
    const typeRangeHit: BetLottoType[] = []
    const numberRangeHit: Set<string> = new Set()
    const limitRangeHit: number[] = []
    const price = newPrice && !isNaN(newPrice) ? newPrice : 1
    const updatePrice = getUpdatePriceBySingleNumber(type, idx, newPrice)

    if (updatePrice > (credit ?? 0)) {
      toastr.confirm(t('message.creditNotEnough'), {})
      return
    }

    const newGroup = lottoGroupItem.map((v) => {
      if (v.type === type && v.items?.[idx]) {
        const { number, rate } = v.items[idx]

        // Use getLimitTypeWithRateUsingTemplate to check limits/ranges based on limitTemplate
        const { limit, appliedRate } = getLimitTypeWithRateUsingTemplate(
          number,
          type as BetLottoType,
          rate, // Current rate of the item
          price, // New price for the item
          limitTemplate // Use the current limit template
        )

        const updatedItem = {
          ...v.items[idx],
          price: price,
          winPrice: appliedRate * price,
          rate: appliedRate
        }

        if (limit === 'range' && v.items[idx].rate !== appliedRate) {
          rangeHit++
          typeRangeHit.push(type as BetLottoType)
          numberRangeHit.add(v.items[idx].number)
          limitRangeHit.push(appliedRate)
          v.items[idx] = updatedItem
        } else if (limit === 'limit') {
          rangeHit++
          typeRangeHit.push(type as BetLottoType)
          numberRangeHit.add(v.items[idx].number)
          limitRangeHit.push(0)

          v.items = v.items.filter((i) => i.number !== v.items[idx].number)
        } else {
          v.items[idx] = updatedItem // Update the item normally if no special conditions are met
        }
      }
      return v
    })

    setTimeout(() => {
      setListGroup(newGroup)
      toast.success(t('message.updatePriceSuccess'))
    }, 1000)

    if (rangeHit > 0) {
      // If either range or limit is hit, show notification
      const id = toast(
        <LimitToastrPopup
          resp={{
            rangeHit,
            typeRangeHit,
            numberRangeHit: Array.from(numberRangeHit),
            limitRangeHit
          }}
          onClick={() => toast.dismiss(id)}
        />,
        {
          duration: Infinity
        }
      )
      return false
    }
  }

  const deleteLottoList = (idx: number, type: string) => {
    const pLottoGroupItem = [...lottoGroupItem]
    const groupIdx = pLottoGroupItem.findIndex((v) => v.type === type)

    if (groupIdx !== -1) {
      const group = pLottoGroupItem[groupIdx]
      const number = group.items[idx].number

      toastr.confirm(t('message.confirmDeleteNumber', { number }), {
        onOk: () => {
          group.items = group.items.filter((_, i) => i !== idx)
          setListGroup(pLottoGroupItem)
        }
      })
    }
  }

  const deleteLottoListByNumber = (number: string) => {
    const pLottoGroupItem = lottoGroupItem
    toastr.confirm(t('message.confirmDeleteNumber', { number }), {
      onOk() {
        pLottoGroupItem.forEach((group) => {
          group.items = group.items.filter((i) => i.number !== number)
        })
        toast.success(t('message.deleteItemSuccess', { number }))
        setListGroup(pLottoGroupItem)
      }
    })
  }

  const setSelectType = (type: BetLottoType) => {
    const pSelectType = new Set([...selectType])
    if (pSelectType.has(type)) {
      pSelectType.delete(type)
    } else {
      pSelectType.add(type)
    }
    setSelect(Array.from(pSelectType))
  }

  const calCulateNewUpdatePrice = (newPrice: number) => {
    let totalUpdatedPrice = 0
    lottoGroupItem?.forEach((item: LottoTypeGroup) => {
      item?.items?.forEach(() => {
        totalUpdatedPrice += newPrice
      })
    })
    return isNaN(totalUpdatedPrice) ? 0 : totalUpdatedPrice
  }

  const isEnoughCredit = () => {
    return totalBetPrice <= (credit ?? 0)
  }

  const betPriceChange = async (newPrice: number) => {
    const updateNewPrice = calCulateNewUpdatePrice(newPrice)
    const updatedLottoGroup: LottoTypeGroup[] = []
    if (newPrice) {
      if (updateNewPrice <= (credit ?? 0)) {
        for (const item of lottoGroupItem) {
          const newItem: LottoTypeGroup = { ...item, items: [] }
          if (
            !['bet_run_top', 'bet_run_bottom'].includes(item.type) ||
            newPrice > 30
          ) {
            for (const v of item.items) {
              const updatedItem: LottoItem = {
                ...v,
                price: newPrice,
                winPrice: v.rate * newPrice
              }
              newItem.items.push(updatedItem)
            }
          } else {
            newItem.items = item.items
          }
          updatedLottoGroup.push(newItem)
        }
        preLimitCheck(updatedLottoGroup)
      } else {
        toastr.confirm(t('message.creditNotEnough'), {})
      }
    }
  }

  const preLimitCheck = (lottoGroup: LottoTypeGroup[]) => {
    let rangeHit = 0
    const typeRangeHit: BetLottoType[] = []
    const numberRangeHit: Set<string> = new Set()
    const limitRangeHit: number[] = []

    // Deep copy using lodash's cloneDeep or another method to ensure deep cloning
    const copy = cloneDeep(lottoGroup)

    copy.forEach((group) => {
      group.items.forEach((item, index) => {
        const originRate = findRateByType(group.type as BetLottoType)
        // Using getLimitTypeWithRateUsingTemplate to check for limit/range
        const { limit, appliedRate } = getLimitTypeWithRateUsingTemplate(
          item.number,
          group.type,
          originRate,
          item.price,
          limitTemplate // Pass the limit template here
        )
        const updatedItem = {
          ...item,
          rate: appliedRate,
          winPrice: appliedRate * item.price
        }

        if (limit === 'range') {
          rangeHit++
          typeRangeHit.push(group.type)
          numberRangeHit.add(updatedItem.number)
          limitRangeHit.push(updatedItem.rate)
          group.items[index] = updatedItem
        }

        if (limit === 'limit') {
          rangeHit++
          typeRangeHit.push(group.type)
          numberRangeHit.add(updatedItem.number)
          limitRangeHit.push(0) // When limit is hit, the pay rate is 0
          group.items.splice(index, 1)
        }
      })
    })
    setListGroup(copy)

    if (rangeHit > 0) {
      // If either range or limit is hit, show notification
      const id = toast(
        <LimitToastrPopup
          resp={{
            rangeHit,
            typeRangeHit,
            numberRangeHit: Array.from(numberRangeHit),
            limitRangeHit
          }}
          onClick={() => toast.dismiss(id)}
        />,
        {
          duration: Infinity
        }
      )
      return false
    }
  }

  const preLimitCheckDep = (lottoGroup: LottoTypeGroup[]) => {
    let rangeHit = 0
    const typeRangeHit: BetLottoType[] = []
    const numberRangeHit: Set<string> = new Set()
    const limitRangeHit: number[] = []

    // Deep copy using lodash's cloneDeep or another method to ensure deep cloning
    const copy = cloneDeep(lottoGroup)

    copy.forEach((group) => {
      group.items.forEach((item, index) => {
        const { isInLimit, item: updatedItemFromLimit } = checkLimitNumbersV2(
          item.number,
          item,
          item.price,
          group.type
        )
        const { isInRange, item: updatedItemFromRange } = handleCheckRange(
          item.number,
          item,
          item.price,
          group.type
        )

        if (isInRange) {
          rangeHit++
          typeRangeHit.push(group.type)
          numberRangeHit.add(updatedItemFromRange.number)
          limitRangeHit.push(updatedItemFromRange.rate)
          group.items[index] = updatedItemFromRange
        }

        if (isInLimit) {
          rangeHit++
          typeRangeHit.push(group.type)
          numberRangeHit.add(updatedItemFromLimit.number)
          limitRangeHit.push(updatedItemFromLimit.rate)
        }
      })
    })
    setListGroup(lottoGroup)
    if (rangeHit > 0) {
      const id = toast(
        <LimitToastrPopup
          resp={{
            rangeHit,
            typeRangeHit,
            numberRangeHit: Array.from(numberRangeHit),
            limitRangeHit
          }}
          onClick={() => toast.dismiss(id)}
        />,
        {
          duration: Infinity
        }
      )
      return false
    }
  }

  const debounceSuccess = useCallback(
    _.debounce(() => {
      toast.success(t('message.updatePriceSuccess'))
    }, 1000),
    []
  )
  const onManualBetPriceChange = (newPrice: number) => {
    setBetPrice(newPrice)
    debounceSuccess()
  }

  const onClearBetList = () => {
    setDefaultLottoGroup(lottoConfig)
    // setListGroup([...defaultItem])
    toast.success(t('message.clearItemsSuccess'))
  }
  const onHandleSubmit = async (roundId: number) => {
    const betList: BetGameItem[] = []
    fetchRoundLimitPrice(roundId)
    if (
      // (await preLimitCheck(lottoGroupItem)) &&

      !loadingFetchLimit &&
      isEnoughCredit()
    ) {
      lottoGroupItem
        .filter((i) => i.items.length > 0)
        .map(
          (i) =>
            i.items?.map((b) => {
              betList.push({
                type: i.type,
                number: b.number,
                price: b.price,
                rate: b.rate
              })
            })
        )
      if (betList.length === 0) {
        toastr.confirm(t('message.noBetItem'), {})
        return
      }
      if (betList.length > 99) {
        toastr.confirm(t('message.maxBetItem'), {})
        return
      }
      const payload: PayloadBetGame = {
        roundId: roundId,
        betList: betList
      }
      toastr.confirm(t('message.confirmBet'), {
        onOk: () => dispatch({ type: sagaActions.POST_BET_GAME, payload })
      })
    }
  }

  const getLimitTypeUI = (
    number: string,
    betTypes: string[] = []
  ): { limit: 'none' | 'limit' | 'range' } => {
    // Ensure limitTemplate is defined before accessing its properties
    if (!limitTemplate) {
      return { limit: 'none' }
    }

    const { current_price, base_limit_price, special_limit_price } =
      limitTemplate

    let specialRangeGap = false // To track gaps in special limits
    let baseRangeGap = false // To track gaps in base limits

    // First, check Special Limit Prices for each relevant bet type
    for (const betType of betTypes) {
      const currentBet =
        current_price.current_bet[betType]?.[number]?.current_bet || 0

      // If no current bet found, skip this betType
      if (currentBet === 0) {
        continue
      }

      // Check Special Limit Prices for the given bet type
      for (const specialLimit of special_limit_price) {
        if (
          specialLimit.bet_number === number &&
          specialLimit.bet_type === betType
        ) {
          // Prioritize direct limit hit in special limits
          if (
            specialLimit.type === 'limit' &&
            specialLimit.limit_price != null && // Handles both undefined and null
            currentBet >= specialLimit.limit_price
          ) {
            return { limit: 'limit' } // Immediate return for special limit hit
          }

          // Check for range in special limits
          if (specialLimit.type === 'range') {
            const min = specialLimit.bet_price_min ?? -Infinity
            const max = specialLimit.bet_price_max ?? Infinity

            if (isInRange(currentBet, min, max)) {
              return { limit: 'range' } // Immediate return if within special range
            } else {
              specialRangeGap = true // Mark a gap in special range
            }
          }
        }
      }
    }

    // Only check base prices if no special range or limit was confirmed
    if (!specialRangeGap) {
      for (const betType of betTypes) {
        const currentBet =
          current_price.current_bet[betType]?.[number]?.current_bet || 0

        // If no current bet found, skip this betType
        if (currentBet === 0) {
          continue
        }

        // Check Base Limit Prices for the given bet type for direct limit hit
        for (const baseLimit of base_limit_price) {
          if (
            baseLimit.type === 'limit' &&
            baseLimit.limit_price != null && // Handles both undefined and null
            currentBet >= baseLimit.limit_price
          ) {
            return { limit: 'limit' } // Immediate return for base limit hit
          }

          // Check for range in base limits
          if (baseLimit.type === 'range') {
            const min = baseLimit.bet_price_min ?? -Infinity
            const max = baseLimit.bet_price_max ?? Infinity

            if (isInRange(currentBet, min, max)) {
              return { limit: 'range' } // Immediate return if within base range
            } else {
              baseRangeGap = true // Mark a gap in base range
            }
          }
        }
      }
    }

    // If there are gaps in special ranges, consider it still a "range" if no base range applies
    if (specialRangeGap || baseRangeGap) {
      return { limit: 'none' } // Consider 'none' if gaps are present and no valid range/limit is found
    }

    // Default to 'none' if no limits or ranges apply
    return { limit: 'none' }
  }

  const getLimitType = (number: string) => {
    const limits = limitNumbers.filter((i) => i.number === number)
    const getLimitTypeUI = () => {
      const limits = limitNumbers.filter((i) => i.number === number)

      const type = 'none'

      // Check for a direct limit hit
      const directLimit = limits.find(
        (i) => i.type === 'limit' && i.system_current_bet >= i.limit_price
      )
      if (directLimit) {
        return 'limit'
      }

      // Check for a range hit
      const rangeHit = limits.find(
        (i) =>
          i.type === 'range' &&
          i.rules.some((rule) => {
            return (
              i.system_current_bet >= rule.min_rate &&
              i.system_current_bet <= rule.max_rate
            )
          })
      )

      if (rangeHit) {
        return 'range'
      }

      // If no direct limit or range hit, check if there's any limit with rules that imply a range
      const impliedRange = limits.find(
        (i) => i.type === 'limit' && i.rules.length > 0
      )
      if (impliedRange) {
        return 'range'
      }

      return type
    }

    return {
      limit: getLimitTypeUI(),
      listLimitNumber: limits
    }
  }
  const addLottoListAndHandleResponse = async (
    number: string,
    params: BetTypesWithRates
  ) => {
    if ((credit ?? 0) <= 0) {
      toastr.confirm(t('message.creditNotEnough'), {})
      return
    }
    const resp = await addLottoListByType(number, params)

    if (resp.rangeHit > 0) {
      const id = toast(
        <LimitToastrPopup resp={resp} onClick={() => toast.dismiss(id)} />,
        {
          duration: Infinity
        }
      )
    }
  }
  const contextValue = {
    creditLeft,
    addLottoList,
    addLottoListV2,
    addLottoListAndHandleResponse,
    addLottoListByType,
    betPrice,
    betPriceChange,
    deleteLottoList,
    deleteLottoListByNumber,
    game,
    lottoGroupItem,
    onChangeSingleBetPrice,
    onClearBetList,
    onHandleSubmit,
    onManualBetPriceChange,
    round,
    selectType,
    setSelectType,
    totalBetPrice,
    getLimitType,
    getLimitTypeUI,
    lottoConfig
  }

  return (
    <LottoContext.Provider value={contextValue}>
      {children}
    </LottoContext.Provider>
  )
}
