import LayoutInfo from 'components/Layout/LayoutInfo'
import { useResultPage } from './useResultPage'
import { useTranslation } from 'react-i18next'
import DatePickerInput from 'components/DatePickerInput'
import SelectDropDown from 'components/SelectDropDown'

const ResultPage = () => {
  const { t } = useTranslation()
  const {
    loading,
    onBackAction,
    selectedDate,
    onDateChange,
    isOpen,
    toggleDatePicker,
    renderer,
    optionsMenu,
    handleSelect,
    selectedGame
  } = useResultPage()
  return (
    <LayoutInfo loading={loading} onBackAction={onBackAction}>
      <h2 className="d-flex justify-content-center mb-3">
        {t('resultPage.header')}
      </h2>
      <DatePickerInput
        isOpen={isOpen}
        toggleDatePicker={toggleDatePicker}
        selectedDate={selectedDate}
        onDateChange={onDateChange}
      />
      <SelectDropDown
        options={optionsMenu}
        onChange={handleSelect}
        value={selectedGame}
      />
      {renderer}
    </LayoutInfo>
  )
}

export default ResultPage
