import { ResultItemProps } from 'components/ResultCard'
import { Column } from 'components/TablePagination'
import { TFunction, i18n } from 'i18next'
import { Svg } from 'images'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { NavigateFunction } from 'react-router-dom'
import { RootState } from 'redux/reducers'
import { Game, Round } from 'redux/reducers/gameReducer'

import {
  DATE_TIME_FORMAT_HUMAN,
  convertToDateTime
} from 'utils/date-time-utils'
import { isEncryptLotto, isGroupLotto } from 'utils/lotto-type-utils'

export const GetResultProps = (
  i18n: i18n,
  t: TFunction,
  round?: Round,
  game?: Game,
  navigation?: NavigateFunction,
  isShowHeader?: boolean
) => {
  const name = i18n.language === 'th' ? game?.name_th : game?.name_en
  const columns: Column[] = [
    {
      name: '',
      accessor: 'id',
      tdStyle: { textAlign: 'left' },
      Cell: (row: Round) => {
        return (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() =>
              navigation &&
              navigation(`/lotto/${game?.code}/${game?.id}/${row?.id}`)
            }
          >
            {t('lottoPage.lottoRound.round_name', {
              name,
              round: row.round_number
            })}
          </div>
        )
      }
    },
    {
      name: t('betResult.three_result'),
      accessor: 'three_front_result',
      thClass: 'lotto-result-table-title',
      thStyle: { textAlign: 'right' },
      tdStyle: { textAlign: 'right' },
      Cell: (row: Round) => {
        const result = row?.is_result_confirmed
          ? row.top_three_result
          : t('betResult.waiting')
        return <strong className="lotto-result-status">{result}</strong>
      }
    },
    {
      name: t('betResult.two_result'),
      accessor: 'two_result',
      thClass: 'lotto-result-table-title',
      tdStyle: { textAlign: 'right' },
      thStyle: { textAlign: 'right' },
      Cell: (row: Round) => {
        const result = row?.is_result_confirmed
          ? row.bottom_two_result
          : t('betResult.waiting')
        return <strong className="lotto-result-status">{result}</strong>
      }
    }
  ]

  const getLottoResultInfo = () => {
    let icon = Svg.iconThFlag
    switch (game?.flag) {
      case 'yeekee':
      case 'yeekeeVIP':
      case 'lao':
      case 'laostar':
      case 'lao_star':
        icon = Svg.iconLaoFlag
        break
      case 'vietnam':
      case 'hanoi':
        icon = Svg.iconVietnamFlag
        break
      case 'korea':
        icon = Svg.iconKoreaFlag
        break
      case 'nikei':
      case 'nikei_afternoon':
      case 'nikei_afternon':
        icon = Svg.iconJapanFlag
        break
      case 'hungseng_afternoon':
      case 'hungseng_morning':
      case 'china_afternoon':
      case 'china_morning':
        icon = Svg.iconChinaFlag
        break
      case 'taiwan':
        icon = Svg.iconTaiwanFlag
        break
      case 'singapore':
        icon = Svg.iconSGFlag
        break
      case 'egypt':
        icon = Svg.iconEgyptFlag
        break
      case 'german':
        icon = Svg.iconGermanFlag
        break
      case 'england':
        icon = Svg.iconEnglandFlag
        break
      case 'russia':
        icon = Svg.iconRussiaFlag
        break
      case 'india':
        icon = Svg.iconEgyptFlag
        break
      case 'downjones':
        icon = Svg.iconUSAFlag
        break
      case 'malaysia':
        icon = Svg.iconMYFlag
        break
      case 'thai_morning_stock':
      case 'thai_afternoon_stock':
      case 'thai_pm_stock':
      case 'thai_evening_stock':
      case 'government':
        icon = Svg.iconThFlag
        break
    }
    return icon
  }
  const getLottoTypeGroup = () => {
    const itemResult: ResultItemProps[] = []
    if (isEncryptLotto(game?.type ?? '')) {
      itemResult.push(
        {
          title: t('betResult.encrypt_result') ?? '',
          result: round?.encrypt_result ?? t('message.no_round') ?? ''
        },
        {
          title: t('betResult.signature') ?? '',
          result: round?.signature ?? t('message.no_round') ?? ''
        }
      )
    }
    switch (game?.type) {
      case 'default':
        let stringResultThreeFront = undefined
        let stringResultThreeBack = undefined
        if (
          round?.bottom_three_front_1_result &&
          round.bottom_three_front_2_result
        ) {
          stringResultThreeFront = `${round?.bottom_three_front_1_result} ${round.bottom_three_front_2_result}`
        }
        if (round?.bottom_three_1_result && round.bottom_three_2_result) {
          stringResultThreeBack = `${round?.bottom_three_1_result} ${round.bottom_three_2_result}`
        }

        itemResult.push(
          {
            title: t('betResult.six_result') ?? '',
            result: round?.six_result ?? t('message.no_round') ?? '',
            wrapper: round?.six_result && 'col-md-12'
          },
          {
            title: t('betResult.three_result') ?? '',
            wrapper:
              !round?.six_result && round?.top_three_result
                ? 'col-md-12'
                : 'col-md-6',
            result: round?.top_three_result ?? t('message.no_round') ?? ''
          },
          {
            title: t('betResult.three_front_result') ?? '',
            result: stringResultThreeFront ?? t('message.no_round') ?? ''
          },
          {
            title: t('betResult.three_bottom_result') ?? '',
            result: stringResultThreeBack ?? t('message.no_round') ?? ''
          },
          {
            title: t('betResult.two_result') ?? '',
            result: round?.bottom_two_result ?? t('message.no_round') ?? ''
          },
          {
            title: t('betResult.two_top_result') ?? '',
            result: round?.result_top_two ?? t('message.no_round') ?? ''
          }
        )
        break
      case 'stock':
      case 'yeekee':
      case 'yeekee_vip':
      case 'lao':
      case 'lao_star':
      case 'vietnam':
      case 'hanoi':
      case 'korea':
      case 'nikei_morning':
      case 'nikei_afternon':
      case 'hungseng_afternoon':
      case 'hungseng_morning':
      case 'china_afternoon':
      case 'china_morning':
      case 'taiwan':
      case 'singapore':
      case 'egyp':
      case 'german':
      case 'england':
      case 'russia':
      case 'india':
      case 'downjones':
      case 'malaysia':
      case 'thai_morning_stock':
      case 'thai_afternoon_stock':
      case 'thai_pm_stock':
      case 'thai_evening_stock':
      default:
        itemResult.push(
          {
            title: t('betResult.three_result') ?? '',
            result: round?.top_three_result
          },
          {
            title: t('betResult.two_result') ?? '',
            result: round?.bottom_two_result
          }
        )
        break
    }

    if (!round?.is_result_confirmed) {
      return itemResult.map((i) => ({
        ...i,
        result: t('betResult.waiting')
      }))
    }
    return itemResult
  }
  const title: string =
    localStorage.getItem('lang') === 'th'
      ? game?.name_th ?? '-'
      : game?.name_en ?? '-'
  const details = convertToDateTime(
    round?.close_at ?? '',
    DATE_TIME_FORMAT_HUMAN,
    localStorage.getItem('lang') ?? i18n.language
  )
  let header = t('betResult.four_result')
  let headerResult = round ? round.four_result : t('message.no_round') ?? ''
  if (game?.type === 'group_six') {
    header = t('betResult.six_group_result')
    headerResult = round ? round.six_result : t('message.no_round') ?? ''
  }
  if (isEncryptLotto(game?.type ?? '')) {
    header = t('betResult.result')
    headerResult = round ? round.six_result : t('message.no_round') ?? ''
  }

  let renderHeader = isShowHeader && isEncryptLotto(game?.type ?? '')
  if (isGroupLotto(game?.type ?? '')) {
    renderHeader = true
  }
  const onClickHeader = () => {
    if (round) {
      navigation && navigation(`/lotto/${game?.code}/${game?.id}/${round?.id}`)
    } else {
      toast.error(t('message.no_round'))
    }
  }
  return {
    gameType: game?.type,
    title,
    details: round ? details : t('message.no_round'),
    isResulted: round?.is_result_confirmed,
    icon: getLottoResultInfo(),
    items: isGroupLotto(game?.type ?? '') ? [] : getLottoTypeGroup(),
    closeAt: round
      ? t('betResult.closed_at', {
          date: convertToDateTime(
            round?.close_at ?? '',
            DATE_TIME_FORMAT_HUMAN,
            i18n.language
          )
        })
      : t('message.no_round'),
    columns,
    noRound: round ? false : true,
    header: renderHeader ? header : undefined,
    headerResult: renderHeader ? headerResult : undefined,
    onClickHeader
  }
}

const useResulted = (isShowHeader?: boolean) => {
  const { i18n, t } = useTranslation()
  const { round, game } = useSelector((state: RootState) => {
    return { round: state.games.round, game: state.games.game }
  })

  return GetResultProps(i18n, t, round, game, undefined, isShowHeader)
}

export default useResulted
