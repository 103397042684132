import { PollItem } from 'components'

import EmptyContent from 'components/EmptyContent'
import PollLoading from './poll-loading'
import { PaginationAction } from 'components/TablePagination'
import { POLL_CANCELED, usePoll } from './hook/usePoll'

export const PollCanceled = () => {
  const { loading, tickets, page, count, onPageChange, limit } =
    usePoll(POLL_CANCELED)
  return (
    <div className="row">
      {loading && <PollLoading />}
      {!loading && (!tickets || tickets.length === 0) && <EmptyContent />}
      {!loading &&
        tickets?.map((i) => (
          <div
            className="col-12"
            style={{ marginBottom: '10px' }}
            key={JSON.stringify(i)}
          >
            <PollItem {...i} />
          </div>
        ))}
      <PaginationAction
        page={page}
        limit={limit}
        onPageChange={onPageChange}
        count={count}
      />
    </div>
  )
}
