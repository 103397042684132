import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const Status = styled.span`
  padding: 2px 16px;
  font-weight: 500;
`

export interface PollStatusProps {
  status: 'waiting' | 'settled' | string
}
const PollStatus = ({ status = 'waiting' }: PollStatusProps) => {
  const { t } = useTranslation()
  const getCssButtonByType = () => {
    switch (status) {
      case 'waiting':
        return {
          classType: 'btn-black text-gold',
          text: t('pollStatus.waiting')
        }
      case 'settled':
        return {
          classType: 'btn-gold text-white',
          text: t('pollStatus.resulted')
        }
      case 'cancelled':
        return {
          classType: 'btn btn-danger text-white',
          text: t('pollStatus.cancelled')
        }
      case 'approved': {
        return {
          classType: 'btn btn-info text-white',
          text: t('pollStatus.approved')
        }
      }
      default:
        return {
          classType: 'btn btn-danger text-white',
          text: t('pollStatus.rejected')
        }
    }
  }
  const { classType, text } = getCssButtonByType()

  return <Status className={`${classType} radius-99`}>{text}</Status>
}

export default PollStatus
