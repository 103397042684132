import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { NumberFormat } from 'utils/number-utils'
const TitleCard = styled.h3`
  font-size: 18px;
  background-color: rgb(241, 182, 37);
  border-radius: 99px;
  padding: 4px 32px;
`
const ResultTag = styled.p`
  font-weight: bold;
  font-size: 1.5em;
  color: rgb(241, 182, 37);
`
interface PollSummaryProps {
  title: string
  totalResultBet: number
  totalWaitBet: number
  totalBet: number
  pollCount: number
}
const PollSummary = ({
  title,
  totalResultBet,
  totalWaitBet,
  totalBet,
  pollCount
}: PollSummaryProps) => {
  const { t } = useTranslation()
  return (
    <div className="position-relative mb-4 mt-4 container-w-border-shadow">
      <TitleCard className="position-absolute top-0 start-50 translate-middle text-center">
        {title}
      </TitleCard>
      <div className="container pt-4">
        <div className="row">
          <div className="col-6 col-lg-3 text-center">
            <h4>{t('pollSummary.pollCount')}</h4>
            <ResultTag>{NumberFormat(pollCount)}</ResultTag>
          </div>
          <div className="col-6 col-lg-3 text-center">
            <h4>{t('pollSummary.totalResultBet')}</h4>
            <ResultTag>{NumberFormat(totalResultBet)}</ResultTag>
          </div>
          <div className="col-6 col-lg-3 text-center">
            <h4>{t('pollSummary.totalWaitBet')}</h4>
            <ResultTag>{NumberFormat(totalWaitBet)}</ResultTag>
          </div>
          <div className="col-6 col-lg-3 text-center">
            <h4>{t('pollSummary.totalBet')}</h4>
            <ResultTag>{NumberFormat(totalBet)}</ResultTag>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PollSummary
