import { useSelector } from 'react-redux'
import { useShootNumber } from './useShootNumber'
import { RootState } from 'redux/reducers'
import { ResultCardProps, ResultItemProps } from 'components/ResultCard'
import { useTranslation } from 'react-i18next'
import {
  DATE_TIME_FORMAT_HUMAN,
  convertToDateTime
} from 'utils/date-time-utils'
import i18n from 'i18n'
import { useNavigate } from 'react-router-dom'

const useYeekeeResult = () => {
  const { t } = useTranslation()
  const navigation = useNavigate()
  const onHandleBack = () => {
    navigation(-1)
  }
  const { round, loading, game } = useSelector((state: RootState) => ({
    round: state.games.round,
    loading: state.games.loading.round,
    game: state.games.game
  }))
  const pageTitle = t('lottoPage.header.title_yeekee', {
    name: i18n.language === 'th' ? game?.name_th : game?.name_en,
    round: round?.round_number
  })
  const yeekeeConfig = round?.yeekee_config
  let yeekeeResultNumber = 0
  const resultBonus = round?.yeekee_round_bonus_result
  const resultTopThree = round?.top_three_result
  const resultBottomTwo = round?.bottom_two_result
  const yeekeeMemberResult: ResultItemProps[] = []
  const yeekeeResult: ResultItemProps[] = [
    {
      title: t('betResult.three_result') ?? '',
      result: resultTopThree
    },
    {
      title: t('betResult.two_result') ?? '',
      result: resultBottomTwo
    },
    {
      title: t('betResult.summary_put_number') ?? '',
      result: resultBonus?.summary_result
    },
    {
      title: t('betResult.put_number_row_16') ?? '',
      result: resultBonus?.result_row_16
    }
  ]
  if (resultBonus) {
    if (resultBonus.player_username_bonus_1 && yeekeeConfig?.bonus_row_1) {
      yeekeeMemberResult.push({
        title:
          t('betResult.member_row_number', {
            row: yeekeeConfig?.bonus_row_1
          }) ?? '',
        result: resultBonus.player_username_bonus_1
      })
    }
    if (resultBonus.player_username_bonus_2 && yeekeeConfig?.bonus_row_2) {
      yeekeeMemberResult.push({
        title:
          t('betResult.member_row_number', {
            row: yeekeeConfig?.bonus_row_2
          }) ?? '',
        result: resultBonus.player_username_bonus_2
      })
    }
    if (resultBonus.player_username_bonus_3 && yeekeeConfig?.bonus_row_3) {
      yeekeeMemberResult.push({
        title:
          t('betResult.member_row_number', {
            row: yeekeeConfig?.bonus_row_3
          }) ?? '',
        result: resultBonus.player_username_bonus_3
      })
    }
    if (resultBonus.player_username_bonus_4 && yeekeeConfig?.bonus_row_4) {
      yeekeeMemberResult.push({
        title:
          t('betResult.member_row_number', {
            row: yeekeeConfig?.bonus_row_4
          }) ?? '',
        result: resultBonus.player_username_bonus_4
      })
    }
    if (resultBonus.player_username_bonus_5 && yeekeeConfig?.bonus_row_5) {
      yeekeeMemberResult.push({
        title:
          t('betResult.member_row_number', {
            row: yeekeeConfig?.bonus_row_5
          }) ?? '',
        result: resultBonus.player_username_bonus_5
      })
    }
  }
  yeekeeResultNumber =
    parseInt(resultBonus?.summary_result ?? '0') -
    parseInt(resultBonus?.result_row_16 ?? '0')
  const resultCard: ResultCardProps = {
    header: t('betResult.result_yeekee_number') ?? '',
    headerResult: yeekeeResultNumber.toString(),
    results: yeekeeResult
  }
  const bonusCard: ResultCardProps = {
    header: t('betResult.bonus_reward') ?? '',
    results: yeekeeMemberResult
  }
  const {
    data,
    page,
    limit,
    columns,
    loadingFetchNumbers,
    onLimitChange,
    onPageChange
  } = useShootNumber(false)

  return {
    pageTitle,
    data,
    page,
    limit,
    columns,
    loadingFetchNumbers,
    onLimitChange,
    onPageChange,
    loading,
    bonusCard,
    resultCard,
    closeAt: t('betResult.closed_at', {
      date: convertToDateTime(
        round?.close_at ?? '',
        DATE_TIME_FORMAT_HUMAN,
        i18n.language
      )
    }),
    roundNumber: round?.round_number,
    onHandleBack
  }
}
export default useYeekeeResult
