import { Svg } from 'images'

export type LottoFilterProps = {
  menus: { key: number; value: string | number }[]
  selected: string[]
  onSelect: (menu: string) => void
  onClear?: () => void
}
const LottoFilter = ({
  menus,
  selected,
  onSelect,
  onClear
}: LottoFilterProps) => {
  return (
    <div className="lotto-list-section-container container">
      {menus?.map((i) => (
        <button
          key={i.key}
          onClick={() => onSelect(i.key.toString())}
          style={{ marginRight: '5px' }}
          className={`btn btn-grey radius-8  space-btn-inner ${
            selected.includes(i.key.toString()) ? 'active' : ''
          }`}
        >
          {i.value}
        </button>
      ))}
      {selected.length > 0 && (
        <a onClick={() => onClear?.()}>
          <img src={Svg.iconClose} />
        </a>
      )}
    </div>
  )
}
export default LottoFilter
