import { useState } from 'react'
import { DatePickerInputProps } from '.'
import { Value } from 'react-date-picker/dist/cjs/shared/types'
export const useDatePickerInput: (initDate?: string) => DatePickerInputProps = (
  _initDate
) => {
  const initDate = _initDate ? new Date(_initDate) : new Date()
  const [selectedDate, setNewDate] = useState(initDate)
  const [isOpen, setIsOpen] = useState(false)
  const onDateChange = (date: Value) => {
    setNewDate(date as Date)
  }
  const toggleDatePicker = () => {
    setIsOpen(!isOpen)
  }
  return { selectedDate, onDateChange, isOpen, toggleDatePicker }
}
