import { LottoType } from 'components/LottoItem'
import { FixTypeLater } from 'react-redux'
import { Round } from 'redux/reducers/gameReducer'

export const convertLottoType = (type: string): LottoType => {
  switch (type) {
    case 'yeekeeVIP':
      return 'lotto-list-yeekeevip'
    case 'thaistock_morning':
      return 'lotto-list-thaistock-morning'
    case 'thaistock_afternoon':
      return 'lotto-list-thaistock-afternoon'
    case 'thaistock_pm':
      return 'lotto-list-thaistock-pm'
    case 'thaistock_evening':
      return 'lotto-list-thaistock-afternoon'
    case 'thai_evening_stock':
      return 'lotto-list-thaistock-evening'
    case 'crypto':
      return 'lotto-list-crypto'
    case 'vietnam':
      return 'lotto-list-vietnam'
    case 'laostar':
      return 'lotto-list-stock-lao-star'
    case 'hanoi':
      return 'lotto-list-hanoi'
    case 'baac':
      return 'lotto-list-baac'
    case 'korea':
      return 'lotto-list-stock-korea'
    case 'nikei':
      return 'lotto-list-stock-nikei'
    case 'nikei_afternoon':
      return 'lotto-list-stock-nikei-afternoon'
    case 'hungseng_afternoon':
      return 'lotto-list-stock-hungseng-afternoon'
    case 'hungseng_morning':
      return 'lotto-list-stock-hungseng-afternoon'
    case 'china_afternoon':
      return 'lotto-list-stock-china-afternoon'
    case 'china_morning':
      return 'lotto-list-stock-china-morning'
    case 'taiwan':
      return 'lotto-list-stock-taiwan'
    case 'singapore':
      return 'lotto-list-stock-singapore'
    case 'egypt':
      return 'lotto-list-stock-egypt'
    case 'german':
      return 'lotto-list-stock-german'
    case 'england':
      return 'lotto-list-stock-england'
    case 'russia':
      return 'lotto-list-stock-russia'
    case 'lao':
      return 'lotto-list-stock-lao'
    case 'india':
      return 'lotto-list-stock-india'
    case 'downjones':
      return 'lotto-list-stock-downjones'
    case 'malaysia':
      return 'lotto-list-stock-malaysia'
    case 'lao_star':
      return 'lotto-list-stock-lao-star'
    case 'corporate':
      return 'lotto-list-corporate'
    case 'government':
      return 'lotto-list-goverment'
    default:
      return 'lotto-list-yeekee'
  }
}
type LottoConfig = {
  type: string
  reward: number
}
export type LottoConfigObject = { [type: string]: number }
export type pResponsseConfig = {
  betTwo: LottoConfig[]
  betThree: LottoConfig[]
  betOne: LottoConfig[]
  betSix: LottoConfig[]
  betFour: LottoConfig[]
}
export type responseLottoConfig = {
  betTwo: LottoConfigObject
  betThree: LottoConfigObject
  betOne: LottoConfigObject
  betSix: LottoConfigObject
  betFour: LottoConfigObject
}

const TYPE_REWARD_FIELD: { [key: string]: string[] } = {
  bet_two_top: ['reward_top_two'],
  bet_two_under: ['reward_bottom_two'],
  // bet_two_tod: ['reward_bottom_two_flip', 'reward_top_two_flip'],
  bet_two_tod: ['reward_top_two_flip'],
  bet_three_top: ['reward_top_three'],
  bet_three_under: ['reward_bottom_three'],
  bet_three_tod: [
    // 'reward_bottom_three_flip',
    // 'reward_top_three_front_flip',
    // 'reward_bottom_three_front_flip',
    'reward_top_three_flip'
  ],
  // bet_three_tod: [
  //   'reward_bottom_three_flip',
  //   'reward_top_three_front_flip',
  //   'reward_bottom_three_front_flip',
  //   'reward_top_three_flip'
  // ],
  // bet_three_front: ['reward_top_three_front', 'reward_bottom_three_front'],
  bet_three_front: ['reward_bottom_three_front'],
  bet_run_top: ['reward_run_top'],
  bet_run_bottom: ['reward_run_bottom'],
  bet_four: ['reward_four'],
  bet_six: ['reward_six']
}

export const getLottoConfig = (round?: Round) => {
  const typeToRewardField: { [key: string]: string[] } = {
    ...TYPE_REWARD_FIELD
  }
  const response = []
  if (round) {
    for (const key in typeToRewardField) {
      if (typeToRewardField.hasOwnProperty(key)) {
        const rewardFields = typeToRewardField[key]
        let rewardValue = 0
        // Use a type assertion here
        if (
          rewardFields.every((field) => (round as any)[field] !== undefined)
        ) {
          // Calculate rewardValue based on each property
          rewardValue = rewardFields.reduce((acc, field) => {
            if (
              acc !== undefined &&
              (round as any)[field] !== undefined &&
              !acc
            ) {
              return (round as any)[field] + acc
            }
            return acc // Set to undefined if any property is undefined
          }, 0) as number
        }
        response.push({ type: key, reward: rewardValue || 0 })
      }
    }
  }
  return response
}
export const getRoundLottoConfig: (round?: Round) => responseLottoConfig = (
  round
) => {
  const response: pResponsseConfig = {
    betTwo: [],
    betThree: [],
    betOne: [],
    betSix: [],
    betFour: []
  }
  const responseV2: responseLottoConfig = {
    betTwo: {},
    betThree: {},
    betOne: {},
    betSix: {},
    betFour: {}
  }
  const typeToRewardField: { [key: string]: string[] } = {
    ...TYPE_REWARD_FIELD
  }
  if (round) {
    for (const key in typeToRewardField) {
      if (typeToRewardField.hasOwnProperty(key)) {
        const rewardFields = typeToRewardField[key]
        let rewardValue = 0
        // Use a type assertion here
        if (
          rewardFields.every((field) => (round as any)[field] !== undefined)
        ) {
          // Calculate rewardValue based on each property
          rewardValue = rewardFields.reduce((acc, field) => {
            if (
              acc !== undefined &&
              (round as any)[field] !== undefined &&
              !acc
            ) {
              return (round as any)[field] + acc
            }
            return acc // Set to undefined if any property is undefined
          }, 0) as number
        }
        switch (key) {
          case 'bet_two_top':
          case 'bet_two_under':
          case 'bet_two_tod':
            rewardValue &&
              response.betTwo.push({ type: key, reward: rewardValue || 0 })
            break
          case 'bet_three_top':
          case 'bet_three_under':
          case 'bet_three_tod':
          case 'bet_three_front':
            rewardValue &&
              response.betThree.push({ type: key, reward: rewardValue || 0 })
            break
          case 'bet_run_top':
          case 'bet_run_bottom':
            rewardValue &&
              response.betOne.push({ type: key, reward: rewardValue || 0 })
            break
          case 'bet_four':
            rewardValue &&
              response.betFour.push({ type: key, reward: rewardValue || 0 })
            break
          case 'bet_six':
            rewardValue &&
              response.betSix.push({ type: key, reward: rewardValue || 0 })
            break
        }
      }
    }
  }

  const responseKeys: (keyof pResponsseConfig)[] = [
    'betTwo',
    'betThree',
    'betOne',
    'betSix',
    'betFour'
  ]
  // Now you can iterate over response using the responseKeys
  responseKeys?.forEach((key) => {
    const newObject: { [type: string]: number } = {} // Specify the type of newObject
    response?.[key]?.forEach((item) => {
      newObject[item.type] = item.reward
    })
    responseV2[key] = newObject
  })
  return responseV2
}
type BetRuleData = {
  bet_type: string
  bet_price: number
}
export const getRuleData = (round?: Round) => {
  const betRuleData: BetRuleData[] = []
  if (round) {
    for (const key in TYPE_REWARD_FIELD) {
      if (TYPE_REWARD_FIELD.hasOwnProperty(key)) {
        const rewardFields = TYPE_REWARD_FIELD[key]
        let rewardValue = 0
        if (
          rewardFields.every(
            (field) => (round as FixTypeLater)[field] !== undefined
          )
        ) {
          rewardValue = rewardFields.reduce((acc, field) => {
            if (
              acc !== undefined &&
              (round as FixTypeLater)[field] !== undefined
            ) {
              return (round as FixTypeLater)[field]
            }
            return acc
          }, 0) as number
          if (rewardValue) {
            betRuleData.push({ bet_price: rewardValue, bet_type: key })
          }
        }
      }
    }
  }
  return betRuleData
}

export const isGroupLotto = (gameType: string) => {
  return ['group', 'group_six', 'group_four'].includes(gameType)
}

export const isEncryptLotto = (gameType: string): boolean => {
  return gameType === 'encrypt_game'
}

export const isYeekeeType = (gameType: string) => {
  return [
    'yeekee',
    'yekee',
    'yeekee_vip',
    'yekee_vip',
    'yeekee_malta',
    'yeekee_malta'
  ].includes(gameType)
}
