import { AxiosResponse } from 'axios'
import {
  PaginationPayloadPaginationStructure,
  ResponsePaginationStructure,
  ResponseStructure,
  callAPI
} from '.'
import { sagaActions } from '../sagaActions'
import { FixTypeLater } from 'react-redux'
import { call, put, takeLatest } from 'redux-saga/effects'
import { systemErrorHandler } from './errorHandlerSaga'
import {
  YeekeePutNumberData,
  postYeekeePutNumber,
  postYeekeePutNumberFailure,
  postYeekeePutNumberSuccess,
  fetchYeekeeListPutNumberFailure,
  fetchYeekeeListPutNumber as fetchYeekee,
  fetchYeekeeListPutNumberSuccess,
  YeekeePutNumberPagination,
  clearYeekeePutNumber
} from 'redux/reducers/yeekeeReducer'
import { toast } from 'react-hot-toast'

const API_YEEKEE_POST_PUT_NUMBER = `${process.env.REACT_APP_HOST}/api/v1/yeekee/put_number`
const API_YEEKEE_FETCH_LIST_PUT_NUMBER = `${process.env.REACT_APP_HOST}/api/v1/yeekee/:roundId/list`

interface ResponseYeekeePaginationData extends ResponsePaginationStructure {
  data: YeekeePutNumberData[]
  summary: number
}
interface ListPutNumberYeekeePagination extends ResponseStructure {
  data: ResponseYeekeePaginationData
}
export interface ListYeekeePutNumberPagePayload
  extends PaginationPayloadPaginationStructure {
  roundId: string
}
export interface YeekeePutNumberPayload {
  roundId: number
  gameId: number
  inputNumber: string
}
type ResponseYeekeePutNumber = ResponseStructure

function* fetchYeekeeListPutNumber({
  payload: { page = 1, pageSize = 10, roundId }
}: {
  payload: ListYeekeePutNumberPagePayload
}) {
  try {
    yield put(fetchYeekee())
    const result: AxiosResponse<ListPutNumberYeekeePagination> = yield call(
      () => {
        let url = API_YEEKEE_FETCH_LIST_PUT_NUMBER.replace(':roundId', roundId)
        url = `${url}?page=${page}&pageSize=${pageSize}`
        return callAPI({ url })
      }
    )
    if (result?.data?.status === 'OK') {
      const action: YeekeePutNumberPagination = {
        count: result?.data?.data?.count,
        data: result?.data?.data?.data,
        summary: result?.data?.data?.summary
      }
      yield put(fetchYeekeeListPutNumberSuccess(action))
    } else {
      yield put(fetchYeekeeListPutNumberFailure(result?.data?.message))
    }
  } catch (e: FixTypeLater) {
    yield put(fetchYeekeeListPutNumberFailure(e.message))
    yield systemErrorHandler(e)
  }
}
function* postYeekeeNumber({
  payload: { gameId, roundId, inputNumber }
}: {
  payload: YeekeePutNumberPayload
}) {
  const loading = toast.loading('กำลังส่งข้อมูล')
  yield put(postYeekeePutNumber())
  try {
    const result: AxiosResponse<ResponseYeekeePutNumber> = yield call(() =>
      callAPI({
        url: API_YEEKEE_POST_PUT_NUMBER,
        method: 'post',
        data: {
          game_id: gameId,
          round_id: roundId,
          number_input: inputNumber
        }
      })
    )
    if (result.data.status === 'OK') {
      toast.dismiss(loading)
      yield put(postYeekeePutNumberSuccess(result.data))
    } else {
      toast.dismiss(loading)
      yield put(postYeekeePutNumberFailure(result.data.status))
    }
  } catch (e: FixTypeLater) {
    toast.dismiss(loading)
    yield systemErrorHandler(e)
    yield put(postYeekeePutNumberFailure(e.message))
  }
}

function* doClearYeekeePutNumber() {
  yield put(clearYeekeePutNumber())
}

export function* watchYeekeeGame() {
  yield takeLatest<FixTypeLater>(
    sagaActions.CLEAR_YEEKEE_PUT_RESPONSE,
    doClearYeekeePutNumber
  )
  yield takeLatest<FixTypeLater>(
    sagaActions.FETCH_YEEKEE_LIST_NUMBER,
    fetchYeekeeListPutNumber
  )
  yield takeLatest<FixTypeLater>(
    sagaActions.POST_YEEKEE_PUT_NUMBER,
    postYeekeeNumber
  )
}
