import styled from 'styled-components'
import { TWO_NUMBER_LIST } from './useManualBetItem'

export type BetItemData = {
  name: string
  key: string
  digit: number
  parent?: string
  onClick?: () => void
}
export interface BetItemProps {
  name: string
  accessor: string
  active: boolean
  onChangeBet: () => void
}
export interface ListManualBetProps {
  selected?: string[]
  onChangeBet: (key: string) => void
  items: BetItemData[]
}
const ListItem = styled.li`
  cursor: pointer;
`
const ListManualBet = ({
  selected,
  onChangeBet,
  items
}: ListManualBetProps) => {
  // TODO check this can move to react hook or not
  const isRenderSpecialTwoDigit = (key: string) => {
    const rules =
      ((TWO_NUMBER_LIST.includes(key) && selected?.includes('bet_two_top')) ||
        selected?.includes('bet_two_under') ||
        selected?.includes('tos_two')) &&
      !selected.includes('bet_three_top') &&
      !selected.includes('bet_three_tod') &&
      !selected.includes('swap_three')
    return rules
  }
  const renderManualBetItem = (item: BetItemData) => {
    if (
      !TWO_NUMBER_LIST.includes(item.key) ||
      isRenderSpecialTwoDigit(item.key)
    ) {
      return (
        <ManualBetItem
          key={JSON.stringify(item)}
          active={selected?.indexOf(item.key) !== -1}
          name={item.name}
          accessor={item.key}
          onChangeBet={() => item?.onClick?.() ?? onChangeBet(item.key)}
        />
      )
    }
  }
  return (
    <ul className="list-unstyled bet-manual-nav-container">
      {items.map(renderManualBetItem)}
    </ul>
  )
}

export const ManualBetItem = ({
  name,
  accessor,
  active,
  onChangeBet
}: BetItemProps) => {
  return (
    <ListItem className="mb-2">
      <a
        key={accessor}
        onClick={onChangeBet}
        title={name}
        className={`text-decoration-none btn-outline-light-grey radius-16 ${
          active && 'active'
        }`}
      >
        {name}
      </a>
    </ListItem>
  )
}
export default ListManualBet
