import { Layout, Navbar } from 'components'
import { PollRecent } from './poll-recent'
import { PollResulted } from './poll-resulted'
import { PollSummaryPage } from './poll-summary'
import { useNavBar } from 'components/Navbar/useNavbar'
import { navItem } from 'components/Navbar'
import { PollType } from 'types/navbar'
import { useTranslation } from 'react-i18next'
import { PollCanceled } from './poll-canceled'
import { PollHistory } from './poll-history'

const PollPage = () => {
  const { t } = useTranslation()
  const NavBarItem: navItem[] = [
    {
      name: t('poll.recent'),
      key: 'pills-bet-poll-recent-tab',
      icon: 'icon-bet-poll-recent'
    },
    {
      name: t('poll.resulted'),
      key: 'pills-bet-poll-result-tab',
      icon: 'icon-bet-poll-result'
    },
    {
      name: t('poll.cancelled'),
      key: 'pills-bet-poll-canceled-tab',
      icon: 'icon-bet-poll-summary'
    },
    {
      name: t('poll.history'),
      key: 'pills-bet-poll-history-tab',
      icon: 'icon-bet-poll-history'
    }
  ]
  const { selected, setSelectedItem } = useNavBar<PollType>(
    'pills-bet-poll-recent-tab'
  )
  const renderTab = () => {
    switch (selected) {
      case 'pills-bet-poll-recent-tab':
        return <PollRecent />
      case 'pills-bet-poll-result-tab':
        return <PollResulted />
      case 'pills-bet-poll-canceled-tab':
        return <PollCanceled />
      case 'pills-bet-poll-history-tab':
        return <PollHistory />
    }
  }
  return (
    <Layout>
      <div className="container mt-4 mb-4">
        <div className="row">
          <div className="col-12">
            <h2 className="text-center mb-4">{t('poll.pollList')}</h2>
            <Navbar
              navBarClass="bet-poll-navigation nav-justified"
              selected={selected}
              onChangeItem={setSelectedItem}
              items={NavBarItem}
            />
            <div id="pills-tabContent">{renderTab()}</div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default PollPage
