import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FixTypeLater } from 'react-redux'

export interface RouterState {
  navigatePath: string | undefined
  delay: number | undefined
}

const initialState: RouterState = {
  navigatePath: undefined,
  delay: 0
}

const routerSlice = createSlice({
  name: 'router',
  initialState,
  reducers: {
    naviagePath: (state, action: PayloadAction<RouterState>) => {
      return {
        ...state,
        ...action.payload
      }
    }
  }
})

export const { naviagePath } = routerSlice.actions
export default routerSlice
