import React from 'react'
import { Svg } from 'images'
interface NumberInputProps {
  maxDigit: number
  currentNumber: number[]
  onAddNumber?: (digit: number) => void
  onDeleteNumber?: () => void
  loading?: boolean
}
const NumberInput = ({
  maxDigit,
  currentNumber,
  onAddNumber,
  onDeleteNumber,
  loading
}: NumberInputProps) => {
  return (
    <React.Fragment>
      <div className="container mb-4">
        <div className="row">
          <div
            className="col-12 d-flex justify-content-center mb-2 pin-code"
            id="pin-code"
          >
            {!loading &&Array.from({ length: maxDigit }, (i, k) => (
              <NumberInputLabel digit={currentNumber?.[k]} key={`${i}${k}`} />
            ))}
          </div>
        </div>
      </div>
      <div className="container caluculator-container">
        <div className="row">
          {Array.from({ length: 9 }, (_, i) => (
            <div className="col-4 d-flex justify-content-center" key={i + 1}>
              <button
                className={`btn w-100 d-flex justify-content-center mb-2 ${loading ? 'disabled' : ''}`}
                onClick={() => !loading && onAddNumber?.(i + 1)}
              >
                {i + 1}
              </button>
            </div>
          ))}
        </div>
        <div className="row">
          <div className="col-4 d-flex justify-content-center">
            <button  className={`btn w-100 d-flex justify-content-center mb-2 ${loading ? 'disabled' : ''}`}>
              <img src={Svg.iconEnter} alt="Enter" />
            </button>
          </div>
          <div className="col-4 d-flex justify-content-center">
            <button
               className={`btn w-100 d-flex justify-content-center mb-2 ${loading ? 'disabled' : ''}`}
              onClick={() => !loading && onAddNumber?.(0)}
            >
              0
            </button>
          </div>
          <div className="col-4 d-flex justify-content-center">
            <button
               className={`btn w-100 d-flex justify-content-center mb-2 ${loading ? 'disabled' : ''}`}
              onClick={() => !loading && onDeleteNumber?.()}
            >
              <img src={Svg.iconBack} alt="Back" />
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
interface NumberInputLabelProps {
  digit?: number
}
const NumberInputLabel = ({ digit }: NumberInputLabelProps) => {
  return (
    <label className="number-input">
      <span className="caret">{digit ?? '-'}</span>
    </label>
  )
}

export default NumberInput
