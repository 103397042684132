export interface SelectDropDownProps {
  options: { label: string; value: string | number }[]
  onChange: (value: number) => void
  value?: string | number
}
const SelectDropDown = ({ options, onChange, value }: SelectDropDownProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const val = event.target.value
    const valInt = parseInt(val)
    onChange(valInt)
  }
  return (
    <div className="container mb-3">
      <div className="row">
        <div className="col-12">
          <div className="form-group mb-0">
            <select
              id="select"
              name="select"
              className="custom-select form-control text-center"
              style={{ fontSize: '1rem' }}
              onChange={handleChange}
              value={value}
            >
              {options.map((i) => (
                <option key={i.value} value={i.value}>
                  {i.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  )
}
export default SelectDropDown
