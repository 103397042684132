import { Img } from 'images'
const Banner = () => {
  return (
    <div className="container mt-4 mb-4">
      <div className="row">
        <div id="bannerCrousel" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src={Img.Banner} className="d-block w-100" alt="..." />
            </div>
            <div className="carousel-item">
              <img src={Img.Banner} className="d-block w-100" alt="..." />
            </div>
            <div className="carousel-item">
              <img src={Img.Banner} className="d-block w-100" alt="..." />
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#bannerCrousel"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#bannerCrousel"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Banner
