import { useTranslation } from 'react-i18next'

export interface BetFooterProps {
  onNext?: () => void
  onPrev?: () => void
  className?: string
}
const BetFooter = ({
  onNext,
  onPrev,
  className = 'd-none d-lg-flex'
}: BetFooterProps) => {
  const { t } = useTranslation()
  return (
    <div
      className={`row bet-navigation-container mt-lg-4 mb-lg-4 ${className}`}
    >
      <div className="col-6">
        <a
          onClick={() => onPrev?.()}
          title={t('betFooter.previous').toString()}
          className="btn-bet-navigation text-decoration-none d-flex justify-content-start align-items-center space-btn-inner radius-8"
        >
          <i className="btn-bet-arrow-nav bet-arrow-left"></i>
          {t('betFooter.previous')}
        </a>
      </div>

      <div className="col-6">
        <a
          onClick={() => onNext?.()}
          // href="#"
          title={t('betFooter.next').toString()}
          className="btn-bet-navigation text-decoration-none d-flex justify-content-start align-items-center space-btn-inner radius-8 flex-row-reverse"
        >
          <i className="btn-bet-arrow-nav bet-arrow-right"></i>
          {t('betFooter.next')}
        </a>
      </div>
    </div>
  )
}

export default BetFooter
