import { LottoLayout, Navbar } from 'components'
import React from 'react'
import useLottoBetPage from './hook/useLottoBetPage'
import YeekeeResult from './yeekee/yeekee-result'
import { useEffect } from 'react'

const LottoBetPage = () => {
  const {
    isYeekeeResulted,
    round,
    getTitlePage,
    loading,
    lottoNaveItem,
    selected,
    setSelectedItem,
    renderSubPage,
    isRoundClosed,
    isYeekee,
    onNextRound,
    onPrevRound,
    betRuleData
  } = useLottoBetPage()
  useEffect(() => {
    if (isRoundClosed && isYeekee && !isYeekeeResulted) {
      setSelectedItem('pills-shoot-lotto-number-tab')
    }
  }, [isRoundClosed, isYeekee, isYeekeeResulted])
  const renderContent = () => {
    if (!isYeekeeResulted) {
      return (
        <>
          <div className="main-nav-bullet-container">
            <Navbar
              items={lottoNaveItem}
              selected={selected}
              onChangeItem={setSelectedItem}
              navBarClass="main-nav-bullet-container d-flex justify-content-center justify-content-lg-start"
            />
          </div>
          {renderSubPage()}
        </>
      )
    } else {
      return <YeekeeResult />
    }
  }
  return (
    <LottoLayout
      title={getTitlePage()}
      dueDate={round?.close_at ?? ''}
      selectedPage={selected}
      loading={loading}
      onNext={onNextRound}
      onPrev={onPrevRound}
      isRoundClose={isRoundClosed}
      betRuleData={betRuleData}
    >
      {renderContent()}
    </LottoLayout>
  )
}
export default LottoBetPage
