import { GenericWidget } from 'components'
import { GenericWidgetProps } from 'components/GenericWidget'
const PageContact = () => {
  const PageContactData: GenericWidgetProps = {
    component: 'Layout',
    items: [
      {
        component: 'Contact'
      }
    ]
  }
  return <GenericWidget {...PageContactData} />
}
export default PageContact
