import { useState } from 'react'
import { BetItemData } from '.'
import {
  generateDoubleNumber,
  generateEvenNumber,
  generateHighNumber,
  generateLowNumber,
  generateOddNumber
} from 'utils/number-utils'
import { useLottoContext } from 'contexts'
import { LottoConfigObject } from 'utils/lotto-type-utils'
export const TWO_NUMBER_LIST = [
  '19_number',
  'end_number',
  'double',
  'even_number',
  'high_number',
  'high_number',
  'low_number',
  'low_number',
  'odd_number',
  'start_number'
]
const THREE_NUMBER_LIST = [
  'bet_three_top',
  'bet_three_tod',
  'swap_three',
  'bet_three_front',
  'bet_three_under'
]
export const useManualBetItem = (defaultItem: string[]) => {
  const { lottoConfig, addLottoListAndHandleResponse } = useLottoContext()
  const [digit, SetDigit] = useState<number[]>([3])
  const [selected, SetSelected] = useState<string[]>([...defaultItem])
  const [maxDigit, SetMaxDigit] = useState<number>(3)
  const checkDoubleNumberSelected = (): number => {
    return selected.filter((v) => TWO_NUMBER_LIST.includes(v)).length
  }
  const onChangeBetItem = (key: string) => {
    const pSelected = new Set([...selected])

    if (THREE_NUMBER_LIST.includes(key) && checkDoubleNumberSelected() > 0) {
      TWO_NUMBER_LIST?.forEach((x) => pSelected.delete(x))
    } else if (TWO_NUMBER_LIST.includes(key)) {
      THREE_NUMBER_LIST?.forEach((x) => pSelected.delete(x))
    }
    if (key === 'swap_three' && pSelected.has('bet_three_tod')) {
      pSelected.delete('bet_three_tod')
    }
    if (key === 'bet_three_tod' && pSelected.has('swap_three')) {
      pSelected.delete('swap_three')
    }

    if (key === 'bet_run_top') {
      pSelected.clear()
      selected.includes('bet_run_bottom') && pSelected.add('bet_run_bottom')
    } else if (key === 'bet_run_bottom') {
      pSelected.clear()
      selected.includes('bet_run_top') && pSelected.add('bet_run_top')
    } else {
      pSelected.delete('bet_run_top')
      pSelected.delete('bet_run_bottom')
    }

    if (pSelected.has(key)) {
      pSelected.delete(key)
    } else {
      pSelected.add(key)
    }
    const arrData: string[] = Array.from(pSelected)
    setDigit(pSelected)
    SetSelected(arrData)
  }

  const setDigit = (pSelected: Set<string>) => {
    const pDigit = new Set([...digit])
    if (hasThreeDigit(pSelected)) {
      pDigit.add(3)
    } else {
      pDigit.delete(3)
    }
    if (hasTwoDigit(pSelected)) {
      pDigit.add(2)
    } else {
      pDigit.delete(2)
    }
    if (hasOneDigit(pSelected)) {
      pDigit.add(1)
    } else {
      pDigit.delete(1)
    }

    if (hasTwoDigitSpecial(pSelected) && hasTwoDigit(pSelected)) {
      pDigit.clear()
      pDigit.add(1)
    }
    if (pDigit.size === 0) {
      pDigit.add(3)
    }

    const arrDigit: number[] = Array.from(pDigit)
    setMaxDigit(arrDigit)
    SetDigit(arrDigit)
  }

  const setMaxDigit = (digits: number[]) => {
    const pMaxNum: number = Math.max(...digits)
    SetMaxDigit(pMaxNum)
  }
  const hasThreeDigit = (selected: Set<string>) => {
    return (
      selected.has('bet_three_top') ||
      selected.has('bet_three_tod') ||
      selected.has('bet_three_front') ||
      selected.has('bet_three_under') ||
      selected.has('swap_three')
    )
  }

  const hasTwoDigit = (selected: Set<string>) => {
    return (
      selected.has('bet_two_top') ||
      selected.has('bet_two_under') ||
      selected.has('swap_two')
    )
  }

  const hasOneDigit = (selected: Set<string>) => {
    return (
      selected.has('bet_run_top') ||
      selected.has('bet_run_bottom') ||
      selected.has('start_number') ||
      selected.has('end_number')
    )
  }

  const hasTwoDigitSpecial = (selected: Set<string>) => {
    return (
      selected.has('19_number') ||
      selected.has('end_number') ||
      selected.has('double') ||
      selected.has('even_number') ||
      selected.has('high_number') ||
      selected.has('high_number') ||
      selected.has('low_number') ||
      selected.has('low_number') ||
      selected.has('odd_number') ||
      selected.has('start_number')
    )
  }

  const injectFunctionLottoBet = (
    data: BetItemData[],
    selected: string[]
  ): BetItemData[] => {
    const buildOnClickFunction = (numbers: string[]) => {
      const params: LottoConfigObject = selected?.reduce(
        (acc: Record<string, number>, menuOption) => {
          acc[menuOption] = lottoConfig.betTwo[menuOption]
          return acc
        },
        {}
      )
      numbers.forEach((number) => {
        addLottoListAndHandleResponse(number, params)
      })
    }
    return data?.map((v) => {
      switch (v.key) {
        case 'double':
          v.onClick = () => {
            const numbers = generateDoubleNumber()
            buildOnClickFunction(numbers)
          }
          break
        case 'high_number':
          v.onClick = () => {
            const numbers = generateHighNumber()
            buildOnClickFunction(numbers)
          }
          break
        case 'low_number':
          v.onClick = () => {
            const numbers = generateLowNumber()
            buildOnClickFunction(numbers)
          }
          break
        case 'odd_number':
          v.onClick = () => {
            const numbers = generateOddNumber()
            buildOnClickFunction(numbers)
          }
          break
        case 'even_number':
          v.onClick = () => {
            const numbers = generateEvenNumber()
            buildOnClickFunction(numbers)
          }
          break
      }
      return v
    })
  }

  return {
    digit,
    maxDigit,
    selected,
    onChangeBetItem,
    injectFunctionLottoBet,
    hasTwoDigitSpecial
  }
}
