import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import duration from 'dayjs/plugin/duration'
import 'dayjs/locale/th' // Importing Thai locale
import { useState, useEffect } from 'react'

// Extending dayjs with required plugins
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(duration)
dayjs.tz.setDefault('Asia/Bangkok') // Setting default timezone to Bangkok

export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm'
export const DATE_TIME_FORMAT_HUMAN = 'D MMM YYYY HH:mm'
export const DATE_TIME_SECOND_FORMAT_HUMAN = 'D MMM YYYY HH:mm:ss'
export const DATE_FORMAT = 'MM/DD/YYYY'
export const DATE_FORMAT_QUERY = 'YYYY-MM-DD'

// Validate if the given string is a valid date
export const isValidDate = (dateString: string): boolean => {
  const parsedDate = dayjs(dateString)
  return parsedDate.isValid() && parsedDate.isAfter(dayjs('2000-01-01'))
}

// Custom hook for countdown
export const useCountDown = (
  date: string
): [string, string, string, boolean, boolean] => {
  const [countdown, setCountdown] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [isOverdue, setIsOverdue] = useState<boolean>(false)

  useEffect(() => {
    if (!isValidDate(date)) {
      setIsOverdue(true)
      setLoading(false)
      return
    }

    const updateCountdown = () => {
      const now = dayjs()
      const targetDate = dayjs(date)
      const diff = targetDate.diff(now)

      if (diff <= 0) {
        setCountdown(0)
        setIsOverdue(true)
        setLoading(false)
      } else {
        setCountdown(diff)
        setLoading(false)
      }
    }

    updateCountdown()
    const intervalId = setInterval(updateCountdown, 1000)
    return () => clearInterval(intervalId)
  }, [date])

  const hours = setStrPad(Math.floor(dayjs.duration(countdown).asHours()))
  const minutes = setStrPad(dayjs.duration(countdown).minutes())
  const seconds = setStrPad(dayjs.duration(countdown).seconds())

  return [hours, minutes, seconds, loading, isOverdue]
}

const setStrPad = (number: number): string => number.toString().padStart(2, '0')

export const convertToDateTime = (
  date: string,
  format: string = DATE_TIME_FORMAT,
  locale = 'th'
): string => {
  dayjs.locale(locale)
  return dayjs(date).format(format)
}

export default dayjs
