import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { BetLottoType } from 'contexts/lotto-context'
import { FixTypeLater } from 'react-redux'
import {
  BetCancelResponse,
  BetSuccessResponse
} from 'redux/saga/fetchGameListSaga'

export interface ListGameState {
  loading: {
    listGame: boolean
    game: boolean
    groups: boolean
    rounds: boolean
    round: boolean
    response: boolean
    listTicket: boolean
    ticketDetails: boolean
    listLimitPriceNumber: boolean
    announcements: boolean
    results: boolean
  }
  listGame: Game[]
  announcements: Announcement[]
  game: Game | undefined
  groups: Group[]
  results: Game[]
  rounds: RoundPage
  round: Round | undefined
  response: FixTypeLater
  listTicket: ListTickets | undefined
  ticketDetails: Ticket | undefined
  listLimitPriceNumber: ListLimitPriceNumber[]
  error: string | undefined
}
export interface Announcement {
  id: string
  title_th: string
  title_en: string
  content_th: string
  content_en: string
  created_at: string
  updated_at: string
}
export interface RoundPage extends ListPageTemplate {
  rounds: Round[]
}
export interface ListPageTemplate {
  page: number
  count: number
  limit: number
  totalPages: number
}
export interface ListTickets {
  count: number
  page: number
  limit: number
  tickets: Ticket[]
}
export interface ListLimitPriceNumber {
  number: string
  system_current_bet: number
  type: string
  bet_type: string
  rules: LimitPriceRule[]
  limit_price: number
}
export interface LimitPriceRule {
  min_rate: number
  max_rate: number
  pay_rate_percent: number
}
export interface Group {
  id: number
  name_th: string
  name_en: string
  code: string
  games: Game[]
  sort: number
  created_at: string
  updated_at: string
}
export interface Ticket {
  bet_amount: number
  created_at: string
  id: number
  percent_share: number
  provider_id: number
  provider_user_id: number
  reward_result: number
  win_amount: number
  status: string
  updated_at: string
  details: TicketDetail[]
  lotto_round_game?: Round
  lotto_game?: Game
  is_primary: boolean
  bonus: number
}
export interface TicketDetail {
  id: number
  provider_id: number
  provider_user_id: number
  number: string
  type: BetLottoType
  bet_amount: number
  reward_amount: number
  reward_result: number
  lotto_bet_ticket_id: number
  status: string
  created_at: string
  updated_at: string
}
export interface YeekeeConfig {
  id: number
  created_at: string
  updated_at: string
  provider_id: number
  put_number_extend_time: number
  bonus_row_1: number
  bonus_row_2: number
  bonus_row_3: number
  bonus_row_4: number
  bonus_row_5: number
  bonus_reward: number
}
export interface YeekeeRoundBonusResult {
  id: number
  yeekee_round_id: number
  game_date: string
  summary_result: string
  result_row_16: string
  result_row_16_username: string
  player_username_bonus_1: string
  player_bonus_1_paid: string
  player_username_bonus_2: string
  player_bonus_2_paid: string
  player_username_bonus_3: string
  player_bonus_3_paid: string
  player_username_bonus_4: string
  player_bonus_4_paid: string
  player_username_bonus_5: string
  player_bonus_5_paid: string
  reward_bonus: number
  created_at: string
  updated_at: string
  vip: string
}
export interface Round {
  close_at: string
  create_at: string
  id: number
  is_enable: string
  open_at: string
  top_three_result?: string
  four_result?: string
  six_result?: string
  top_two_result?: string
  result_top_two?: string
  bottom_three_front_1_result?: string
  bottom_three_front_2_result?: string
  bottom_three_1_result?: string
  bottom_three_2_result?: string
  bottom_two_result?: string
  reward_top_two?: number
  reward_bottom_two?: number
  reward_top_two_flip?: number
  reward_bottom_two_flip?: number
  reward_top_three?: number
  reward_bottom_three?: number
  reward_top_three_flip?: number
  reward_bottom_three_flip?: number
  reward_top_three_front?: number
  reward_bottom_three_front?: number
  reward_top_three_front_flip?: number
  reward_bottom_three_front_flip?: number
  reward_run_top?: number
  reward_run_bottom?: number
  round_number: number
  is_result_confirmed: boolean
  previous_id: number
  next_id: number
  updated_at: string
  yeekee_config?: YeekeeConfig
  yeekee_round_bonus_result?: YeekeeRoundBonusResult
  signature?: string
  encrypt_result?: string
}
export interface Game {
  code: string
  created_at: string
  desc_en: string
  desc_th: string
  id: number
  is_enable: string
  is_hilight: string
  lotto_group_id: string
  name_en: string
  name_th: string
  order: number
  type: string
  cost_price: number
  updated_at: string
  flag: string
  rounds: Round[]
}
const initRoundData: RoundPage = {
  count: 0,
  limit: 0,
  rounds: [],
  page: 1,
  totalPages: 0
}
const initialState: ListGameState = {
  loading: {
    listGame: false,
    game: false,
    groups: false,
    rounds: false,
    round: false,
    response: false,
    listTicket: false,
    announcements: false,
    ticketDetails: false,
    listLimitPriceNumber: false,
    results: false
  },
  listGame: [],
  game: undefined,
  groups: [],
  results: [],
  rounds: initRoundData,
  round: undefined,
  response: undefined,
  announcements: [],
  listTicket: undefined,
  ticketDetails: undefined,
  listLimitPriceNumber: [],
  error: undefined
}
const ListGameSlice = createSlice({
  name: 'listGame',
  initialState,
  reducers: {
    fetchListGameData: (state, action: PayloadAction<Game[]>) => {
      return {
        ...state,
        listGame: action.payload
      }
    },
    fetchGameData: (state) => {
      return {
        ...state,
        loading: {
          ...state.loading,
          game: true
        }
      }
    },
    fetchGameDataSuccess: (state, action: PayloadAction<Game>) => {
      return {
        ...state,
        loading: {
          ...state.loading,
          game: false
        },
        game: action.payload
      }
    },
    fetchGameDataFailure: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        game: undefined,
        loading: {
          ...state.loading,
          game: false
        },
        error: action.payload
      }
    },
    fetchRoundData: (state) => {
      return {
        ...state,
        round: undefined,
        loading: {
          ...state.loading,
          round: true
        }
      }
    },
    fetchResults: (state) => {
      return {
        ...state,
        results: [],
        loading: {
          ...state.loading,
          results: true
        }
      }
    },
    fetchResultsSuccess: (state, action: PayloadAction<Game[]>) => {
      return {
        ...state,
        loading: {
          ...state.loading,
          results: false
        },
        results: action.payload
      }
    },
    fetchResultsFailure: (state, action: PayloadAction<string | undefined>) => {
      return {
        ...state,
        loading: {
          ...state.loading,
          results: false
        },
        results: [],
        error: action.payload ? action.payload : undefined
      }
    },
    fetchRoundDataSuccess: (state, action: PayloadAction<Round>) => {
      return {
        ...state,
        round: action.payload,
        loading: {
          ...state.loading,
          round: false
        }
      }
    },
    fetchRoundDataFailure: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        round: undefined,
        loading: {
          ...state.loading,
          round: false
        },
        error: action.payload
      }
    },
    fetchRoundList: (state) => {
      return {
        ...state,
        loading: {
          ...state.loading,
          rounds: true
        },
        rounds: initRoundData
      }
    },
    fetchRoundListSuccess: (state, action: PayloadAction<RoundPage>) => {
      const totalPages = Math.ceil(action.payload.count / action.payload.limit)
      return {
        ...state,
        loading: {
          ...state.loading,
          rounds: false
        },
        rounds: {
          ...action.payload,
          totalPages
        }
      }
    },
    fetchRoundListFailure: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        rounds: initRoundData,
        error: action.payload,
        loading: {
          ...state.loading,
          rounds: false
        }
      }
    },
    loadMoreRoundList: (state) => {
      return {
        ...state,
        loading: {
          ...state.loading,
          rounds: true
        }
      }
    },
    loadMoreRoundListSuccess: (state, action: PayloadAction<RoundPage>) => {
      const totalPages = Math.ceil(action.payload.count / action.payload.limit)
      return {
        ...state,
        loading: {
          ...state.loading,
          rounds: false
        },
        rounds: {
          ...action.payload,
          totalPages,
          rounds: [...state.rounds.rounds, ...action.payload.rounds]
        }
      }
    },
    loadMoreRoundListFailure: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        rounds: {
          count: 0,
          limit: 0,
          rounds: [],
          page: 1,
          totalPages: 0
        },
        error: action.payload,
        loading: {
          ...state.loading,
          rounds: false
        }
      }
    },
    fetchBetResponse: (state, action: PayloadAction<BetSuccessResponse>) => {
      return {
        ...state,
        response: action.payload
      }
    },
    fetchTicketPagination: (state) => {
      return {
        ...state,
        loading: {
          ...state.loading,
          listTicket: true
        },
        listTicket: undefined,
        error: undefined
      }
    },
    fetchTicketPaginationSuccess: (
      state,
      action: PayloadAction<ListTickets>
    ) => {
      return {
        ...state,
        loading: {
          ...state.loading,
          listTicket: false
        },
        listTicket: action.payload,
        error: undefined
      }
    },
    fetchTicketPaginationFailure: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        loading: {
          ...state.loading,
          listTicket: false
        },
        listTicket: undefined,
        error: action.payload
      }
    },
    fetchTicketDetailData: (state) => {
      return {
        ...state,
        loading: { ...state.loading, ticketDetails: true },
        error: undefined
      }
    },
    fetchTicketDetailDataSuccess: (state, action: PayloadAction<Ticket>) => {
      return {
        ...state,
        loading: { ...state.loading, ticketDetails: false },
        ticketDetails: action.payload,
        error: undefined
      }
    },
    fetchTicketDetailDataFailure: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        loading: { ...state.loading, ticketDetails: false },
        ticketDetails: undefined,
        error: action.payload
      }
    },
    fetchAnnouncement: (state) => {
      return {
        ...state,
        loading: { ...state.loading, announcements: true },
        announcements: [],
        error: undefined
      }
    },
    fetchAnnouncementSuccess: (
      state,
      action: PayloadAction<Announcement[]>
    ) => {
      return {
        ...state,
        loading: { ...state.loading, announcements: false },
        announcements: action.payload,
        error: undefined
      }
    },
    fetchAnnouncementFailure: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        loading: { ...state.loading, announcements: false },
        announcements: [],
        error: action.payload
      }
    },
    fetchGroupData: (state) => {
      return {
        ...state,
        loading: {
          ...state.loading,
          groups: true
        }
      }
    },
    fetchGroupDataSuccess: (state, action: PayloadAction<Group[]>) => {
      return {
        ...state,
        loading: {
          ...state.loading,
          groups: false
        },
        groups: action.payload,
        error: undefined
      }
    },
    fetchGroupDataFailure: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        loading: {
          ...state.loading,
          groups: false
        },
        error: action.payload
      }
    },
    fetchCancelBetResponse: (
      state,
      action: PayloadAction<BetCancelResponse>
    ) => {
      return {
        ...state,
        response: action.payload
      }
    },
    clearResponseData: (state) => {
      return {
        ...state,
        response: undefined,
        error: undefined
      }
    },
    fetchListLimitPriceNumberData: (state) => {
      return {
        ...state,
        listLimitPriceNumber: [],
        loading: {
          ...state.loading,
          listLimitPriceNumber: true
        }
      }
    },
    fetchListLimitPriceNumberDataSuccess: (
      state,
      action: PayloadAction<ListLimitPriceNumber[]>
    ) => {
      return {
        ...state,
        listLimitPriceNumber: action.payload,
        loading: {
          ...state.loading,
          listLimitPriceNumber: false
        }
      }
    },
    fetchListLimitPriceNumberDataFailure: (
      state,
      action: PayloadAction<string>
    ) => {
      return {
        ...state,
        listLimitPriceNumber: [],
        error: action.payload,
        loading: {
          ...state.loading,
          listLimitPriceNumber: false
        }
      }
    }
  }
})

export const {
  clearResponseData,
  fetchAnnouncement,
  fetchAnnouncementFailure,
  fetchAnnouncementSuccess,
  fetchBetResponse,
  fetchCancelBetResponse,
  fetchGameData,
  fetchGameDataFailure,
  fetchGameDataSuccess,
  fetchGroupData,
  fetchGroupDataFailure,
  fetchGroupDataSuccess,
  fetchListGameData,
  fetchListLimitPriceNumberData,
  fetchListLimitPriceNumberDataFailure,
  fetchListLimitPriceNumberDataSuccess,
  fetchResults,
  fetchResultsFailure,
  fetchResultsSuccess,
  fetchRoundData,
  fetchRoundDataFailure,
  fetchRoundDataSuccess,
  fetchRoundList,
  fetchRoundListFailure,
  fetchRoundListSuccess,
  fetchTicketDetailData,
  fetchTicketDetailDataFailure,
  fetchTicketDetailDataSuccess,
  fetchTicketPagination,
  fetchTicketPaginationFailure,
  fetchTicketPaginationSuccess,
  loadMoreRoundList,
  loadMoreRoundListFailure,
  loadMoreRoundListSuccess
} = ListGameSlice.actions
export default ListGameSlice
