import BetDetailsPanel from '../BetDetailsPanel'
import BetFooter, { BetFooterProps } from '../BetFooter'
import CountdownClock from '../CountdownClock'
import Layout from './Layout'
import PageTitle, { PageTitleProps } from '../PageTitle'
import { LottoContextProvider } from 'contexts'
// TODO remove when get prod
import BetRulePanel from '../BetRulePanel'
import { LottoNavType } from 'types/navbar'
import { useTranslation } from 'react-i18next'
import { FixTypeLater } from 'react-redux'
export interface LottoPageLayoutProps extends PageTitleProps, BetFooterProps {
  dueDate: string
  selectedPage?: LottoNavType
  children?: React.ReactNode
  loading?: boolean
  isRoundClose?: boolean
  betRuleData?: FixTypeLater
}
const LottoPageLayout = (props: LottoPageLayoutProps) => {
  const { t } = useTranslation()
  const renderBetPanel = () => {
    if (props.isRoundClose) {
      return <></>
    }
    if (props.selectedPage === 'pills-bet-rules-tab') {
      return (
        <div className="col-12 col-lg-4 bet-details">
          <BetRulePanel data={props?.betRuleData ?? []} />
        </div>
      )
    }
    if (props.selectedPage === 'pills-bet-lotto-tab') {
      return (
        <div className="col-12 col-lg-4 bet-details">
          <CountdownClock
            title={t('layout.lottoPage.clockText')}
            titleClass="text-center"
            clockClass="bet-time-countdown"
            dueDate={props.dueDate}
          />
          <BetDetailsPanel />
          <BetFooter className="d-flex d-lg-none" />
        </div>
      )
    }
  }
  return (
    <Layout loading={props.loading}>
      <LottoContextProvider>
        <section className="bet-dashboard-container container mt-4 mb-4">
          <div className="row">
            <div
              className={`col-12 ${
                props?.selectedPage === 'pills-shoot-lotto-number-tab' ||
                props.isRoundClose
                  ? 'col-lg-12'
                  : 'col-lg-8'
              } bet-dashboard mb-4 mb-lg-0`}
            >
              <PageTitle title={props.title} details={props.details} />
              {props.children}

              <BetFooter
                onNext={props.onNext}
                onPrev={props.onPrev}
                className={props.className}
              />
            </div>
            {renderBetPanel()}
          </div>
        </section>
      </LottoContextProvider>
    </Layout>
  )
}

export default LottoPageLayout
