import LottoContainer, { LottoContainerProps } from 'components/LottoContainer'
import LottoItem, { LottoItemProps } from 'components/LottoItem'
export interface LottoListProps extends LottoContainerProps {
  listLotto?: LottoItemProps[]
}
const LottoList = ({
  title,
  titleClass,
  listLotto,
  buttonText,
  isShowButton,
  onClickButton
}: LottoListProps) => {
  return (
    <LottoContainer
      title={title}
      titleClass={titleClass}
      isShowButton={isShowButton}
      buttonText={buttonText}
      onClickButton={onClickButton}
    >
      {listLotto &&
        listLotto.map((i) => <LottoItem key={JSON.stringify(i)} {...i} />)}
    </LottoContainer>
  )
}

export default LottoList
