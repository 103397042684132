import { GenericWidget } from 'components'
import { GenericWidgetProps } from 'components/GenericWidget'
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { sagaActions } from 'redux/sagaActions'
import { RootState } from 'redux/reducers'

export const LandingPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { token } = useParams()
  const targetPath = useSelector((state: RootState) => {
    return state.router?.path
  })

  // console.log(useParams())
  useEffect(() => {
    console.log('Component didmount')
    dispatch({
      type: sagaActions.INITIALIZE_STATE_TO_SERVER,
      payload: { token }
    })
  }, [])

  const LandingPageData: GenericWidgetProps = {
    component: 'Layout',
    props: { hideMenu: true, token: token },
    items: [
      {
        component: 'Loading'
      }
    ]
  }
  return <GenericWidget {...LandingPageData} />
}

export default LandingPage
