import { combineReducers } from 'redux'
import todoSlice, { TodoState } from 'redux/reducers/todoReducer'
import ListGameSlice, { ListGameState } from './gameReducer'
import ListUserSlice, { UserState } from './userReducer'
import ListErrorHandler, { ErrorHandlerState } from './errorHandlerReducer'
import RouterSlice from './routerReducer'
import { FixTypeLater } from 'react-redux'
import { ToastrState, reducer as toastrReducer } from 'react-redux-toastr'
import YeekeeGameSlice, { YeekeeGameState } from './yeekeeReducer'
import limitNumberSlice, { LimitNumberState } from './limitNumberReducer'
export interface RootState {
  todo: TodoState
  games: ListGameState
  user: UserState
  error: ErrorHandlerState
  router: FixTypeLater
  toastr: ToastrState
  yeekee: YeekeeGameState
  limitTemplate: LimitNumberState
}
const rootReducer = combineReducers<RootState>({
  todo: todoSlice.reducer,
  games: ListGameSlice.reducer,
  user: ListUserSlice.reducer,
  error: ListErrorHandler.reducer,
  router: RouterSlice.reducer,
  toastr: toastrReducer,
  yeekee: YeekeeGameSlice.reducer,
  limitTemplate: limitNumberSlice.reducer
})
export default rootReducer
