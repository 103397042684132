import {
  HomePage,
  LottoPage,
  PollPage,
  LandingPage,
  SessionErrorPage,
  Page404
} from 'pages'
import PageContact from 'pages/contact'
import LottoRoundsPage from 'pages/lotto-rounds-page'
import PollDetails from 'pages/poll/poll-details'
import ResultPage from 'pages/result'
import { Navigate, RouteObject } from 'react-router-dom'

const routes: RouteObject[] = [
  {
    path: '/startGame/:token',
    element: <LandingPage />
  },
  {
    path: '/session_error',
    element: <SessionErrorPage />
  },
  {
    path: '/',
    children: [
      {
        path: '/',
        element: <Navigate to="/lotto/" replace />
      },
      {
        path: '404',
        element: <Page404 />
      },
      {
        path: 'contact',
        element: <PageContact />
      },
      {
        path: 'lotto',
        children: [
          {
            path: '',
            element: <HomePage />
          },
          { path: ':gameType/:gameId', element: <LottoRoundsPage /> },
          { path: ':gameType/:gameId/:roundId', element: <LottoPage /> },
          { path: 'poll', element: <PollPage /> },
          { path: 'poll/history/:betId', element: <PollDetails isHistory /> },
          { path: 'poll/:betId', element: <PollDetails /> },
          { path: 'result', element: <ResultPage /> }
        ]
      }
    ]
  },
  {
    path: '*',
    element: <Navigate to="/404" replace />
  }
]
export default routes
