import React from 'react'
import styled from 'styled-components'
import { FixTypeLater } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { LoadingMiniContent } from 'components/Loading'
import EmptyContent from 'components/EmptyContent'

const PaginationButton = styled.a`
  background-color: rgba(238, 238, 238, 1) !important;
  border-radius: 99px;
  margin-right: 3em;
  padding: 8px 24px;

  &.active {
    background-color: #f1b625 !important;
    color: #080f18;
  }

  &.disabled {
    cursor: not-allowed;
    color: #6c757d !important;
  }
`
export type Column = {
  name: string
  accessor: string
  Cell?: (row: FixTypeLater, idx: number) => React.ReactElement
  thClass?: string
  thStyle?:
    | React.StyleHTMLAttributes<HTMLTableCellElement>
    | React.CSSProperties
  tdClass?: string
  tdStyle?: React.CSSProperties
}
export interface TableProps {
  sort?: string
  columns: Column[]
  data: { [key: string]: FixTypeLater }[]
  loading?: boolean
  containerClassName?: string
  tableClassName?: string
  trClass?: string
  noHeader?: boolean
}

export interface TablePaginationProps extends TableProps {
  count: number
  limit: number
  page: number
  onPageChange: (newPage: number) => void
  onLimitChange?: (newLimit: number) => void
  onSort?: (sort?: { id: string; desc: boolean }) => void
}

const TablePagination = ({
  columns,
  data,
  loading,
  containerClassName,
  page,
  limit = 10,
  onPageChange,
  count,
  noHeader,
  trClass
}: TablePaginationProps) => {
  return (
    <div>
      <GenericTable
        noHeader={noHeader}
        trClass={trClass}
        columns={columns}
        data={data}
        containerClassName={containerClassName}
        loading={loading}
      />
      <PaginationAction
        page={page}
        limit={limit}
        count={count}
        onPageChange={onPageChange}
      />
    </div>
  )
}
export interface PaginationActionProps {
  count: number
  limit: number
  page: number
  onPageChange: (newPage: number) => void
}
export const PaginationAction: React.FC<PaginationActionProps> = ({
  count,
  limit,
  page,
  onPageChange
}) => {
  const totalPage = Math.ceil(count / limit)
  const siblingCount = 4

  const paginationRange = (() => {
    if (totalPage <= 10) {
      return range(1, totalPage)
    }

    const leftSiblingIndex = Math.max(page - siblingCount, 1)
    const rightSiblingIndex = Math.min(page + siblingCount, totalPage)

    const shouldShowLeftDots = leftSiblingIndex > 2
    const shouldShowRightDots = rightSiblingIndex < totalPage - 1

    const firstPageIndex = 1
    const lastPageIndex = totalPage

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2 * siblingCount
      return [...range(1, leftItemCount), '...', lastPageIndex]
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 3 + 2 * siblingCount
      return [
        firstPageIndex,
        '...',
        ...range(totalPage - rightItemCount + 1, totalPage)
      ]
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      return [
        firstPageIndex,
        '...',
        ...range(leftSiblingIndex, rightSiblingIndex),
        '...',
        lastPageIndex
      ]
    }
  })()

  function range(start: number, end: number) {
    const length = end - start + 1
    return Array.from({ length }, (_, idx) => idx + start)
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="pagination-container">
            <nav aria-label="pagination-result">
              <ul className="pagination justify-content-center">
                <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                  <a
                    className="page-link"
                    onClick={() => page !== 1 && onPageChange(page - 1)}
                    aria-label="Previous"
                  >
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                {paginationRange?.map((item, idx) => (
                  <li
                    key={idx}
                    className={`page-item ${
                      typeof item === 'number' && item === page ? 'active' : ''
                    } ${item === '...' ? 'disabled' : ''}`}
                  >
                    <a
                      className="page-link"
                      onClick={() =>
                        typeof item === 'number' && onPageChange(item)
                      }
                    >
                      {item}
                    </a>
                  </li>
                ))}
                <li
                  className={`page-item ${
                    page === totalPage ? 'disabled' : ''
                  }`}
                >
                  <a
                    className="page-link"
                    onClick={() => page !== totalPage && onPageChange(page + 1)}
                    aria-label="Next"
                  >
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}

export const GenericTable = ({
  columns,
  containerClassName,
  tableClassName,
  loading,
  data,
  trClass,
  noHeader
}: TableProps) => {
  const { t } = useTranslation()
  const renderEmptyRow = (columns: Column[]) => {
    return (
      <tr>
        <td colSpan={columns?.length ?? 0} align="center">
          <EmptyContent />
        </td>
      </tr>
    )
  }
  const renderLoading = (columns: Column[]) => {
    return (
      <tr>
        <td colSpan={columns?.length ?? 0} align="center">
          <LoadingMiniContent />
        </td>
      </tr>
    )
  }
  return (
    <div className={`table-responsive ${containerClassName ?? ''}`}>
      <table className={`table ${tableClassName ?? ''}`}>
        {!noHeader && (
          <thead>
            <tr>
              {columns?.map((i) => (
                <th style={i?.thStyle} className={i?.thClass} key={i.accessor}>
                  {i.name}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {loading && renderLoading(columns)}
          {!loading && data?.length === 0 && renderEmptyRow(columns)}
          {data?.map((item: FixTypeLater, idx) => (
            <tr
              key={JSON.stringify(item)}
              className={`${trClass ? trClass : ''}`}
            >
              {columns.map((column) => (
                <td
                  key={JSON.stringify(column)}
                  className={column?.tdClass ?? ''}
                  style={column?.tdStyle}
                >
                  {column?.Cell?.(item, idx) ?? <>{item?.[column?.accessor]}</>}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
export default TablePagination
