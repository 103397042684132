import { useLottoContext } from 'contexts'
import { ChangeEvent, useEffect, useState } from 'react'
import { LottoConfigObject } from 'utils/lotto-type-utils'
import {
  BetNumber,
  generateNineteenNumber,
  generatePermutationsWithString,
  generateSwitchNumber,
  generateTwoNumber
} from 'utils/number-utils'

export const useTwoNumber = () => {
  const {
    addLottoListAndHandleResponse,
    lottoGroupItem,
    deleteLottoListByNumber,
    getLimitTypeUI,
    lottoConfig
  } = useLottoContext()
  const [nineTeenSelect, SetNineteen] = useState<string[]>([])
  const [startNumberSelect, SetStartNumber] = useState<string[]>([])
  const [endNumberSelect, SetEndNumber] = useState<string[]>([])
  const [searchDigit, SetSearchDigit] = useState<string>('')
  const [swapNumber, setSwapNumber] = useState<boolean>(false)
  const [menuSelected, setMenuSelect] = useState<string[]>([])
  const [numbers, setNumber] = useState<BetNumber[]>(generateTwoNumber())
  const { betTwo: menuToReward } = lottoConfig
  useEffect(() => {
    const pSelected = lottoGroupItem
      .filter((lotto) => ['bet_two_top', 'bet_two_under'].includes(lotto.type))
      ?.map((v) => v?.items?.map((i) => i.number))
      .reduce((acc, cur) => acc.concat(cur), [])
    const selectedNum = Array.from(new Set(pSelected))
    const updateNumber = numbers.map((i) => {
      return {
        ...i,
        active: selectedNum.includes(i.number)
      }
    })
    setNumber(updateNumber)
  }, [JSON.stringify(lottoGroupItem)])
  useEffect(() => {
    const pSelected = lottoGroupItem
      .filter((lotto) => ['bet_two_top', 'bet_two_under'].includes(lotto.type))
      ?.map((v) => v?.items?.map((i) => i.number))
      .reduce((acc, cur) => acc.concat(cur), [])
    const selectedNum = Array.from(new Set(pSelected))
    let updateNumber = generateTwoNumber().map((i) => {
      return {
        ...i,
        active: selectedNum.includes(i.number)
      }
    })
    if (searchDigit !== '') {
      updateNumber = [
        ...updateNumber?.filter((i) => i.number.includes(searchDigit))
      ]
    }
    setNumber(updateNumber)
  }, [searchDigit])
  const onSetTwoDigitFunction = (number: string, type: string) => {
    const updateNumberSet = (numberSet: Set<string>, isAdding: boolean) => {
      if (isAdding) {
        numberSet.add(number)
      } else {
        numberSet.delete(number)
      }
    }
    const updateNumbers = (numbers: string[], isAdding: boolean) => {
      const numbersToAdd = isAdding ? numbers : []
      const params: LottoConfigObject = menuSelected?.reduce(
        (acc: Record<string, number>, menuOption) => {
          acc[menuOption] = menuToReward[menuOption]
          return acc
        },
        {}
      )
      numbersToAdd.forEach((number) => {
        addLottoListAndHandleResponse(number, params)
      })
    }
    switch (type) {
      case 'start_number':
        const sNumber = new Set(startNumberSelect)
        updateNumberSet(sNumber, !sNumber.has(number))
        updateNumbers(generateSwitchNumber(parseInt(number)), true)
        SetStartNumber(Array.from(sNumber))
        break
      case 'end_number':
        const eNumber = new Set(endNumberSelect)
        updateNumberSet(eNumber, !eNumber.has(number))
        updateNumbers(generateSwitchNumber(parseInt(number), true), true)
        SetEndNumber(Array.from(eNumber))
        break
      case '19_number':
        const nNumber = new Set(nineTeenSelect)
        updateNumberSet(nNumber, !nNumber.has(number))
        updateNumbers(generateNineteenNumber(parseInt(number)), true)
        SetNineteen(Array.from(nNumber))
        break
      default:
        break
    }
  }
  const onSearchDigit = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const formattedValue = value.replace(/\D/g, '').slice(0, 2)
    SetSearchDigit(formattedValue)
  }
  const onToggleMenu = (menu: string) => {
    const newMenu = new Set([...menuSelected])
    if (newMenu.has(menu)) {
      newMenu.delete(menu)
    } else {
      newMenu.add(menu)
    }
    setMenuSelect(Array.from(newMenu))
  }
  const onToggleSwapNumber = () => {
    setSwapNumber(!swapNumber)
  }
  const onClickNumber = async (number: string) => {
    const selectedList = [...lottoGroupItem]
      .filter(
        (lotto) =>
          ['bet_two_top', 'bet_two_under'].includes(lotto.type) &&
          lotto.items?.find((i) => i.number === number)
      )
      ?.map((v) => v?.items?.map((i) => i.number))

    const createParams = (menuSelected: string[]) => {
      return menuSelected?.reduce((acc: Record<string, number>, menuOption) => {
        acc[menuOption] = menuToReward[menuOption]
        return acc
      }, {})
    }

    if (selectedList.length === 0) {
      const params = createParams(menuSelected)
      if (swapNumber) {
        const results = generatePermutationsWithString(number)
        results.forEach((result) => {
          addLottoListAndHandleResponse(result, params)
        })
      } else {
        addLottoListAndHandleResponse(number, params)
      }
    } else {
      menuSelected?.forEach((menuOption) => {
        deleteLottoListByNumber(number, menuOption)
      })
    }
  }
  return {
    numbers,
    searchDigit,
    swapNumber,
    onToggleSwapNumber,
    menuSelected,
    onSearchDigit,
    onToggleMenu,
    onClickNumber,
    onSetTwoDigitFunction,
    nineTeenSelect,
    startNumberSelect,
    endNumberSelect,
    getLimitTypeUI,
    betTwo: lottoConfig.betTwo
  }
}
