import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'

import { Autoplay, Pagination, Mousewheel, Keyboard } from 'swiper/modules'
import { useEffect, useState } from 'react'
export interface SwiperComponentProps {
  items: React.ReactNode[]
}
export const SwiperComponent = ({ items }: SwiperComponentProps) => {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    // Set the component to "mounted" after the first render
    setIsMounted(true)
  }, [])

  if (!isMounted || items.length === 0) {
    return null
  }
  if (items.length === 0) {
    return <></>
  }
  return (
    <Swiper
      cssMode={true}
      mousewheel={true}
      keyboard={true}
      centeredSlides={true}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false
      }}
      modules={[Autoplay, Pagination, Mousewheel, Keyboard]}
      slidesPerView={'auto'}
      spaceBetween={30}
      pagination={{
        clickable: true
      }}
      className="carousel slide container mt-5"
      style={{ maxHeight: 500, overflow: 'hidden' }}
    >
      {items.map((i, idx) => (
        <SwiperSlide key={idx} style={{ height: '400px', overflowY: 'hidden' }}>
          {i}
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default SwiperComponent
