import { GenericWidget } from 'components'
import { GenericWidgetProps } from 'components/GenericWidget'
import { useTranslation } from 'react-i18next'

export const SessionErrorPage = () => {
  const { t } = useTranslation()
  const SessionErrorPageeData: GenericWidgetProps = {
    component: 'Layout',
    props: { hideMenu: true },
    items: [
      {
        component: 'Component404',
        props: {
          title: 'Session error',
          header: t('page404.header'),
          info: t('page404.info')
        }
      }
    ]
  }
  return <GenericWidget {...SessionErrorPageeData} />
}

export default SessionErrorPage
