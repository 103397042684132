import React, { useState } from 'react'
import { Svg } from 'images'
import { BetDetailsPanelProps } from 'components/BetDetailsPanel'
import { LottoItem, LottoTypeGroup } from 'contexts/lotto-context'
import { NUMBER_FORMAT_WITHOUT_DECIMAL } from 'utils/number-utils'
import numeral from 'numeral'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const StyledTd = styled.td`
  justify-content: space-between !important;

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }
`
type BetListItemProps = BetDetailsPanelProps & {
  disable?: boolean
  onDeleteItem: (key: number, type: string) => void
  onChangeSingleBetPrice: (type: string, idx: number, newNumber: number) => void
}

const BetListItem = ({
  disable,
  listItem,
  onDeleteItem,
  onChangeSingleBetPrice
}: BetListItemProps) => {
  return (
    <div className="bet-list-container">
      {listItem?.map((i: LottoTypeGroup) => (
        <React.Fragment key={i.type}>
          <h3 className="bet-list-item-title">{i.name}</h3>
          <div className="table-responsive table-bet-list">
            <table className="table table-borderless">
              <tbody>
                {i?.items?.map((item, idx) => (
                  <BetItem
                    disable={disable}
                    idx={idx}
                    type={i.type}
                    key={JSON.stringify(item)}
                    {...item}
                    onDeleteItem={onDeleteItem}
                    onChangeSingleBetPrice={onChangeSingleBetPrice}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </React.Fragment>
      ))}
    </div>
  )
}
type BetItemProps = LottoItem & {
  idx: number
  type: string
  disable?: boolean
  onDeleteItem: (key: number, type: string) => void
  onChangeSingleBetPrice: (type: string, idx: number, newNumber: number) => void
}
const BetItem = ({
  idx,
  type,
  number,
  price,
  rate,
  winPrice,
  onDeleteItem,
  onChangeSingleBetPrice,
  disable
}: BetItemProps) => {
  const [sprice, setBetPrice] = useState<number>(price)
  const { t } = useTranslation()
  return (
    <tr key={number}>
      <td className="lotto-number">{number}</td>
      <td className="lotto-quantity">
        <input
          className="radius-8"
          type="number"
          value={isNaN(sprice) ? 1 : sprice}
          style={
            ['bet_four', 'bet_six'].includes(type) ? { width: '100%' } : {}
          }
          disabled={disable}
          onChange={(e) => {
            setBetPrice(e.target.valueAsNumber)
            onChangeSingleBetPrice(type, idx, e.target.valueAsNumber)
          }}
        />
      </td>
      {!['bet_four', 'bet_six'].includes(type) && (
        <StyledTd className="lotto-rate d-flex">
          <span className="lotto-win-rate text-left">
            {t('betDetailPanel.win')}{' '}
            {numeral(winPrice).format(NUMBER_FORMAT_WITHOUT_DECIMAL)}
          </span>
          <span className="lotto-win-rate-detail radius-99 text-right">
            {rate} ฿
          </span>
        </StyledTd>
      )}
      <td className="closed">
        <a
          onClick={() => onDeleteItem(idx, type)}
          title="closed"
          id="reset-bet-details"
        >
          <img src={Svg.iconClose} alt="closed" />
        </a>
      </td>
    </tr>
  )
}
export default BetListItem
