import { LayoutProps } from 'components/Layout/Layout'
import { Svg } from 'images'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'redux/reducers'
import React, { useEffect } from 'react'
import { sagaActions } from 'redux/sagaActions'
import LanguageSelector from 'components/LanguageSelector'
import { useNavigate } from 'react-router-dom'

function currencyFormat(num: any) {
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

type HeaderProps = LayoutProps
const Header = React.memo(({ hideMenu }: HeaderProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userName = useSelector((state: RootState) => {
    return state.user?.userName
  })

  const userBalance = useSelector((state: RootState) => {
    return state.user?.userBalance
  })

  function handleRefreshClick() {
    dispatch({
      type: sagaActions.USER_FETCH_BALANCE
    })
  }

  console.log('UserBalance', userBalance)

  useEffect(() => {
    console.log('Component didmount: Header comp')
    if (!hideMenu) {
      dispatch({
        type: sagaActions.USER_FETCH_BALANCE
      })
    }
  }, [hideMenu])

  return (
    <header className="main-navigation py-1 bg-white fixed-top">
      <nav className="container">
        <div className="row">
          <div className="col-3">
            <a className="navbar-brand" onClick={() => navigate('/lotto')}>
              <img src={Svg.logoYellow} alt="movewinbet" />
            </a>
          </div>
          <div
            className="col-9"
            style={{ display: 'flex', flexDirection: 'row-reverse' }}
          >
            <LanguageSelector />
            {!hideMenu && (
              <div className="d-flex justify-content-end align-items-center align-content-center gap-2">
                <div className="user-name"> {userName && userName}</div>
                <div className="user-credit-container">
                  <div className="d-flex flex-row user-credit-wrapper gap-2">
                    <div>
                      <img src={Svg.iconHeaderDollar} alt="User Credit" />
                      <span>Credit :</span>
                    </div>
                    <div className="user-credit">
                      {currencyFormat(userBalance)}
                    </div>
                    <div>
                      <a
                        onClick={handleRefreshClick}
                        href="#"
                        title="Refresh Credit"
                      >
                        <img src={Svg.iconHeaderRefresh} alt="refresh" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>
    </header>
  )
})
export default Header
