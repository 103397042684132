import { Svg } from 'images'
import Layout, { LayoutProps } from './Layout'
import { useTranslation } from 'react-i18next'

export interface LayoutInfoProps extends LayoutProps {
  onBackAction?: () => void
}
const LayoutInfo = ({ loading, onBackAction, children }: LayoutInfoProps) => {
  const { t } = useTranslation()
  return (
    <Layout loading={loading}>
      <div
        className="container main-container my-4"
        // style={{ height: '100vh' }}
      >
        <div className="row">
          <div className="col-12 col-lg-1 mb-3">
            <div
              className="d-flex flex-row align-items-center gap-1"
              style={{ cursor: 'pointer' }}
              onClick={() => onBackAction?.()}
            >
              <img src={Svg.iconPrevious} alt="กลับ" />
              <span>{t('pollDetails.back')}</span>
            </div>
          </div>
          <div className="col-12 col-lg-8 offset-lg-1">{children}</div>
        </div>
      </div>
    </Layout>
  )
}
export default LayoutInfo
