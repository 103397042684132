import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { sagaActions } from 'redux/sagaActions'
import { RootState } from 'redux/reducers'
import { GenericWidgetProps } from 'components/GenericWidget'
import { LottoListProps } from 'components/LottoList'
import { LottoItemProps } from 'components/LottoItem'
import {
  convertLottoType,
  isEncryptLotto,
  isYeekeeType
} from 'utils/lotto-type-utils'
import dayjs, {
  DATE_TIME_FORMAT_HUMAN,
  convertToDateTime
} from 'utils/date-time-utils'
import { useTranslation } from 'react-i18next'
import { usePagination } from 'components/TablePagination/usePagination'
import { Round } from 'redux/reducers/gameReducer'
import { GetResultProps } from 'pages/lotto/hook/useResulted'
import { SwiperComponentProps } from 'components/Swiper'
import Resulted from 'pages/lotto/resulted'
import { ResultTable } from 'components/ResultTable'
import toast from 'react-hot-toast'
import { ModalComponentProps } from 'components/Modal'
import { createSelector } from '@reduxjs/toolkit'
// Selector for the rounds
const selectRounds = (state: RootState) => state.games?.rounds?.rounds ?? []

// Selector for the game
const selectGame = (state: RootState) => state.games.game

// Selector for loading states
const selectLoading = (state: RootState) =>
  state.games.loading.game || state.games.loading.rounds

// Selector for error
const selectError = (state: RootState) => state.games.error

// Combined selector using createSelector for memoization
const gamePageSelector = createSelector(
  selectRounds,
  selectGame,
  selectLoading,
  selectError,
  (rounds, game, loading, error) => ({
    rounds,
    game,
    loading,
    error
  })
)
export const useRoundPage = () => {
  const [open, setOpen] = useState(false)
  const { limit, page } = usePagination(undefined, 16)
  const { i18n, t } = useTranslation()
  const { gameId } = useParams()
  const navigation = useNavigate()
  useEffect(() => {
    fetchRoundData()
  }, [])
  const dispatch = useDispatch()
  const loadMoreRound = () => {
    if (gameId) {
      dispatch({
        type: sagaActions.LOAD_MORE_LIST_GAME_ROUND,
        payload: { page: page, pageSize: limit, gameId }
      })
    }
  }
  const fetchRoundData = () => {
    if (gameId) {
      dispatch({
        type: sagaActions.FETCH_LIST_GAME_ROUND,
        payload: { page: page, pageSize: limit, gameId }
      })
      dispatch({
        type: sagaActions.FETCH_GAME,
        payload: { gameId }
      })
    }
  }
  const { rounds, game, loading, error } = useSelector(gamePageSelector)

  useEffect(() => {
    if (page != 1) {
      loadMoreRound()
    }
    if (error) {
      toast.error(error)
    }
  }, [page, rounds, loading, error])

  const filterAndSortRounds = (rounds: Round[]) => {
    const now = dayjs()

    const closedRound = rounds
      .filter((round) => dayjs(round.close_at).isBefore(now))
      .sort((a, b) => dayjs(b.close_at).diff(dayjs(a.close_at))) // Sort open rounds in descending order (from newest to oldest)

    const openRound = rounds
      .filter((round) => dayjs(round.close_at).isAfter(now))
      .sort((a, b) => dayjs(a.close_at).diff(dayjs(b.close_at))) // Sort closed rounds in ascending order (from oldest to newest)

    const renderRound = [...openRound, ...closedRound] // Combine closed rounds followed by open rounds
    return { renderRound, closedRound, openRound }
  }
  const { renderRound, closedRound } = filterAndSortRounds(rounds)

  const resultProps = GetResultProps(
    i18n,
    t,
    closedRound?.[0],
    game,
    navigation
  )
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 1000,
      maxWidth: '90%', // Adjust width to fit within mobile view
      maxHeight: '80vh', // Limit the height to 80% of the viewport height to ensure it doesn't go off screen
      overflow: 'auto' // Allows scrolling within the modal
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      zIndex: 999
    }
  }
  const modalComponentProps: ModalComponentProps = {
    isOpen: open,
    shouldCloseOnEsc: true,
    style: customStyles,
    title: t('lottoPage.lottoRound.rules') ?? '',
    shouldFocusAfterRender: false,
    onRequestClose: () => setOpen(false),
    onClickOk() {
      setOpen(false)
    },
    onClickCancel() {
      setOpen(false)
    },
    children: (
      <div
        dangerouslySetInnerHTML={{
          __html: (i18n.language === 'th' ? game?.desc_th : game?.desc_en) ?? ''
        }}
      />
    )
  }
  const swiperProps: SwiperComponentProps = { items: [] }
  if (!isYeekeeType(game?.type ?? '') && !isEncryptLotto(game?.type ?? '')) {
    swiperProps.items.push(<Resulted {...resultProps} swiper />)
  } else {
    swiperProps.items.push(
      <ResultTable
        data={closedRound
          .filter((i) => dayjs(i.close_at).isSame(dayjs(), 'date'))
          .slice(0, 10)}
        {...resultProps}
      />
    )
  }
  const getGameType = (i?: number) => ({
    title: t('lottoPage.lottoRound.list_round_name', {
      name:
        localStorage.getItem('lang') === 'th' ? game?.name_th : game?.name_en
    }),
    round: t('lottoPage.lottoRound.round_name', {
      name:
        localStorage.getItem('lang') === 'th' ? game?.name_th : game?.name_en,
      round: i
    })
  })
  const listRound: LottoListProps = {
    title: getGameType()?.title,
    isShowButton: true,
    buttonText: t('lottoPage.lottoRound.rules') ?? '',
    onClickButton: () => {
      setOpen(true)
    },
    listLotto: [...renderRound].map((i) => {
      const isOpen =
        (i.is_enable === 'yes' ? true : false) &&
        dayjs(i.close_at).isAfter(dayjs())
      const resp: LottoItemProps = {
        gameId: i.id,
        title: getGameType(i.round_number)?.round,
        type: convertLottoType(game?.flag ?? 'government'),
        betEnable: isOpen,
        round: i.round_number,
        openDate: i.open_at,
        endDate: i.close_at,
        dueDate: isOpen ? i.close_at : undefined,
        details: convertToDateTime(
          i.close_at,
          DATE_TIME_FORMAT_HUMAN,
          localStorage.getItem('lang') ?? i18n.language
        ),
        handleOnClick: () => {
          navigation(`${i.id}`)
        }
      }
      return resp
    })
  }
  const LottoTemplate: GenericWidgetProps = {
    component: 'Layout',
    props: { loading },
    items: [
      { component: 'SwiperComponent', props: swiperProps },
      { component: 'LottoList', props: listRound },
      { component: 'ModalComponent', props: modalComponentProps }
    ]
  }
  // if (totalPage !== currentPage && rounds.length != 0) {
  //   LottoTemplate.items.push({
  //     component: 'LoadMore',
  //     props: { onClick: () => onPageChange(page + 1) }
  //   })
  // }
  return { LottoTemplate }
}
