import BetListItem from 'components/BetListItem'
import { LottoTypeGroup, useLottoContext } from 'contexts/lotto-context'
import { Svg } from 'images'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import numeral from 'numeral'
import { NUMBER_FORMAT } from 'utils/number-utils'
import { useParams } from 'react-router-dom'
import { isGroupLotto } from 'utils/lotto-type-utils'

export interface BetDetailsPanelProps {
  listItem?: LottoTypeGroup[]
}
const ListItem = styled.li`
  cursor: pointer;
`

const BetDetailsPanel = (props: BetDetailsPanelProps) => {
  const { t } = useTranslation()
  const { roundId } = useParams()
  const {
    game,
    betPrice,
    onManualBetPriceChange,
    betPriceChange,
    lottoGroupItem,
    deleteLottoList,
    onChangeSingleBetPrice,
    onClearBetList,
    onHandleSubmit,
    totalBetPrice,
    creditLeft
  } = useLottoContext()
  const items = (props?.listItem ?? lottoGroupItem).filter(
    (v) => v.items.length > 0
  )
  const onManualBetChange = (value: number) => {
    onManualBetPriceChange(value)
  }

  const renderItem = () => {
    if (!items || items?.length === 0) {
      return <EmptyList />
    }
    return (
      <BetListItem
        disable={isGroupLotto(game?.type ?? '')}
        listItem={items}
        onDeleteItem={deleteLottoList}
        onChangeSingleBetPrice={onChangeSingleBetPrice}
      />
    )
  }
  const pTotalBetPrice = isNaN(totalBetPrice) ? 0 : totalBetPrice
  const pCreditLeft = isNaN(creditLeft) ? 0 : creditLeft

  return (
    <div className="bet-detail-dashboard-wrapper mb-4">
      <div className="bet-detail-title d-flex flex-row flex-wrap justify-content-between align-items-center mb-4">
        <h3>{t('betDetailPanel.listNumber')}</h3>
        <a
          title={t('betDetailPanel.listNumber').toString()}
          className="text-decoration-none radius-8 space-btn-inner bet-by-doc"
          href="/lotto/poll"
        >
          <i className="dollar-coin"></i>
          {t('betDetailPanel.listNumber')}
        </a>
      </div>
      <div
        className="user-bet-list mb-4"
        style={{ maxHeight: '500px', overflowX: 'auto' }}
      >
        {renderItem()}
      </div>
      <div className="user-bet-number mb-4">
        {!isGroupLotto(game?.type ?? '') && (
          <>
            <div className="input-group input-group-lg">
              <span className="input-group-text" id="user-bet-total">
                <img src={Svg.iconDollarCoin} alt="" />
              </span>
              <input
                type="number"
                value={betPrice ?? ''}
                onChange={(e) => onManualBetChange(e.target.valueAsNumber)}
                className="form-control"
                aria-label="User bet number"
                aria-describedby="inputGroup-sizing-lg"
              />
            </div>
            <ul className="list-unstyled list-inline d-flex justify-content-lg-center justify-content-between mt-2 mb-4">
              {[10, 20, 50, 100].map((v) => (
                <ListItem className="list-inline-item mt-4 mb-4" key={v}>
                  <a
                    title={`${v}฿`}
                    className="text-decoration-none radius-8"
                    onClick={() => betPriceChange(v)}
                  >
                    {v}​฿
                  </a>
                </ListItem>
              ))}
            </ul>
          </>
        )}

        <p className="mb-0">
          <strong>{t('betDetailPanel.creditAmount')}</strong>{' '}
          <span className="text-gold">
            {numeral(pCreditLeft).format(NUMBER_FORMAT)}
          </span>{' '}
          ฿
        </p>
        <p className="mb-0">
          <strong>{t('betDetailPanel.betAmount')}</strong>{' '}
          <span className="text-gold">
            {numeral(pTotalBetPrice).format(NUMBER_FORMAT)}
          </span>{' '}
          ฿
        </p>
      </div>
      <hr />
      <div className="bet-process-complete">
        <a
          onClick={() => onHandleSubmit(parseInt(roundId ?? '-1'))}
          title={t('betDetailPanel.bet').toString()}
          className="btn-gold radius-8 text-light-black d-flex justify-content-center space-btn-inner text-decoration-none mb-3"
        >
          {t('betDetailPanel.bet')}
        </a>
        <a
          onClick={() => onClearBetList()}
          title={t('betDetailPanel.clear').toString()}
          className="btn-black radius-8 text-white d-flex justify-content-center space-btn-inner text-decoration-none mb-3"
        >
          {t('betDetailPanel.clear')}
        </a>
      </div>
    </div>
  )
}

const EmptyList = () => {
  const { t } = useTranslation()
  return (
    <p className="mb-0 text-center pt-4 pb-4 no-bet">
      - {t('betDetailPanel.emptyList')} -
    </p>
  )
}

export default BetDetailsPanel
