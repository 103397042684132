import { useLottoContext } from 'contexts'
import { BetLottoType } from 'contexts/lotto-context'
import { ChangeEvent, useEffect, useState } from 'react'
import { BetNumber, generateRunningNumber } from 'utils/number-utils'
import { LottoConfigObject } from 'utils/lotto-type-utils'
export const useRunningNumber = () => {
  const {
    addLottoListAndHandleResponse,
    lottoGroupItem,
    deleteLottoListByNumber,
    getLimitTypeUI,
    lottoConfig
  } = useLottoContext()
  const [searchDigit, SetSearchDigit] = useState<string>('')
  const [menuSelected, setMenuSelect] = useState<string[]>([])
  const [numbers, setNumber] = useState<BetNumber[]>(generateRunningNumber())
  const { betOne: menuToReward } = lottoConfig
  useEffect(() => {
    const pSelected = lottoGroupItem
      .filter((lotto) => ['bet_run_top', 'bet_run_bottom'].includes(lotto.type))
      ?.map((v) => v?.items?.map((i) => i.number))
      .reduce((acc, cur) => acc.concat(cur), [])
    const selectedNum = Array.from(new Set(pSelected))
    const updateNumber = numbers.map((i) => {
      return {
        ...i,
        active: selectedNum.includes(i.number)
      }
    })
    setNumber(updateNumber)
  }, [JSON.stringify(lottoGroupItem)])
  useEffect(() => {
    const pSelected = lottoGroupItem
      .filter((lotto) => ['bet_run_top', 'bet_run_bottom'].includes(lotto.type))
      ?.map((v) => v?.items?.map((i) => i.number))
      .reduce((acc, cur) => acc.concat(cur), [])
    const selectedNum = Array.from(new Set(pSelected))
    let updateNumber = generateRunningNumber().map((i) => {
      return {
        ...i,
        active: selectedNum.includes(i.number)
      }
    })
    if (searchDigit !== '') {
      updateNumber = [
        ...updateNumber?.filter((i) => i.number.includes(searchDigit))
      ]
    }
    setNumber(updateNumber)
  }, [searchDigit])

  const onSearchDigit = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const formattedValue = value.replace(/\D/g, '').slice(0, 1)
    SetSearchDigit(formattedValue)
  }
  const onToggleMenu = (menu: string) => {
    const newMenu = new Set([...menuSelected])
    if (newMenu.has(menu)) {
      newMenu.delete(menu)
    } else {
      newMenu.add(menu)
    }
    setMenuSelect(Array.from(newMenu))
  }

  const onClickNumber = async (number: string) => {
    const selectedList = [...lottoGroupItem]
      .filter(
        (lotto) =>
          ['bet_run_top', 'bet_run_bottom'].includes(lotto.type) &&
          lotto.items?.find((i) => i.number === number)
      )
      ?.map((v) => v?.items?.map((i) => i.number))
    if (selectedList.length === 0) {
      const params: LottoConfigObject = menuSelected?.reduce(
        (acc: Record<string, number>, menuOption) => {
          acc[menuOption] = menuToReward[menuOption]
          return acc
        },
        {}
      )
      addLottoListAndHandleResponse(number, params)
    } else {
      menuSelected?.forEach((menuOption) => {
        const menuOptionTyped: BetLottoType = menuOption as BetLottoType
        if (menuToReward[menuOption]) {
          deleteLottoListByNumber(number, menuOptionTyped)
        }
      })
    }
  }
  return {
    numbers,
    searchDigit,
    menuSelected,
    onSearchDigit,
    onToggleMenu,
    onClickNumber,
    getLimitTypeUI,
    betOne: lottoConfig.betOne
  }
}
