import { FixTypeLater } from 'react-redux'
export type navItem = {
  key: string
  name: string
  icon?: string
  itemClass?: string
}
export interface NavbarProps {
  selected?: string
  onChangeItem: (key: any) => void
  navBarClass?: string
  items: navItem[]
}
export interface NavBarItemProps {
  name: string
  active: boolean
  onChangeItem?: () => FixTypeLater
  icon?: string
  itemClass?: string
}
const Navbar = ({
  selected,
  onChangeItem,
  navBarClass,
  items
}: NavbarProps) => {
  return (
    <ul className={`nav nav-pills mb-3 ${navBarClass}`}>
      {items.map((i) => (
        <NavBarItem
          name={i.name}
          key={i.key}
          active={i.key === selected}
          itemClass={i.itemClass}
          icon={i.icon}
          onChangeItem={() => onChangeItem(i.key)}
        />
      ))}
    </ul>
  )
}
export const NavBarItem = ({
  active,
  onChangeItem,
  icon,
  name
}: NavBarItemProps) => (
  <li className="nav-item" role="presentation">
    <button
      className={`nav-link d-flex justify-content-center align-items-center ${
        active && 'active'
      }`}
      type="button"
      onClick={onChangeItem}
    >
      {icon && <i className={`icon ${icon}`}></i>}
      {name}
    </button>
  </li>
)

export default Navbar
