import { AddLottoListResult } from 'contexts/lotto-context'
import { useTranslation } from 'react-i18next'

export const LimitToastrPopup = ({
  resp,
  onClick
}: {
  resp: AddLottoListResult
  onClick: () => void
}) => {
  const { t } = useTranslation()
  return (
    <div>
      <p>
        {t('popUpRange.limitNumberTypeCount')}:{' '}
        <span className="lotto-win-rate-detail">{resp.rangeHit}</span>
      </p>
      <p>
        {t('popUpRange.typeOfLimitIssue')}:{' '}
        <span className="lotto-win-rate-detail">
          {resp.typeRangeHit
            .map((typeHit) => t(`betDetailPanel.betLottoType.${typeHit}`))
            .join(', ')}
        </span>
      </p>
      <p>
        {t('popUpRange.numberRangeHit')}:{' '}
        <span className="lotto-win-rate-detail">
          {resp.numberRangeHit.join(', ')}
        </span>
      </p>
      <p>
        {t('popUpRange.paidPrice')}:{' '}
        <span className="lotto-win-rate-detail">
          {resp.limitRangeHit.join(', ')}
        </span>
      </p>
      <button
        className="text-decoration-none text-light-black btn-gold radius-8 space-btn-inner"
        onClick={onClick}
      >
        {t('message.confirm')}
      </button>
    </div>
  )
}
