import { NumberInput } from 'components'
import { useGroupBet } from './hook/useGroupBet'

const GroupLotto = () => {
  const { addDigit, deleteNumber, currentNumber, maxDigit } = useGroupBet()
  return (
    <div className="row">
      <div className="col-12 col-lg-12">
        <NumberInput
          maxDigit={maxDigit}
          onAddNumber={addDigit}
          onDeleteNumber={deleteNumber}
          currentNumber={currentNumber}
        />
      </div>
    </div>
  )
}

export default GroupLotto
