import { useState } from 'react'

export const useNumberInput = (maxDigit: number) => {
  const [currentNumber, SetNumber] = useState<number[]>([])
  const addDigit = (digit: number) => {
    if (currentNumber?.length != maxDigit) {
      const pCur = [...currentNumber]
      pCur.push(digit)
      SetNumber(pCur)
    }
  }
  const deleteNumber = () => {
    if (currentNumber?.length != 0) {
      const pCur = [...currentNumber]
      pCur.pop()
      SetNumber(pCur)
    }
  }
  const resetNumber = () => {
    SetNumber([])
  }
  return { currentNumber, addDigit, deleteNumber, resetNumber }
}
