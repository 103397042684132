import { CountdownClock, NumberInput } from 'components'
import { useTranslation } from 'react-i18next'
import { useShootNumber } from './hook/useShootNumber'
import YeeKeeTable from './yeekee/yeekee-table'
interface ShootNumberTabProps {
  dueDate: string
}
export const ShootNumberTab = ({ dueDate }: ShootNumberTabProps) => {
  const { t } = useTranslation()
  const {
    data,
    limit,
    onLimitChange,
    onPageChange,
    page,
    columns,
    addDigit,
    currentNumber,
    deleteNumber,
    countDown,
    summary
  } = useShootNumber(true)
  return (
    <div className="col-12 col-lg 12">
      <CountdownClock
        title={t('shootNumber.label')}
        titleClass="font-16 text-center mb-2"
        dueDate={dueDate}
        clockClass="font-32 text-gold text-center count-down-clock mb-4"
      />
      {countDown && (
        <center>
          {t('message.waitingForPutAgain', { second: countDown })}
        </center>
      )}
      <center>
        <h3>{summary}</h3>
      </center>
      <NumberInput
        maxDigit={5}
        loading={countDown != undefined}
        onAddNumber={addDigit}
        currentNumber={currentNumber}
        onDeleteNumber={deleteNumber}
      />
      <YeeKeeTable
        columns={columns}
        limit={limit}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
        page={page}
        data={data?.data ?? []}
        count={data.count}
      />
    </div>
  )
}
