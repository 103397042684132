import { put, takeLatest } from 'redux-saga/effects'
import { sagaActions } from 'redux/sagaActions'
import { callAPI } from '.'
import { FixTypeLater } from 'react-redux'
import { setPlayerToken } from 'redux/reducers/userReducer'

import { toast } from 'react-hot-toast'

const API_FETCH_USER_BALANCE = `${process.env.REACT_APP_HOST}/api/v1/seamless/fetchUserBalance`

function* fetchUserBalance(action: FixTypeLater): FixTypeLater {
  console.log('Saga called: fetchUserBalance')
  yield put(
    setPlayerToken({
      isUpdating: true
    })
  )

  try {
    const result: FixTypeLater = yield callAPI({
      url: API_FETCH_USER_BALANCE,
      method: 'POST',
      data: {}
    })
    // console.log(result)
    yield put(
      setPlayerToken({
        userBalance: result?.data?.data?.balance,
        isUpdating: false
      })
    )
  } catch (e) {
    toast.error('Could not update balance')

    yield put(
      setPlayerToken({
        isUpdating: false
      })
    )
  }
}

export function* watchUser() {
  // yield takeLatest<FixTypeLater>(sagaActions.POST_AUTH_PLAYER, authUser)
  yield takeLatest<FixTypeLater>(
    sagaActions.USER_FETCH_BALANCE,
    fetchUserBalance
  )
}
