import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface UserState {
  isUpdating?: boolean | undefined,
  token?: string | undefined
  accessToken?: string | undefined
  userName?: string | undefined
  userId?: number | undefined
  providerId?: number | undefined
  ipAddeess?: string | undefined
  userBalance?: number | undefined
  userBalanceUpdatedTime?: string | undefined
}

const initialState: UserState = {
  token: undefined,
  accessToken: undefined,
  userName: undefined,
  userId: undefined,
  providerId: undefined,
  ipAddeess: undefined,
  userBalance: 0,
  userBalanceUpdatedTime: undefined,
  isUpdating: false
}

const ListUserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setPlayerToken: (state, action: PayloadAction<UserState>) => {
      return {
        ...state,
        ...action.payload
      }
    }
  }
})
export const { setPlayerToken } = ListUserSlice.actions
export default ListUserSlice
