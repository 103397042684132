import { LayoutProps } from 'components/Layout/Layout'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
type FooterProps = LayoutProps

const Footer = ({ hideMenu }: FooterProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <footer className="footer-white-container sticky-bottom ">
      {!hideMenu && (
        <div className="container">
          <div className="row footer-icon-list">
            <div className="col-12 col-md-12 col-lg-8 offset-lg-2">
              <div className="row">
                <div className="col-4 col-md-4 col-lg-4 footer-icon-list-items">
                  <a
                    onClick={() => navigate('/lotto')}
                    title={t('footer.home') ?? 'หน้าแรก'}
                    className="text-decoration-none active"
                  >
                    <div className="footer-icon-list-items-wrapper d-flex flex-column align-items-center justify-content-center">
                      <i className="footer-icon" id="footer-icon-home"></i>
                      <span className="footer-list-items-title">
                        {t('footer.home')}
                      </span>
                    </div>
                  </a>
                </div>

                <div className="col-4 col-md-4 col-lg-4">
                  <a
                    onClick={() => navigate('/lotto/poll')}
                    title="poll"
                    className="text-decoration-none"
                  >
                    <div className="footer-icon-list-items-wrapper d-flex flex-column align-items-center justify-content-center">
                      <i className="footer-icon" id="footer-icon-bet-list"></i>
                      <span className="footer-list-items-title">
                        {t('footer.report')}
                      </span>
                    </div>
                  </a>
                </div>

                <div className="col-4 col-md-4 col-lg-4">
                  <a
                    onClick={() => navigate('/lotto/result')}
                    title={t('footer.resulted') ?? 'ผลวิเคราะห์'}
                    className="text-decoration-none"
                  >
                    <div className="footer-icon-list-items-wrapper d-flex flex-column align-items-center justify-content-center">
                      <i className="footer-icon" id="footer-icon-analysis"></i>
                      <span className="footer-list-items-title">
                        {t('footer.resulted')}
                      </span>
                    </div>
                  </a>
                </div>
                {/*
                <div className="col-3 col-md-3 col-lg-3">
                  <a
                    onClick={() => navigate('/contact')}
                    title={t('footer.contactUs') ?? 'ติดต่อเรา'}
                    className="text-decoration-none"
                  >
                    <div className="footer-icon-list-items-wrapper d-flex flex-column align-items-center justify-content-center">
                      <i
                        className="footer-icon"
                        id="footer-icon-contact-us"
                      ></i>
                      <span className="footer-list-items-title">
                        {t('footer.contactUs')}
                      </span>
                    </div>
                  </a>
                </div> */}
              </div>
              {/* <div className="col-3 col-md-3 col-lg-3">
                  <a href="#" title="หน้าแรก" className="text-decoration-none">
                    <div className="footer-icon-list-items-wrapper d-flex flex-column align-items-center justify-content-center">
                      <i
                        className="footer-icon"
                        id="footer-icon-make-lotto-set"
                      ></i>
                      <span className="footer-list-items-title">
                        สร้างเลขชุด
                      </span>
                    </div>
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      )}
    </footer>
  )
}
export default Footer
