import { LoadingMiniContent } from 'components/Loading'

const PollLoading = () => {
  return (
    <div className="container bet-user-poll-detail-container container-w-border-shadow">
      <LoadingMiniContent />
    </div>
  )
}
export default PollLoading
