import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface ErrorHandlerState {
  globalError: SystemError | undefined
}
const initialState: ErrorHandlerState = {
  globalError: undefined
}
export interface SystemError {
  code?: number
  status?: string
  message?: string
}

const ListErrorHandler = createSlice({
  name: 'errorHandler',
  initialState,
  reducers: {
    fetchErrorHandler: (state, action: PayloadAction<SystemError>) => {
      return {
        ...state,
        globalError: action.payload
      }
    },
    clearErrorHandler: () => ({ ...initialState })
  }
})

export const { fetchErrorHandler, clearErrorHandler } = ListErrorHandler.actions
export default ListErrorHandler
