import { useTranslation } from 'react-i18next'

export interface ResultCardProps {
  title?: string
  header?: string
  headerResult?: string
  detail?: string
  icon?: string
  results?: ResultItemProps[]
  noRound?: boolean
  onClickHeader?: () => void
  swiper?: boolean
}
export type ResultItemProps = {
  title?: string
  result?: string
  noRound?: boolean
  wrapper?: string
}
export interface ResultWrapperProps extends ResultCardProps {
  children: React.ReactNode
}
export const ResultWrapper = ({
  title,
  icon,
  detail,
  header,
  headerResult,
  noRound,
  children,
  onClickHeader,
  swiper
}: ResultWrapperProps) => {
  const { t } = useTranslation()
  return (
    <div
      className="lotto-result-card-container p-3 mb-3"
      key={title}
      style={swiper ? { height: '300px' } : {}}
    >
      <div className="row">
        <div className="col-12 mb-3">
          <h3 className="lotto-result-header d-flex align-items-center gap-2">
            {icon && (
              <img src={icon} alt={title} className="lotto-result-mini-flag" />
            )}

            {title && (
              <span
                onClick={onClickHeader}
                style={onClickHeader ? { cursor: 'pointer' } : {}}
                className="lotto-result-title"
              >
                {title}
              </span>
            )}
            {detail && <span className="lotto-result-date">{detail}</span>}
          </h3>
        </div>
      </div>
      {header && (
        <div className="row">
          <div className="col-12 mb-3">
            <div className="yeekee-result-number-summary d-flex justify-content-center align-items-center flex-column">
              <strong className="yeekee-result-number-summary-title">
                {header}
              </strong>
              <strong className="yeekee-result-number-summary-number">
                {noRound ? t('message.no_round') : headerResult}
              </strong>
            </div>
          </div>
        </div>
      )}
      {children}
    </div>
  )
}

const ResultCard = ({
  title,
  detail,
  icon,
  results,
  header,
  headerResult,
  noRound,
  onClickHeader,
  swiper
}: ResultCardProps) => {
  return (
    <ResultWrapper
      title={title}
      detail={detail}
      icon={icon}
      header={header}
      headerResult={headerResult}
      onClickHeader={onClickHeader}
      noRound={noRound}
      swiper={swiper}
    >
      {results && results.length > 0 && (
        <div className="row mb-3">
          {results.map(
            (i) =>
              i.result && <ResultItem key={i.title} {...i} noRound={noRound} />
          )}
        </div>
      )}
    </ResultWrapper>
  )
}

export const ResultItem = ({
  title,
  result,
  noRound,
  wrapper
}: ResultItemProps) => {
  const { t } = useTranslation()
  return (
    <div className={wrapper ? wrapper : 'col-md-6 mb-3'} key={title}>
      <div className="lotto-result-summary-container d-flex flex-column align-items-center justify-content-center">
        <h4 className="lotto-result-summary-header">{title}</h4>
        <strong className="lotto-result-summary-number">
          {!noRound ? result : t('message.no_round')}
        </strong>
      </div>
    </div>
  )
}
export default ResultCard
