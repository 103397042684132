export const sagaActions = {
  CLEAR_YEEKEE_PUT_RESPONSE: 'CLEAR_YEEKEE_PUT_RESPONSE',
  CLEAR_RESPONSE: 'CLEAR_RESPONSE',
  FETCH_FAILED: 'FETCH_FAILED',
  FETCH_ANNOUNCEMENTS: 'FETCH_ANNOUNCEMENTS',
  FETCH_GAME: 'FETCH_GAME',
  FETCH_GAMES: 'FETCH_GAMES',
  FETCH_RESULTS: 'FETCH_RESULTS',
  FETCH_GAME_ROUND: 'FETCH_GAME_ROUND',
  FETCH_GROUPS: 'FETCH_GROUP',
  FETCH_LIMIT_PRICE_NUMBERS: 'FETCH_LIMIT_PRICE_NUMBERS',
  FETCH_LIST_GAME_ROUND: 'FETCH_LIST_GAME_ROUND',
  FETCH_TICKET_DETAIL: 'FETCH_TICKET_DETAIL',
  FETCH_TICKET_DETAIL_HISTORY: 'FETCH_TICKET_DETAIL_HISTORY',
  FETCH_TICKET_PAGINATION: 'FETCH_TICKET_PAGINATION',
  FETCH_TICKET_PAGINATION_HISTORY: 'FETCH_TICKET_PAGINATION_HISTORY',
  FETCH_TODOS: 'FETCH_TODOS',
  FETCH_YEEKEE_LIST_NUMBER: 'FETCH_YEEKEE_LIST_NUMBER',
  LOAD_MORE_LIST_GAME_ROUND: 'LOAD_MORE_LIST_GAME_ROUND',
  POST_YEEKEE_PUT_NUMBER: 'POST_YEEKEE_PUT_NUMBER',
  POST_AUTH_PLAYER: 'POST_AUTH_PLAYER',
  POST_AUTH_SUCCESS: 'POST_AUTH_SUCCESS',
  POST_BET_CANCEL: 'POST_BET_CANCEL',
  POST_BET_CANCEL_SUCCESS: 'POST_BET_CANCEL_SUCCESS',
  POST_BET_GAME: 'POST_BET_GAME',
  POST_BET_SUCCESS: 'POST_BET_SUCCESS',
  POST_FAILED: 'POST_FAILED',
  CLEAR_GLOBAL_ERROR: 'CLEAR_GLOBAL_ERROR',
  CLEAR_GAME_ERROR: 'CLEAR_GAME_ERROR',
  FETCH_LIMIT_ROUND_NUMBERS: 'FETCH_LIMIT_ROUND_NUMBERS',
  //GLOBAL CHEKING
  INITIALIZE_APP: 'INITIALIZE_APP',

  //INITIALIZE LANDING PAGE
  INITIALIZE_STATE_TO_SERVER: 'INITIALIZE_STATE_TO_SERVER',
  INITIALIZED_STATE_TO_SERVER: 'INITIALIZED_STATE_TO_SERVER',

  //INITIALIZE HOME PAGE
  INITIALIZE_HOME_PAGE: 'INITIALIZE_HOME_PAGE',

  // ROUTER
  ROUTER_NAVIGATE_REQUEST: 'ROUTER_NAVIGATE_REQUEST',

  //USER
  USER_FETCH_BALANCE: 'USER_FETCH_BALANCE'
}
