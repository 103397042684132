import { ResultCard } from 'components'
import { ResultItemProps } from 'components/ResultCard'
interface ResultedProps {
  title: string
  details: string
  icon: string
  items: ResultItemProps[]
  closeAt: string
  gameType?: string
  noRound?: boolean
  hideRound?: boolean
  headerResult?: string
  header?: string
  onClickHeader?: () => void
  swiper?: boolean
}
const Resulted = ({
  title,
  details,
  icon,
  items,
  closeAt,
  gameType,
  noRound,
  hideRound,
  headerResult,
  header,
  onClickHeader,
  swiper
}: ResultedProps) => {
  return (
    <>
      {!hideRound && (
        <div className="yekee-result-status-round mb-3 p-3 d-flex justify-content-center">
          {closeAt}
        </div>
      )}
      {gameType != 'yekee' && (
        <ResultCard
          onClickHeader={onClickHeader}
          noRound={noRound}
          title={title}
          detail={details}
          icon={icon}
          results={items}
          header={header}
          headerResult={headerResult}
          swiper={swiper}
        />
      )}
    </>
  )
}
export default Resulted
