import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ResponseStructure } from 'redux/saga'

export interface YeekeeGameState {
  loading: {
    listYeekeePutNumber: boolean
    putNumberYeekee: boolean
  }
  listYeekeePutNumber: YeekeePutNumberPagination
  putNumberYeekee: undefined | ResponseStructure
  error: undefined | string
}
export type YeekeePutNumberPagination = {
  data?: YeekeePutNumberData[]
  count: number
  summary: number
}
export type YeekeePutNumberData = {
  id: number
  input_number: string
  player_username: string
  saved_at: string
  created_at: string
}
const initialState: YeekeeGameState = {
  loading: {
    listYeekeePutNumber: false,
    putNumberYeekee: false
  },
  listYeekeePutNumber: {
    data: [],
    count: 0,
    summary: 0
  },
  putNumberYeekee: undefined,
  error: undefined
}
const YeekeeGameSlice = createSlice({
  name: 'yeekee',
  initialState,
  reducers: {
    fetchYeekeeListPutNumber: (state) => ({
      ...state,
      loading: {
        ...state.loading,
        listYeekeePutNumber: true
      },
      error: undefined
    }),
    fetchYeekeeListPutNumberSuccess: (
      state,
      action: PayloadAction<YeekeePutNumberPagination>
    ) => ({
      ...state,
      loading: {
        ...state.loading,
        listYeekeePutNumber: false
      },
      listYeekeePutNumber: action.payload,
      error: undefined
    }),
    fetchYeekeeListPutNumberFailure: (
      state,
      action: PayloadAction<string>
    ) => ({
      ...state,
      loading: {
        ...state.loading,
        listYeekeePutNumber: false
      },
      listYeekeePutNumber: {
        data: undefined,
        count: 0,
        summary: 0
      },
      error: action.payload
    }),
    postYeekeePutNumber: (state) => ({
      ...state,
      loading: {
        ...state.loading,
        putNumberYeekee: true
      },
      putNumberYeekee: undefined,
      error: undefined
    }),
    postYeekeePutNumberSuccess: (
      state,
      action: PayloadAction<ResponseStructure>
    ) => ({
      ...state,
      loading: {
        ...state.loading,
        putNumberYeekee: false
      },
      putNumberYeekee: action.payload,
      error: undefined
    }),
    postYeekeePutNumberFailure: (state, action: PayloadAction<string>) => ({
      ...state,
      loading: {
        ...state.loading,
        putNumberYeekee: false
      },
      putNumberYeekee: undefined,
      error: action.payload
    }),
    clearYeekeePutNumber: (state) => ({
      ...state,
      putNumberYeekee: undefined
    })
  }
})

export const {
  clearYeekeePutNumber,
  fetchYeekeeListPutNumber,
  fetchYeekeeListPutNumberFailure,
  fetchYeekeeListPutNumberSuccess,
  postYeekeePutNumber,
  postYeekeePutNumberFailure,
  postYeekeePutNumberSuccess
} = YeekeeGameSlice.actions
export default YeekeeGameSlice
