import { Svg } from 'images'
import { useTranslation } from 'react-i18next'
const SearchBar = () => {
  const { t } = useTranslation()
  return (
    <section className="container search-bar-container mb-4 radius-16 pt-3 pb-3">
      <div className="row">
        <div className="col-12 col-lg-3 d-flex justify-content-center align-items-center">
          <p className="mb-0 text-gold">{t('searchBar.findLotto')}</p>
        </div>
        <div className="col-12 col-lg-9 search-form-container">
          <div className="input-group flex-nowrap">
            <span className="input-group-text" id="addon-wrapping">
              <img src={Svg.iconMagnifier} alt="" />
            </span>
            <input
              type="text"
              className="form-control"
              placeholder={t('searchBar.placeholder').toString()}
              aria-label="Search Lotto"
              aria-describedby="addon-wrapping"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default SearchBar
